import cx from 'classnames';
import React, { Component, MouseEvent, ReactElement, ReactNode } from 'react';
import DropdownButton, { DropdownButtonProps } from './DropdownButton';
import DropdownItem from './DropdownItem';
import type { DropdownItemProps } from './DropdownItem';
import Popover, { PopoverProps } from './Popover';
import styles from './styles.module.css';

export type { DropdownItemProps };

export interface DropdownProps<Values extends string | number> {
  children: NonNullable<ReactNode>;
  label: ReactNode;
  avatar?: ReactElement;
  initialOpen?: boolean;
  large?: boolean;
  variant?: DropdownButtonProps['variant'];
  className?: string;
  position?: DropdownButtonProps['position'] & PopoverProps<Values>['position'];
  value?: Values;
  withoutArrowIcon?: DropdownButtonProps['withoutArrowIcon'];
  onSelectItem?: (value: Values, e: MouseEvent) => void;
  onBlur?: () => void;
}

interface DropdownState {
  open: boolean;
}

export default class Dropdown<Values extends string | number> extends Component<DropdownProps<Values>, DropdownState> {
  static Item = DropdownItem;

  state = {
    open: this.props.initialOpen || false,
  };

  containerRef = React.createRef<HTMLDivElement>();

  componentWillUnmount(): void {
    this.removeListeners();
  }

  private readonly togglePopover = (): void => (this.state.open ? this.close() : this.open());

  /**
   * Hide the dropdown when clicking outside of it
   */
  private readonly handleWindowClick = (e: Event): void => {
    if (!this.state.open) {
      return;
    }

    let { target } = e;

    const currentContainer = this.containerRef.current;
    while (target && target !== currentContainer) {
      target = (target as any).parentNode;
    }

    if (!target) {
      this.close();
    }
  };

  private readonly handleSelectItem = (value: Values, e: MouseEvent): void => {
    if (this.props.onSelectItem && this.state.open) {
      this.close();
      this.props.onSelectItem(value, e);
      if (this.props.onBlur) this.props.onBlur();
    }
  };

  private removeListeners(): void {
    document.removeEventListener('click', this.handleWindowClick);
    document.removeEventListener('touchend', this.handleWindowClick);
  }

  private open(): void {
    document.addEventListener('click', this.handleWindowClick);

    // Touchend listener: Click events might not be triggered on safari mobile
    document.addEventListener('touchend', this.handleWindowClick);
    this.setState({
      open: true,
    });
  }

  private close(): void {
    this.removeListeners();
    this.setState({
      open: false,
    });
  }

  render(): ReactElement {
    const {
      avatar,
      position = 'bottom-right',
      label,
      children,
      value,
      large,
      variant,
      withoutArrowIcon,
      className,
    } = this.props;

    return (
      <div ref={this.containerRef} className={cx(styles.Dropdown, { [styles.Stretch]: large })}>
        <DropdownButton
          avatar={avatar}
          position={position}
          variant={variant}
          large={large}
          open={this.state.open}
          withoutArrowIcon={withoutArrowIcon}
          onClick={this.togglePopover}
        >
          {label}
        </DropdownButton>

        <Popover
          position={position}
          large={large}
          currentValue={value}
          visible={this.state.open}
          className={className}
          onSelectItem={this.handleSelectItem}
        >
          {children}
        </Popover>
      </div>
    );
  }
}
