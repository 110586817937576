import cx from 'classnames';
import React, { ReactNode, ReactElement } from 'react';
import { InputFormState } from 'utils/form';
import Typograhy from '../Typography';
import styles from './styles.module.css';

export interface InputFeedbackProps {
  state?: InputFormState;
  children: NonNullable<ReactNode>;
}

/**
 * Renders an helper sentence below an input.
 */
export default function InputFeedback({ state, children }: InputFeedbackProps): ReactElement {
  return (
    <Typograhy.div
      base="body-small"
      className={cx(styles.InputFeedback, {
        [styles.Valid]: state === 'valid',
        [styles.Invalid]: state === 'invalid',
      })}
    >
      {children}
    </Typograhy.div>
  );
}
