/* eslint-disable jsx-a11y/label-has-for */
import React, { ChangeEvent, Component, ReactNode, ReactElement } from 'react';
import styles from './styles.module.css';

export interface InputFileProps {
  children: NonNullable<ReactNode>;
  name: string;
  accept?: string;
  disabled?: boolean;
  onFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default class InputFile extends Component<InputFileProps> {
  handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length !== 0) {
      this.props.onFileUpload(e);
    }
    // Empty the input file value, so that the same file can be uploaded twice in a row.
    // eslint-disable-next-line no-param-reassign
    e.target.value = '';
  };

  render(): ReactElement {
    const { children, name, accept, disabled } = this.props;
    return (
      <div className={styles.InputFileContainer}>
        <label htmlFor={name}>{children}</label>
        <input
          type="file"
          disabled={disabled}
          className={styles.InputHidden}
          id={name}
          accept={accept}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
