/* eslint-disable camelcase */

import React, { ReactElement } from 'react';
import Icon from 'components/Icon';
import styles from './styles.module.css';

interface AddressStructuredFormatting {
  main_text: string;
  secondary_text?: string;
}

export interface Address {
  structured_formatting: AddressStructuredFormatting;
}

export interface AddressProps {
  address: Address;
}

export default function Address({ address }: AddressProps): ReactElement {
  return (
    <div className={styles.Address}>
      <Icon name="map-pin" />
      <div className={styles.AddressTexts}>
        <span>{address.structured_formatting.main_text}</span>
        <span className={styles.SecondaryText}>{address.structured_formatting.secondary_text}</span>
      </div>
    </div>
  );
}
