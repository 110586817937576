// cant be bothered to make css modules with kitt
// let's centralize styles-modules styles so we can fix
// them someday if we update kitt.

const styles = {};

styles.DefaultStyling = "styles-module_DefaultStyling__jYFJ9";
styles.LabelContainer = "styles-module_LabelContainer__1_BEJ";

export default styles;
