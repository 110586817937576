import cx from 'classnames';
import React, { MouseEventHandler } from 'react';
import Icon, { IconProps } from 'components/Icon';
import styles from './styles.module.css';

export interface InputIconProps {
  icon: IconProps['name'];
  className?: string;
  disabled?: boolean;
  noTabFocus?: boolean;
  onIconClicked?: MouseEventHandler<HTMLButtonElement>;
}

export default function InputIcon({ className, icon, disabled, onIconClicked, noTabFocus }: InputIconProps) {
  return (
    <button
      className={cx(styles.InputIcon, className, {
        [styles.WithCursor]: !!onIconClicked,
        [styles.ValidStateIconColor]: icon === 'check' && !disabled,
        [styles.InvalidStateIconColor]: icon === 'cross' && !disabled,
        [styles.DisabledIcon]: !!disabled,
      })}
      disabled={disabled}
      type="button"
      tabIndex={noTabFocus ? -1 : 0}
      onMouseUp={onIconClicked}
    >
      <Icon name={icon} />
    </button>
  );
}
