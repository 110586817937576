import cx from 'classnames';
import { Except } from 'type-fest';
import React, { ChangeEventHandler, ComponentProps, ReactNode, ReactElement } from 'react';
import styles from './styles.module.css';

export interface RadioBehaviourProps {
  id: string;
  children: NonNullable<ReactNode>;
  name: string;
  value?: string;
  checked?: boolean;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

type InputOtherProps = Except<
  ComponentProps<'input'>,
  'type' | 'className' | 'checked' | 'value' | 'name' | 'id' | 'onChange'
>;

export default function RadioBehaviour({
  id,
  children,
  checked,
  name,
  value,
  className,
  onChange,
  ...props
}: RadioBehaviourProps & InputOtherProps): ReactElement {
  return (
    <label htmlFor={id} className={cx(styles.RadioBehaviourContainer, className)}>
      <input
        type="radio"
        className={styles.InputRadioHidden}
        checked={checked}
        value={value}
        name={name}
        id={id}
        onChange={onChange}
        {...props}
      />
      {children}
    </label>
  );
}
