import { Except } from 'type-fest';
import React, { ChangeEventHandler, Component } from 'react';
import { GetInputPropsOptions } from 'downshift';
import PromiseRender from '../PromiseRender';
import InputAutocomplete from '../InputAutocomplete';
import Address from './Address';
import ManualAddress from './ManualAddress';
import EmptyList from './EmptyList';
import { FormattedAddress, GoogleMaps, loadGoogleMaps, normalizeAddressFromGoogle } from './utils';
import styles from './styles.module.css';

type AutocompleteService = google.maps.places.AutocompleteService;
type AutocompletionRequest = google.maps.places.AutocompletionRequest;
type AutocompletePrediction = google.maps.places.AutocompletePrediction;
interface ManualModeItem {
  type: 'manual-mode';
}

type Item = AutocompletePrediction | ManualModeItem;

const addressToString = (item?: Item | null): string | null => {
  if (!item) return null;
  if ((item as ManualModeItem).type === 'manual-mode') return null;
  return (item as AutocompletePrediction).description;
};
const selectedItemChanged = (prevItem: any, item: any) =>
  (!prevItem && item) || (prevItem && !item) || prevItem.id !== item.id;

type NormalizedAddressToString = (address: FormattedAddress) => string;

const defaultNormalizedAddressToString: NormalizedAddressToString = (address) => {
  let normalizedInitialValue = '';
  if (address.streetNumber) {
    normalizedInitialValue += `${address.streetNumber} `;
  }
  if (address.streetName) {
    normalizedInitialValue += `${address.streetName}, `;
  }
  if (address.city) {
    normalizedInitialValue += `${address.city}`;
  }
  return normalizedInitialValue;
};

export interface InputAddressProps extends Omit<GetInputPropsOptions, 'onChange'> {
  initialValue?: FormattedAddress;
  options?: Except<AutocompletionRequest, 'input'>;
  normalizedAddressToString?: NormalizedAddressToString;
  onChange: (newValue: null | FormattedAddress) => void;
  onManualModeRequired?: () => void;
}

interface InputAddressState {
  promise: Promise<undefined | AutocompletePrediction[]>;
}

class InputAddress extends Component<InputAddressProps, InputAddressState> {
  static defaultProps = {
    options: {},
    normalizedAddressToString: defaultNormalizedAddressToString,
  };

  private readonly defaultInputValue = this.props.initialValue
    ? (this.props.normalizedAddressToString as NormalizedAddressToString)(this.props.initialValue)
    : '';

  private maps?: GoogleMaps;

  private autocompleteService?: AutocompleteService;

  state: InputAddressState = {
    // unresolved promise
    promise: new Promise(() => null),
  };

  componentDidMount() {
    loadGoogleMaps().then((maps) => {
      this.maps = maps;
      this.autocompleteService = new maps.places.AutocompleteService();
      // Triggers the search when an initial value is given
      if (this.props.initialValue)
        this.handleInputChange({
          target: {
            value: this.defaultInputValue,
          },
        } as any);
    });
  }

  handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    if (value !== this.defaultInputValue) {
      this.props.onChange(null);
    }

    if (!this.autocompleteService) {
      return;
    }

    if (!value) {
      this.setState({
        promise: Promise.resolve(undefined),
      });
      return;
    }

    const predictionRequest = {
      input: value,
      ...this.props.options,
    };

    this.setState({
      promise: new Promise((resolve) => {
        (this.autocompleteService as AutocompleteService).getPlacePredictions(
          predictionRequest,
          (predictions, status) => {
            if (status === (this.maps as GoogleMaps).places.PlacesServiceStatus.OK) {
              resolve(predictions);
            } else {
              // Clear predictions by default if no result or NOK status
              resolve([]);
            }
          },
        );
      }),
    });
  };

  handleAddressSelected = (item: Item) => {
    if (!this.maps) return;

    if (this.props.onManualModeRequired && item && (item as ManualModeItem).type === 'manual-mode') {
      this.props.onManualModeRequired();
      return;
    }

    if (!this.props.onChange) return;

    if (!item) {
      this.props.onChange(null);
      return;
    }

    const address = item as AutocompletePrediction;
    const service = new this.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({ placeId: address.place_id }, (place, status) => {
      if (status === (this.maps as GoogleMaps).places.PlacesServiceStatus.OK && place.address_components) {
        this.props.onChange(normalizeAddressFromGoogle(place, address.description));
      }
    });
  };

  render() {
    const inputProps = { ...this.props };
    delete inputProps.options;
    delete inputProps.initialValue;
    delete inputProps.normalizedAddressToString;
    delete inputProps.onManualModeRequired;

    return (
      <PromiseRender promise={this.state.promise}>
        {({ result: predictions }) => (
          <InputAutocomplete
            defaultInputValue={this.defaultInputValue}
            inputProps={{
              ...inputProps,
              onChange: this.handleInputChange,
            }}
            emptyComponent={EmptyList}
            itemToString={addressToString}
            selectedItemChanged={selectedItemChanged}
            onChange={this.handleAddressSelected}
          >
            {predictions &&
              [
                ...predictions.map((item) => (
                  <InputAutocomplete.Item key={item.place_id} item={item}>
                    <Address address={item} />
                  </InputAutocomplete.Item>
                )),
              ].concat(
                this.props.onManualModeRequired ? (
                  <InputAutocomplete.Item
                    key="manual-mode"
                    item={{ type: 'manual-mode' }}
                    className={styles.ManualAddressLiOverride}
                    classes={{
                      selected: styles.ManualAddressLiOverrideSelected,
                      highlighted: styles.ManualAddressLiOverrideHighlighted,
                    }}
                  >
                    <ManualAddress />
                  </InputAutocomplete.Item>
                ) : (
                  []
                ),
              )}
          </InputAutocomplete>
        )}
      </PromiseRender>
    );
  }
}

export default InputAddress;
