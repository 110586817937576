import React from "react";
import GiftCar from "../assets/AEcar_gift.svg";
import "./GiftCarContainer.css";

const GiftCarContainer = ({ floating = false, children }) => {
  return (
    <div className="quote-form-gift-car-container ">
      <div
        className={"quote-form-gift-car-block " + (floating ? "floating" : "")}
      >
        <GiftCar className="quote-form-gift-car" />
        {children}
      </div>
    </div>
  );
};

export default GiftCarContainer;
