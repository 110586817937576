import React, { ReactElement } from 'react';
import Button from 'components/Button';
import { OnClose } from './Wrapper';
import styles from './styles.module.css';

export interface CloseButtonProps {
  onClick: OnClose;
}

export default function CloseButton({ onClick }: CloseButtonProps): ReactElement {
  return (
    <div className={styles.Closer}>
      <Button icon="cross" onClick={onClick} />
    </div>
  );
}
