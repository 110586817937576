/* eslint-disable react/no-multi-comp */
import cx from 'classnames';
import React, { ComponentType, ElementType } from 'react';
import InputIcon, { InputIconProps } from 'components/InputIcon';
import { InputFormState } from 'utils/form';
import styles from './styles.module.css';

const stateIcons: Record<NonNullable<InputFormState>, InputIconProps['icon']> = {
  valid: 'check',
  invalid: 'cross',
  pending: 'loading',
};

interface StyledInputTextComponentBaseProps {
  disabled?: boolean;
  className?: string;
}

export interface StyledInputTextProps<P extends StyledInputTextComponentBaseProps> {
  as: ElementType<P>;
  disabled?: boolean;
  icon?: InputIconProps['icon'];
  state?: InputFormState;
  width?: string | number;
  onIconClicked?: InputIconProps['onIconClicked'];
}

function StyledInputText<P extends StyledInputTextComponentBaseProps>(
  { as, disabled, icon, state, width, onIconClicked, ...props }: StyledInputTextProps<P> & P,
  ref: any,
) {
  const As = as as ComponentType<P>;
  return (
    <div className={styles.InputContainer} style={{ width }}>
      <As
        ref={ref}
        disabled={disabled}
        className={cx(styles.Input, {
          [styles.WithIcon]: icon || state,
          [styles.Disabled]: disabled,
        })}
        {...(props as any)}
      />

      <div className={styles.InputShadow} />
      {state && <InputIcon noTabFocus className={styles.StateIcon} icon={stateIcons[state]} disabled={disabled} />}
      {icon && (
        <InputIcon
          noTabFocus
          className={styles.InputIcon}
          icon={icon}
          disabled={disabled}
          onIconClicked={onIconClicked}
        />
      )}
    </div>
  );
}

export default React.forwardRef(StyledInputText) as typeof StyledInputText;
