import React, { useCallback } from "react";

import { SvgButton } from "./button";
import texts from "../../configs/texts";
import Question from "./wrapper";

import SvgQAlreadycarYes from "../../assets/Q_alreadycar_yes.svg";
import SvgQAlreadycarNo from "../../assets/Q_alreadycar_no.svg";

const Qalreadycar = ({ answer, ...props }) => {
  const onYesClick = useCallback(() => answer({ alreadycar: true }), [answer]);
  const onNoClick = useCallback(() => answer({ alreadycar: false }), [answer]);

  return (
    <Question {...props} title={texts.alreadycar.question} id="Qalreadycar">
      <SvgButton id="btn-yes-alreadycar" onClick={onYesClick} Svg={SvgQAlreadycarYes}>
        {texts.commons.yes}
      </SvgButton>
      <SvgButton id="btn-no-alreadycar" onClick={onNoClick} Svg={SvgQAlreadycarNo}>
        {texts.commons.no}
      </SvgButton>
    </Question>
  );
};

export default Qalreadycar;
