import cx from 'classnames';
import React from 'react';
import Media from 'react-media';
import Marker, { MarkerProps } from 'components/Map/Marker';
import styles from './styles.module.css';

// On mobile we want to enforce the tiniest marker
const getMarkerSize = (isWideScreen: boolean, markerSize: MarkerProps['size']): MarkerProps['size'] =>
  markerSize !== 'large' || isWideScreen ? markerSize : 'small';

export interface MapMarkerProps {
  size?: MarkerProps['size'];
  type?: MarkerProps['type'];
  loaded?: boolean;
}

export default function MapMarker({ size = 'small', type, loaded }: MapMarkerProps) {
  return (
    <div
      className={cx(styles.MarkerContainer, {
        [styles.AnimatedMarker]: loaded,
        [styles.LargeContainer]: type !== 'position' && size === 'large',
        [styles.PositionContainer]: type === 'position',
      })}
    >
      <Media query="(min-width: 480px)">
        {(matches) => <Marker type={type} size={getMarkerSize(matches, size)} />}
      </Media>
    </div>
  );
}
