import React, { MouseEvent } from 'react';
import Icon from 'components/Icon';
import Typography from '../Typography';
import styles from './styles.module.css';

export interface LoadingErrorProps {
  onReload: (e: MouseEvent) => void;
}

export default function LoadingError({ onReload }: LoadingErrorProps) {
  return (
    <div className={styles.LoadingError}>
      <div>Oops, il y a eu une erreur&nbsp;!</div>
      <Typography.Link variant="bold" onClick={onReload}>
        <Icon name="rotate-ccw" />
        <span>Rafraîchir</span>
      </Typography.Link>
    </div>
  );
}
