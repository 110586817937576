import { useRef } from 'react';

type UseIOSBodyFreeze = (
  enabled?: boolean,
  onEntered?: () => void,
) => { handleOnEntered: () => void; handleOnExit: () => void };

const useIOSBodyFreeze: UseIOSBodyFreeze = (enabled = false, onEntered) => {
  const isIOSRef = useRef(false);
  const scrollTopRef = useRef(0);

  const handleOnEntered = (): void => {
    // On iOS, force body to position fixed and apply current scroll offset
    // as body top. Will be reverted on exit
    if (enabled && isIOSRef.current) {
      const top = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);
      scrollTopRef.current = top;
      document.body.style.top = `-${top}px`;
      document.body.classList.add('ios-body-freeze');
    }

    return onEntered?.();
  };

  const handleOnExit = (): void => {
    if (enabled && isIOSRef.current) {
      document.body.style.top = '';
      document.body.classList.remove('ios-body-freeze');
      window.requestAnimationFrame(() => window.scrollTo(0, scrollTopRef.current));
    }
  };

  return { handleOnEntered, handleOnExit };
};

export default useIOSBodyFreeze;
