import React, { ReactNode, ReactElement } from 'react';
import Icon, { IconProps } from 'components/Icon';
import styles from './styles.module.css';

type IconPosition = 'right' | 'left' | undefined;

export interface ButtonContentProps {
  children?: ReactNode;
  icon?: IconProps['name'];
  iconPosition?: IconPosition;
}

/**
 * ButtonContent
 * Shared with every all types of buttons
 *
 * @param icon
 * @param iconPosition
 * @param children
 * @constructor
 */
export default function ButtonContent({ icon, iconPosition = 'left', children }: ButtonContentProps): ReactElement {
  return (
    /* A negative value (usually tabindex="-1" means that the element should be focusable, but should not be reachable via sequential keyboard navigation)
       -> Because we want to trigger content focus when we use tab to focus button
       -> See https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press */
    <div tabIndex={-1} className={styles.Content}>
      {icon && iconPosition !== 'right' && (
        <span className={styles.Icon}>
          <Icon name={icon} />
        </span>
      )}
      {children && <span className={styles.Children}>{children}</span>}
      {icon && iconPosition === 'right' && (
        <span className={styles.Icon}>
          <Icon name={icon} />
        </span>
      )}
    </div>
  );
}
