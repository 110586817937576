import React, { ReactNode, ReactElement, ComponentProps, Ref } from 'react';
import { Except } from 'type-fest';
import Link, { LinkProps, LinkImplementationRequiredProps } from '../Link';
import { BaseButtonProps, ButtonType } from './baseTypes';
import getClassNames from './getClassNames';
import ButtonContent from './ButtonContent';

export interface BaseLinkButtonProps extends BaseButtonProps {
  type?: ButtonType;
  children: NonNullable<ReactNode>;
}

export interface BaseHrefLinkButtonProps extends BaseLinkButtonProps {
  href: string;
  to?: never;
}
export type BaseToLinkButtonProps = BaseLinkButtonProps &
  LinkProps<LinkImplementationRequiredProps & { to: any }> & {
    href?: never;
  };

export type LinkHrefButtonProps = BaseHrefLinkButtonProps & Except<ComponentProps<'a'>, 'ref'>;
export type LinkToButtonProps = BaseToLinkButtonProps & Except<ComponentProps<'a'>, 'ref'>;

export type LinkButtonProps = LinkHrefButtonProps | LinkToButtonProps;

const onClickPreventDefault = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
  e.preventDefault();
};

export default React.forwardRef(function LinkButton(
  {
    children,
    type,
    disabled,
    href,
    to,
    icon,
    iconPosition = 'left',
    stretch,
    large,
    target,
    onClick,
    className,
    ...props
  }: LinkButtonProps,
  ref: Ref<HTMLAnchorElement>,
): ReactElement {
  return (
    <Link
      ref={ref}
      className={
        type === 'link'
          ? undefined
          : getClassNames(children, !!icon, iconPosition, large, stretch, type, disabled, className)
      }
      disabled={disabled}
      href={href}
      to={to}
      target={target}
      rel={target ? 'noopener noreferrer' : undefined}
      onClick={disabled ? onClickPreventDefault : onClick}
      {...(props as LinkButtonProps)}
    >
      {type === 'link' ? (
        (children as NonNullable<typeof children>)
      ) : (
        <ButtonContent icon={icon} iconPosition={iconPosition}>
          {children}
        </ButtonContent>
      )}
    </Link>
  );
});
