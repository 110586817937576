import React from "react";
import CodePromo from "./CodePromo";
import texts from "../../configs/texts";
import { Icon, Tooltip } from "@ornikar/kitt";

const GoLongMessage = ({ monthlyUnits, monthlyCents }) => (
  <>
    <h1 className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header2__1bOT8  styles-types-module_medium-header2__2OOVz  styles_Title__h1Kcp">
      Votre 1ère estimation&nbsp;:{" "}
      <span className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7     ">
        {monthlyUnits},{monthlyCents}&nbsp;€
      </span>
      &nbsp;!
      <Tooltip content={texts.landing.tooltip_quote}>
        <Icon name="info" color="#B0BEC5" />
      </Tooltip>
    </h1>
    <div className="styles_Description__zIzcq">
      <div>
        <p>
          En 3 étapes, vous précisez votre tarif et vous avez la possibilité d’
          <strong>adapter votre contrat d’assurance</strong> à vos besoins.
        </p>
        <p>
          Plus de <strong>transparence</strong>, plus de{" "}
          <strong>confiance</strong>
        </p>
      </div>
    </div>
    <div className="styles_Options__RHGIO" style={{ marginBottom: "1em" }}>
      <CodePromo />
    </div>
  </>
);

export default GoLongMessage;
