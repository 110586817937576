import cx from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import Typography from '../Typography';
import Link, { LinkProps, LinkImplementationRequiredProps, DefaultLinkImplementationProps } from '../Link';
import styles from './styles.module.css';

export type MenuItemProps<P extends LinkImplementationRequiredProps> = LinkProps<P> & {
  selected?: boolean;
};

function MenuItem<P extends LinkImplementationRequiredProps = DefaultLinkImplementationProps>({
  selected,
  ...props
}: MenuItemProps<P>): ReactElement {
  return (
    <li
      className={cx(styles.MenuItem, {
        [styles.MenuItemSelected]: selected,
      })}
    >
      <Link {...props} />
    </li>
  );
}

export interface MenuProps {
  title?: string | ReactNode;
  children: NonNullable<ReactNode>;
}

export default function Menu({ title, children }: MenuProps): ReactElement {
  return (
    <div>
      {title && (
        <div className={styles.MenuTitle}>
          <Typography.div variant="bold" base="header4" medium="header5">
            {title}
          </Typography.div>
        </div>
      )}
      {children}
    </div>
  );
}

Menu.Item = MenuItem;
