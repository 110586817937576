import React, { CSSProperties, ReactNode } from 'react';
import PlaceHolderSvg from './placeholder.raw.svg';
import styles from './styles.module.css';

export type PlaceholderStyle = Pick<CSSProperties, 'width' | 'height'>;

export interface PlaceHolderProps {
  placeholder?: ReactNode;
  style?: PlaceholderStyle;
}

/* eslint-disable react/no-danger */
export default function PlaceHolder({ placeholder, style }: PlaceHolderProps) {
  return placeholder ? (
    <div className={styles.PlaceholderContent} style={style}>
      {placeholder}
    </div>
  ) : (
    <div dangerouslySetInnerHTML={{ __html: PlaceHolderSvg }} className={styles.PlaceholderContent} style={style} />
  );
}
