import React, { useCallback } from "react";

import texts from "../../configs/texts";

import Question from "./wrapper";
import Validate from "./validate";
import { InputText, InputFeedback } from "@ornikar/kitt";
import useFormField from "../../hooks/useFormField";
import { validAge } from "../../types/answers";

const Qage = ({ answer, draft, ...props }) => {
  const [age, ageField, state] = useFormField(draft.age, validAge);

  const next = useCallback(() => {
    if (state === "valid") answer({ age });
  }, [age, answer, state]);

  return (
    <Question {...props} title={texts.age.question}>
      <InputText
        {...ageField}
        id="age"
        name="age"
        type="number"
        autoComplete="age"
        state={state}
        placeholder={texts.age.placeholder}
        onKeyPress={(e) => e.key === "Enter" && next()}
      />
      {state === "invalid" && (
        <InputFeedback state={state}>{texts.age.invalid}</InputFeedback>
      )}
      <Validate
        id="btn-validate-age"
        disabled={state !== "valid"}
        onClick={next}
      />
    </Question>
  );
};

export default Qage;
