/* eslint-disable import/no-commonjs, max-lines */

const arlequin1000 = '#64DD17';

module.exports = {
  // Primary
  '--color-blaze-1000': '#FF6F00',
  '--color-azure-300': '#B2E1FF',
  '--color-gunmetal-100': '#ECEFF1',
  '--color-gunmetal-400': '#B0BEC5',
  '--color-gunmetal-1000': '#38454C',

  // Secondary
  '--color-chrome-500': '#FFD57F',
  '--color-arlequin-1000': arlequin1000,
  '--color-coquelicot-1000': '#FF3D00',

  // Variations
  '--color-coquelicot-50': '#FFF5F2',
  '--color-coquelicot-100': '#FFEBE5',
  '--color-coquelicot-200': '#FFD8CC',
  '--color-coquelicot-300': '#FFC4B2',
  '--color-coquelicot-400': '#FFB199',
  '--color-coquelicot-500': '#FF9E7F',
  '--color-coquelicot-600': '#FF8B66',
  '--color-coquelicot-700': '#FF774C',
  '--color-coquelicot-800': '#FF6433',
  '--color-coquelicot-900': '#FF5019',

  '--color-cerise-50': '#FEF3F8',
  '--color-cerise-100': '#FEE8F2',
  '--color-cerise-200': '#FDD2E5',
  '--color-cerise-300': '#FCBBD8',
  '--color-cerise-400': '#FBA5CB',
  '--color-cerise-500': '#FA8EBE',
  '--color-cerise-600': '#F977B2',
  '--color-cerise-700': '#F860A4',
  '--color-cerise-800': '#F74A98',
  '--color-cerise-900': '#F6338A',
  '--color-cerise-1000': '#F51D7E',

  '--color-violet-50': '#FAF2FE',
  '--color-violet-100': '#F5E5FD',
  '--color-violet-200': '#ECCCFC',
  '--color-violet-300': '#E3B2FB',
  '--color-violet-400': '#DA99FA',
  '--color-violet-500': '#D07FF8',
  '--color-violet-600': '#C766F7',
  '--color-violet-700': '#BD4CF5',
  '--color-violet-800': '#B533F5',
  '--color-violet-900': '#AB19F3',
  '--color-violet-1000': '#A200F2',

  '--color-purple-50': '#F7F4FF',
  '--color-purple-100': '#EFE8FF',
  '--color-purple-200': '#E0D2FF',
  '--color-purple-300': '#D0BBFF',
  '--color-purple-400': '#C1A5FF',
  '--color-purple-500': '#B28FFF',
  '--color-purple-600': '#A379FF',
  '--color-purple-700': '#9362FF',
  '--color-purple-800': '#844CFF',
  '--color-purple-900': '#7435FF',
  '--color-purple-1000': '#651FFF',

  '--color-crayola-50': '#F4F8FF',
  '--color-crayola-100': '#E9F1FF',
  '--color-crayola-200': '#D4E4FF',
  '--color-crayola-300': '#BED6FF',
  '--color-crayola-400': '#A9C9FF',
  '--color-crayola-500': '#94BCFF',
  '--color-crayola-600': '#7FAFFF',
  '--color-crayola-700': '#69A1FF',
  '--color-crayola-800': '#5494FF',
  '--color-crayola-900': '#3E86FF',
  '--color-crayola-1000': '#2979FF',

  '--color-azure-50': '#F2FAFF',
  '--color-azure-100': '#E5F5FF',
  '--color-azure-200': '#CCEBFF',
  '--color-azure-400': '#99D8FF',
  '--color-azure-500': '#7FCEFF',
  '--color-azure-600': '#66C4FF',
  '--color-azure-700': '#4CBAFF',
  '--color-azure-800': '#33B1FF',
  '--color-azure-900': '#19A6FF',
  '--color-azure-1000': '#009DFF',

  '--color-gunmetal-50': '#FAFBFC',
  '--color-gunmetal-200': '#DAE1E6',
  '--color-gunmetal-300': '#CFD8DC',
  '--color-gunmetal-500': '#91A4AE',
  '--color-gunmetal-600': '#7B8E98',
  '--color-gunmetal-700': '#657884',
  '--color-gunmetal-800': '#586A74',
  '--color-gunmetal-900': '#485960',
  '--color-gunmetal-1100': '#293033',

  '--color-turquoise-50': '#F2FBFD',
  '--color-turquoise-100': '#E5F8FA',
  '--color-turquoise-200': '#CCF2F6',
  '--color-turquoise-300': '#B2EAF2',
  '--color-turquoise-400': '#99E4EE',
  '--color-turquoise-500': '#7FDDE9',
  '--color-turquoise-600': '#66D7E5',
  '--color-turquoise-700': '#4CD0E0',
  '--color-turquoise-800': '#33C9DD',
  '--color-turquoise-900': '#19C2D8',
  '--color-turquoise-1000': '#00BCD4',

  '--color-amazonite-50': '#F2FCFA',
  '--color-amazonite-100': '#E5F8F6',
  '--color-amazonite-200': '#CCF2ED',
  '--color-amazonite-300': '#B2EBE4',
  '--color-amazonite-400': '#99E5DB',
  '--color-amazonite-500': '#7FDFD2',
  '--color-amazonite-600': '#66D9C9',
  '--color-amazonite-700': '#4CD2C0',
  '--color-amazonite-800': '#33CCB7',
  '--color-amazonite-900': '#19C5AE',
  '--color-amazonite-1000': '#00BFA5',

  '--color-arlequin-50': '#F7FDF3',
  '--color-arlequin-100': '#EFFBE7',
  '--color-arlequin-200': '#E0F8D1',
  '--color-arlequin-300': '#D0F4B9',
  '--color-arlequin-400': '#C1F1A2',
  '--color-arlequin-500': '#B1EE8B',
  '--color-arlequin-600': '#A2EB74',
  '--color-arlequin-700': '#92E75C',
  '--color-arlequin-800': '#83E445',
  '--color-arlequin-900': '#73E02E',

  '--color-chrome-50': '#FFFBF2',
  '--color-chrome-100': '#FFF6E5',
  '--color-chrome-200': '#FFEECC',
  '--color-chrome-300': '#FFE5B2',
  '--color-chrome-400': '#FFDD99',
  '--color-chrome-600': '#FFCD66',
  '--color-chrome-700': '#FFC44C',
  '--color-chrome-800': '#FFBC33',
  '--color-chrome-900': '#FFB319',
  '--color-chrome-1000': '#FFAB00',

  '--color-blaze-50': '#FFF8F2',
  '--color-blaze-100': '#FFF0E5',
  '--color-blaze-200': '#FFE2CC',
  '--color-blaze-300': '#FFD3B2',
  '--color-blaze-400': '#FFC599',
  '--color-blaze-500': '#FFB77F',
  '--color-blaze-600': '#FFA966',
  '--color-blaze-700': '#FF9A4C',
  '--color-blaze-800': '#FF8C33',
  '--color-blaze-900': '#FF7D19',

  '--color-chestnut-50': '#F9F6F5',
  '--color-chestnut-100': '#F4EDEB',
  '--color-chestnut-200': '#E9DCD7',
  '--color-chestnut-300': '#DECAC3',
  '--color-chestnut-400': '#D3B9B0',
  '--color-chestnut-500': '#C8A79C',
  '--color-chestnut-600': '#BD9688',
  '--color-chestnut-700': '#B28474',
  '--color-chestnut-800': '#A77361',
  '--color-chestnut-900': '#9C614C',
  '--color-chestnut-1000': '#915039',

  // Color gradient
  '--gradient-blaze-to-coquelicot': 'linear-gradient(-225deg, #FF6F00 0%, #FF3D00 100%)',
  '--gradient-crayola-to-purple': 'linear-gradient(-225deg, #2979FF 0%, #651FFF 100%)',
  '--gradient-chrome-to-blaze': 'linear-gradient(-225deg, #FFAB00 0%, #FF6F00 100%)',
  '--gradient-azure-to-crayola': 'linear-gradient(-225deg, #009DFF 0%, #2979FF 100%)',
  '--gradient-turquoise-to-amazonite': 'linear-gradient(-225deg, #00BCD4 0%, #00BFA5 100%)',
  '--gradient-violet-to-purple': 'linear-gradient(-225deg, #A200F2 0%, #651FFF 100%)',
  '--gradient-gunmetal-dark': 'linear-gradient(225deg, #586A74 0%, #38454C 100%)',
  '--gradient-gunmetal-light': 'linear-gradient(225deg, #B0BEC5 0%, #CFD8DC 100%)',

  // Typography
  '--font-size-body': '1.6rem',
  '--line-height-body': '1.6',

  '--font-size-body-small': '1.3rem',
  '--line-height-body-small': '1.3',

  /* Base and Small */

  '--font-size-base-header1': '3.8rem',
  '--line-height-base-header1': '1.3',

  '--font-size-base-header2': '3.2rem',
  '--line-height-base-header2': '1.3',

  '--font-size-base-header3': '2.4rem',
  '--line-height-base-header3': '1.3',

  '--font-size-base-header4': '1.8rem',
  '--line-height-base-header4': '1.6',

  /* Medium and Wide */

  '--font-size-medium-and-higher-header1': '6.2rem',
  '--line-height-medium-and-higher-header1': '1.3',

  '--font-size-medium-and-higher-header2': '4.8rem',
  '--line-height-medium-and-higher-header2': '1.3',

  '--font-size-medium-and-higher-header3': '3.6rem',
  '--line-height-medium-and-higher-header3': '1.3',

  '--font-size-medium-and-higher-header4': '2.4rem',
  '--line-height-medium-and-higher-header4': '1.3',

  '--font-size-medium-and-higher-header5': '1.8rem',
  '--line-height-medium-and-higher-header5': '1.6',

  /* Variants */

  '--black-bold-font-weight': 700,
  '--black-bold-color': '#38454c',

  '--black-regular-font-weight': 400,
  '--black-regular-color': '#657884',

  '--black-light-font-weight': 400,
  '--black-light-color': '#b0bec5',

  '--white-bold-font-weight': 700,
  '--white-bold-color': '#fff',

  '--white-regular-font-weight': 400,
  '--white-regular-color': 'rgba(255, 255, 255, 0.7)',

  '--primary-bold-font-weight': 700,
  '--primary-bold-color': '#FF6F00',

  '--primary-regular-font-weight': 400,
  '--primary-regular-color': '#FF6F00',

  '--tertiary-bold-font-weight': 700,
  '--tertiary-bold-color': '#2979FF',

  '--tertiary-regular-font-weight': 400,
  '--tertiary-regular-color': '#2979FF',

  '--success-bold-font-weight': 700,
  '--success-bold-color': arlequin1000,

  '--success-regular-font-weight': 400,
  '--success-regular-color': arlequin1000,

  /* Depths */
  '--depth-default-base': '0 5px 20px 0 rgba(41, 48, 51, 0.10)',
  '--depth-card-base': '0 5px 20px 0 rgba(41, 48, 51, 0.10)',
  '--depth-card-medium': '0 10px 20px 0 rgba(41, 48, 51, 0.15)',
  '--depth-card-high-base': '0 5px 30px 0 rgba(41, 48, 51, 0.08)',
  '--depth-card-high-medium': '0 60px 40px 0 rgba(41, 48, 51, 0.08)',
  '--depth-card-high-large': '0 80px 60px 0 rgba(41, 48, 51, 0.08)',

  /* Card border radius */
  '--card-border-radius': '20px',
};
