import { useState, useCallback } from "react";

export default (
  initValue = "",
  validator = async () => true,
  corrector = (x) => x
) => {
  const [value, setValue] = useState(initValue);
  const [validationState, setValidationState] = useState(
    initValue ? (validator(initValue) ? "valid" : "invalid") : undefined
  );

  const onChange = useCallback(
    async (e) => {
      setValue(e.target.value);
      const valid = await validator(e.target.value);
      if (valid) setValidationState("valid");
      else setValidationState(undefined);
    },
    [validationState, setValidationState, setValue]
  );

  const onBlur = useCallback(async () => {
    const corrected = corrector(value);
    if (corrected != value) setValue(corrected);
    if (corrected === "") return setValidationState(undefined);

    if (await validator(corrected)) setValidationState("valid");
    else setValidationState("invalid");
  }, [value]);

  return [
    value,
    { value, state: validationState, onChange, onBlur },
    validationState,
  ];
};
