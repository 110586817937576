import cx from 'classnames';
import React, { ComponentType, createContext, ReactNode, useContext, ReactElement } from 'react';
import Anchor, { AnchorProps } from './Anchor';
import styles from './styles.module.css';

export interface LinkImplementationRequiredProps {
  className?: string;
  children?: NonNullable<ReactNode>;
}

export type DefaultLinkImplementationProps = AnchorProps;

type LinkImplementationProps<P extends LinkImplementationRequiredProps> = P;

const LinkImplementationContext = createContext<ComponentType<LinkImplementationProps<any>>>(Anchor);

export interface LinkPropsWithoutLinkImplementationProps {
  className?: string;
  disabled?: boolean;
  noUnderline?: boolean;
  noBold?: boolean;
  /** @deprecated replaced by disabled */
  disable?: never;
  /** @deprecated use Typography */
  dark?: never;
}

export type LinkProps<P extends LinkImplementationRequiredProps> = LinkPropsWithoutLinkImplementationProps &
  LinkImplementationProps<P>;

function Link<P extends LinkImplementationRequiredProps = DefaultLinkImplementationProps>({
  className,
  disabled,
  noUnderline,
  noBold,
  ...props
}: LinkProps<P>): ReactElement {
  const LinkImplementation = useContext(LinkImplementationContext);
  return (
    <LinkImplementation
      className={cx(
        styles.Link,
        {
          [styles.Disabled]: disabled,
          [styles.NoUnderline]: noUnderline,
          [styles.NoBold]: noBold,
        },
        className,
      )}
      disabled={disabled}
      {...props}
    />
  );
}

Link.LinkImplementationContext = LinkImplementationContext;
Link.Provider = LinkImplementationContext.Provider;
Link.Consumer = LinkImplementationContext.Consumer;
Link.Anchor = Anchor;

export default Link;
