import React from "react";

import Svgf752d65ca830c1af81dd7fb68c9a5739 from "../../assets/f752d65ca830c1af81dd7fb68c9a5739.svg";
import Svg8e543b425800ea077f027c83a896c704 from "../../assets/8e543b425800ea077f027c83a896c704.svg";
import { Button } from "@ornikar/kitt";
import texts from "../../configs/texts";

const Steps = () => (
  <div
    className="TheorySteps-3TZa4I_nyvEqBcrDU71RDR"
    id="steps"
    style={{ paddingTop: 60 }}
  >
    <div className="StepsContainer-1FFLR1MvEKM4Gack1sd5N_">
      <div className="Line-19XKlgfsViS3KUNEGcgn19"></div>
      <div className="GirlStudentSofa-2Z2OAwScXRF8U2d9zJkgd9">
        <Svgf752d65ca830c1af81dd7fb68c9a5739
          alt="révisions code de la route"
          className="Illustration-RwVUE4CPDJBUikHaC-DTa"
        />
      </div>
      <div className="StepsPart1-ONXWRI8YcbuVbsAzpBXDm">
        <div className="Step-1OkWDVa-sMy8eu9N8lxZUC">
          <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz   styles-types-module_medium-header5__1wb6V  BulletContainer-1nT2dafkbA6mVzCIQ_kZiO RightBulletContainer-eL8fVVxjuXZpofLY8tdbp">
            <span className="Bullet-2Kqd_4GUq6bzQEwsMhvRmX"></span>
          </div>
          <div className="Content-30cHAqZ-gB-bE5uHaycO5V">
            <div className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7 styles-types-module_base-header4__142rQ  styles-types-module_medium-header5__1wb6V  StepNumber-1vcdnZc0UKptYaCouubgDB">
              {texts.landing.step}&nbsp;01
            </div>
            <h3
              id="fonctionnement"
              className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header3__FKPGi  styles-types-module_medium-header3__3ZkRt  Title-nu6Pqu-rf3Aq-ziNlUC0F"
            >
              Effectuez une estimation
            </h3>
            <div className="Description-3XlN4zVQ3wRX6Q1HZyJRQv">
              <span>
                Découvrez combien votre assurance auto va vous couter en
                quelques clics
              </span>
              <div className="InscriptionCTA-1_K13SHJk7lYZuoDlm3sYL">
                <Button
                  large
                  type="primary"
                  id="link-landing-cta2"
                  href={texts.url.quote_form}
                >
                  {texts.landing.cta}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="Step-1OkWDVa-sMy8eu9N8lxZUC">
          <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz   styles-types-module_medium-header5__1wb6V  BulletContainer-1nT2dafkbA6mVzCIQ_kZiO RightBulletContainer-eL8fVVxjuXZpofLY8tdbp">
            <span className="Bullet-2Kqd_4GUq6bzQEwsMhvRmX"></span>
          </div>
          <div className="Content-30cHAqZ-gB-bE5uHaycO5V">
            <div className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7 styles-types-module_base-header4__142rQ  styles-types-module_medium-header5__1wb6V  StepNumber-1vcdnZc0UKptYaCouubgDB">
              {texts.landing.step}&nbsp;02
            </div>
            <h3 className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header3__FKPGi  styles-types-module_medium-header3__3ZkRt  Title-nu6Pqu-rf3Aq-ziNlUC0F">
              Obtenez un tarif définitif
            </h3>
            <div className="Description-3XlN4zVQ3wRX6Q1HZyJRQv">
              Répondez à quelques questions pour effectuer votre devis,
              évidemment nous sommes disponibles tous les jours du lundi au
              vendredi de 10h à 19h !
            </div>
          </div>
        </div>
        <div className="Step-1OkWDVa-sMy8eu9N8lxZUC">
          <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz   styles-types-module_medium-header5__1wb6V  BulletContainer-1nT2dafkbA6mVzCIQ_kZiO RightBulletContainer-eL8fVVxjuXZpofLY8tdbp">
            <span className="Bullet-2Kqd_4GUq6bzQEwsMhvRmX"></span>
          </div>
          <div className="Content-30cHAqZ-gB-bE5uHaycO5V">
            <div className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7 styles-types-module_base-header4__142rQ  styles-types-module_medium-header5__1wb6V  StepNumber-1vcdnZc0UKptYaCouubgDB">
              {texts.landing.step}&nbsp;03
            </div>
            <h3 className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header3__FKPGi  styles-types-module_medium-header3__3ZkRt  Title-nu6Pqu-rf3Aq-ziNlUC0F">
              Personnalisez votre&nbsp;offre
            </h3>
            <div className="Description-3XlN4zVQ3wRX6Q1HZyJRQv">
              Choississez toutes vos garanties et découvrez combien vous allez
              payer.
            </div>
          </div>
        </div>
      </div>
      <div className="StepsPart2-3qbwBzRqiLgITnla9jcZ9W">
        <div className="Step-1OkWDVa-sMy8eu9N8lxZUC">
          <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz   styles-types-module_medium-header5__1wb6V  BulletContainer-1nT2dafkbA6mVzCIQ_kZiO LeftBulletContainer-29zdLGzR-Gj3KVsUFFPb9o">
            <span className="Bullet-2Kqd_4GUq6bzQEwsMhvRmX"></span>
          </div>
          <div className="Content-30cHAqZ-gB-bE5uHaycO5V">
            <div className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7 styles-types-module_base-header4__142rQ  styles-types-module_medium-header5__1wb6V  StepNumber-1vcdnZc0UKptYaCouubgDB">
              {texts.landing.step}&nbsp;04
            </div>
            <h3 className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header3__FKPGi  styles-types-module_medium-header3__3ZkRt  Title-nu6Pqu-rf3Aq-ziNlUC0F">
              Découvrez votre profil
            </h3>
            <div className="Description-3XlN4zVQ3wRX6Q1HZyJRQv">
              Effectuez un test de profil jeune conducteur. Celui-ci nous
              permettra d'adapter notre produit d'assurance à votre conduite
            </div>
          </div>
        </div>
        <div className="Step-1OkWDVa-sMy8eu9N8lxZUC">
          <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz   styles-types-module_medium-header5__1wb6V  BulletContainer-1nT2dafkbA6mVzCIQ_kZiO LeftBulletContainer-29zdLGzR-Gj3KVsUFFPb9o">
            <span className="Bullet-2Kqd_4GUq6bzQEwsMhvRmX"></span>
          </div>
          <div className="Content-30cHAqZ-gB-bE5uHaycO5V">
            <div className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7 styles-types-module_base-header4__142rQ  styles-types-module_medium-header5__1wb6V  StepNumber-1vcdnZc0UKptYaCouubgDB">
              {texts.landing.step}&nbsp;05
            </div>
            <h3 className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header3__FKPGi  styles-types-module_medium-header3__3ZkRt  Title-nu6Pqu-rf3Aq-ziNlUC0F">
              <span>Validez votre devis</span>
            </h3>
            <div className="Description-3XlN4zVQ3wRX6Q1HZyJRQv">
              Effectuez le paiement sur la plateforme
            </div>
          </div>
        </div>
        <div className="Step-1OkWDVa-sMy8eu9N8lxZUC">
          <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz   styles-types-module_medium-header5__1wb6V  BulletContainer-1nT2dafkbA6mVzCIQ_kZiO LeftBulletContainer-29zdLGzR-Gj3KVsUFFPb9o">
            <span className="Bullet-2Kqd_4GUq6bzQEwsMhvRmX"></span>
          </div>
          <div
            className="Content-30cHAqZ-gB-bE5uHaycO5V"
            style={{ marginBottom: "100px" }}
          >
            <div className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7 styles-types-module_base-header4__142rQ  styles-types-module_medium-header5__1wb6V  StepNumber-1vcdnZc0UKptYaCouubgDB">
              {texts.landing.step}&nbsp;06
            </div>
            <h3 className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header3__FKPGi  styles-types-module_medium-header3__3ZkRt  Title-nu6Pqu-rf3Aq-ziNlUC0F">
              Roulez dès aujourd’hui
            </h3>
            <div className="Description-3XlN4zVQ3wRX6Q1HZyJRQv">
              <span>
                Dès que le paiement est effectué vous êtes assurés pour le mois
                à venir.
              </span>
              <div className="InscriptionCTA-1_K13SHJk7lYZuoDlm3sYL">
                <Button
                  large
                  type="primary"
                  id="link-landing-cta3"
                  href={texts.url.quote_form}
                >
                  Je me lance
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="GirlConfetti-2JXtJVOVL9BGrGmsgtOZRL">
        <Svg8e543b425800ea077f027c83a896c704
          alt="obtention code de la route"
          className="Illustration-RwVUE4CPDJBUikHaC-DTa"
        />
      </div>
    </div>
  </div>
);

export default Steps;
