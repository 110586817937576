import React from "react";

import img45ea9c751f4249a13ec58bbdf3acdc5c from "../../assets/45ea9c751f4249a13ec58bbdf3acdc5c.jpg";

const Testimonial = () => (
  <div className="Reviews-2C8DHS3yGNJ-1OVgKzMcYt">
    <div className="RatingContainer-oGMzz_4E_MNGI6pIpqJax">
      <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header2__1bOT8  styles-types-module_medium-header2__2OOVz  ">
        Excellent !
      </div>
      <div className="Rating-3h--YWCWtPWI-uuQZr8IQf">
        <span className="Rating-2YtL-a1AN57P9tbSwRox0C EmptyColorWhite-1CtmmU1zOnnh-LE5IaV2il">
          <span
            style={{ width: "5.3999999999999915%" }}
            className="EmptyStars-3A8RTh7rK8fRDo2NbzO9Ti"
          >
            <svg viewBox="0 0 52 50">
              <defs>
                <path
                  id="ratingstar_empty-a"
                  d="M24.7785625,39.8115848 L12.1672995,48.7186579 C11.2997716,49.3313749 10.099796,49.1248098 9.48707902,48.2572818 C9.13965184,47.7653699 9.04272687,47.1391096 9.22519704,46.5651865 L13.8599355,31.9875548 C14.1100271,31.2009421 13.8302531,30.3424652 13.1647026,29.8542565 L0.784984206,20.7732236 C-0.0714024168,20.1450288 -0.256388414,18.9415377 0.371806417,18.0851511 C0.730505293,17.5961549 1.29884972,17.3050081 1.9052761,17.2995981 L17.3459838,17.1618484 C18.1644087,17.1545471 18.8885404,16.6300331 19.1506354,15.8546758 L24.0661776,1.31299781 C24.4062899,0.306841549 25.4976562,-0.233093758 26.5038125,0.107018546 C27.0718285,0.299025724 27.5177846,0.744981824 27.7097918,1.31299781 L32.625334,15.8546758 C32.887429,16.6300331 33.6115607,17.1545471 34.4299856,17.1618484 L49.8706933,17.2995981 C50.9327371,17.3090728 51.7860129,18.1777102 51.7765382,19.2397539 C51.7711282,19.8461803 51.4799815,20.4145247 50.9909852,20.7732236 L38.6112668,29.8542565 C37.9457163,30.3424652 37.6659423,31.2009421 37.9160339,31.9875548 L42.5507724,46.5651865 C42.8725738,47.5773479 42.3129265,48.6587384 41.3007652,48.9805399 C40.7268422,49.1630101 40.1005818,49.0660851 39.6086699,48.7186579 L26.9974069,39.8115848 C26.3323656,39.34188 25.4436038,39.34188 24.7785625,39.8115848 Z"
                ></path>
              </defs>
              <use fillRule="evenodd" xlinkHref="#ratingstar_empty-a"></use>
            </svg>
            <svg viewBox="0 0 52 50">
              <defs>
                <path
                  id="ratingstar_empty-a"
                  d="M24.7785625,39.8115848 L12.1672995,48.7186579 C11.2997716,49.3313749 10.099796,49.1248098 9.48707902,48.2572818 C9.13965184,47.7653699 9.04272687,47.1391096 9.22519704,46.5651865 L13.8599355,31.9875548 C14.1100271,31.2009421 13.8302531,30.3424652 13.1647026,29.8542565 L0.784984206,20.7732236 C-0.0714024168,20.1450288 -0.256388414,18.9415377 0.371806417,18.0851511 C0.730505293,17.5961549 1.29884972,17.3050081 1.9052761,17.2995981 L17.3459838,17.1618484 C18.1644087,17.1545471 18.8885404,16.6300331 19.1506354,15.8546758 L24.0661776,1.31299781 C24.4062899,0.306841549 25.4976562,-0.233093758 26.5038125,0.107018546 C27.0718285,0.299025724 27.5177846,0.744981824 27.7097918,1.31299781 L32.625334,15.8546758 C32.887429,16.6300331 33.6115607,17.1545471 34.4299856,17.1618484 L49.8706933,17.2995981 C50.9327371,17.3090728 51.7860129,18.1777102 51.7765382,19.2397539 C51.7711282,19.8461803 51.4799815,20.4145247 50.9909852,20.7732236 L38.6112668,29.8542565 C37.9457163,30.3424652 37.6659423,31.2009421 37.9160339,31.9875548 L42.5507724,46.5651865 C42.8725738,47.5773479 42.3129265,48.6587384 41.3007652,48.9805399 C40.7268422,49.1630101 40.1005818,49.0660851 39.6086699,48.7186579 L26.9974069,39.8115848 C26.3323656,39.34188 25.4436038,39.34188 24.7785625,39.8115848 Z"
                ></path>
              </defs>
              <use fillRule="evenodd" xlinkHref="#ratingstar_empty-a"></use>
            </svg>
            <svg viewBox="0 0 52 50">
              <defs>
                <path
                  id="ratingstar_empty-a"
                  d="M24.7785625,39.8115848 L12.1672995,48.7186579 C11.2997716,49.3313749 10.099796,49.1248098 9.48707902,48.2572818 C9.13965184,47.7653699 9.04272687,47.1391096 9.22519704,46.5651865 L13.8599355,31.9875548 C14.1100271,31.2009421 13.8302531,30.3424652 13.1647026,29.8542565 L0.784984206,20.7732236 C-0.0714024168,20.1450288 -0.256388414,18.9415377 0.371806417,18.0851511 C0.730505293,17.5961549 1.29884972,17.3050081 1.9052761,17.2995981 L17.3459838,17.1618484 C18.1644087,17.1545471 18.8885404,16.6300331 19.1506354,15.8546758 L24.0661776,1.31299781 C24.4062899,0.306841549 25.4976562,-0.233093758 26.5038125,0.107018546 C27.0718285,0.299025724 27.5177846,0.744981824 27.7097918,1.31299781 L32.625334,15.8546758 C32.887429,16.6300331 33.6115607,17.1545471 34.4299856,17.1618484 L49.8706933,17.2995981 C50.9327371,17.3090728 51.7860129,18.1777102 51.7765382,19.2397539 C51.7711282,19.8461803 51.4799815,20.4145247 50.9909852,20.7732236 L38.6112668,29.8542565 C37.9457163,30.3424652 37.6659423,31.2009421 37.9160339,31.9875548 L42.5507724,46.5651865 C42.8725738,47.5773479 42.3129265,48.6587384 41.3007652,48.9805399 C40.7268422,49.1630101 40.1005818,49.0660851 39.6086699,48.7186579 L26.9974069,39.8115848 C26.3323656,39.34188 25.4436038,39.34188 24.7785625,39.8115848 Z"
                ></path>
              </defs>
              <use fillRule="evenodd" xlinkHref="#ratingstar_empty-a"></use>
            </svg>
            <svg viewBox="0 0 52 50">
              <defs>
                <path
                  id="ratingstar_empty-a"
                  d="M24.7785625,39.8115848 L12.1672995,48.7186579 C11.2997716,49.3313749 10.099796,49.1248098 9.48707902,48.2572818 C9.13965184,47.7653699 9.04272687,47.1391096 9.22519704,46.5651865 L13.8599355,31.9875548 C14.1100271,31.2009421 13.8302531,30.3424652 13.1647026,29.8542565 L0.784984206,20.7732236 C-0.0714024168,20.1450288 -0.256388414,18.9415377 0.371806417,18.0851511 C0.730505293,17.5961549 1.29884972,17.3050081 1.9052761,17.2995981 L17.3459838,17.1618484 C18.1644087,17.1545471 18.8885404,16.6300331 19.1506354,15.8546758 L24.0661776,1.31299781 C24.4062899,0.306841549 25.4976562,-0.233093758 26.5038125,0.107018546 C27.0718285,0.299025724 27.5177846,0.744981824 27.7097918,1.31299781 L32.625334,15.8546758 C32.887429,16.6300331 33.6115607,17.1545471 34.4299856,17.1618484 L49.8706933,17.2995981 C50.9327371,17.3090728 51.7860129,18.1777102 51.7765382,19.2397539 C51.7711282,19.8461803 51.4799815,20.4145247 50.9909852,20.7732236 L38.6112668,29.8542565 C37.9457163,30.3424652 37.6659423,31.2009421 37.9160339,31.9875548 L42.5507724,46.5651865 C42.8725738,47.5773479 42.3129265,48.6587384 41.3007652,48.9805399 C40.7268422,49.1630101 40.1005818,49.0660851 39.6086699,48.7186579 L26.9974069,39.8115848 C26.3323656,39.34188 25.4436038,39.34188 24.7785625,39.8115848 Z"
                ></path>
              </defs>
              <use fillRule="evenodd" xlinkHref="#ratingstar_empty-a"></use>
            </svg>
            <svg viewBox="0 0 52 50">
              <defs>
                <path
                  id="ratingstar_empty-a"
                  d="M24.7785625,39.8115848 L12.1672995,48.7186579 C11.2997716,49.3313749 10.099796,49.1248098 9.48707902,48.2572818 C9.13965184,47.7653699 9.04272687,47.1391096 9.22519704,46.5651865 L13.8599355,31.9875548 C14.1100271,31.2009421 13.8302531,30.3424652 13.1647026,29.8542565 L0.784984206,20.7732236 C-0.0714024168,20.1450288 -0.256388414,18.9415377 0.371806417,18.0851511 C0.730505293,17.5961549 1.29884972,17.3050081 1.9052761,17.2995981 L17.3459838,17.1618484 C18.1644087,17.1545471 18.8885404,16.6300331 19.1506354,15.8546758 L24.0661776,1.31299781 C24.4062899,0.306841549 25.4976562,-0.233093758 26.5038125,0.107018546 C27.0718285,0.299025724 27.5177846,0.744981824 27.7097918,1.31299781 L32.625334,15.8546758 C32.887429,16.6300331 33.6115607,17.1545471 34.4299856,17.1618484 L49.8706933,17.2995981 C50.9327371,17.3090728 51.7860129,18.1777102 51.7765382,19.2397539 C51.7711282,19.8461803 51.4799815,20.4145247 50.9909852,20.7732236 L38.6112668,29.8542565 C37.9457163,30.3424652 37.6659423,31.2009421 37.9160339,31.9875548 L42.5507724,46.5651865 C42.8725738,47.5773479 42.3129265,48.6587384 41.3007652,48.9805399 C40.7268422,49.1630101 40.1005818,49.0660851 39.6086699,48.7186579 L26.9974069,39.8115848 C26.3323656,39.34188 25.4436038,39.34188 24.7785625,39.8115848 Z"
                ></path>
              </defs>
              <use fillRule="evenodd" xlinkHref="#ratingstar_empty-a"></use>
            </svg>
          </span>
          <span
            style={{ width: "94.60000000000001%" }}
            className="FullStars-38MAqhE3vPJNl5EIsA2GOe"
          >
            <svg viewBox="0 0 52 50">
              <path
                fillRule="evenodd"
                d="M24.7645898,39.5840573 L12.1429915,48.4429333 C11.2736661,49.0530974 10.0743029,48.8430056 9.46413885,47.9736803 C9.11859903,47.4813757 9.02329363,46.8557583 9.20656779,46.2828952 L13.8326494,31.8230756 C14.0851943,31.033692 13.8035898,30.1713078 13.1338539,29.6830875 L0.789603411,20.6844439 C-0.0686486396,20.0588001 -0.257214855,18.8558648 0.368428982,17.9976128 C0.72688005,17.5058931 1.29690445,17.2128344 1.90538311,17.2074398 L17.3274689,17.0707128 C18.1445922,17.0634685 18.8679066,16.5406235 19.1310544,15.7669982 L24.0487554,1.30950336 C24.390778,0.303994871 25.4831678,-0.233866709 26.4886763,0.108155901 C27.0538959,0.30041472 27.497765,0.744283784 27.6900238,1.30950336 L32.6077248,15.7669982 C32.8708726,16.5406235 33.594187,17.0634685 34.4113103,17.0707128 L49.8333961,17.2074398 C50.8954404,17.2168555 51.7487645,18.0854455 51.7393488,19.1474898 C51.7339542,19.7559685 51.4408954,20.3259929 50.9491758,20.6844439 L38.6049253,29.6830875 C37.9351894,30.1713078 37.653585,31.033692 37.9061298,31.8230756 L42.5322114,46.2828952 C42.8558422,47.2944731 42.2981508,48.3768736 41.2865728,48.7005044 C40.7137097,48.8837786 40.0880923,48.7884731 39.5957878,48.4429333 L26.9741894,39.5840573 C26.3111644,39.1186918 25.4276148,39.1186918 24.7645898,39.5840573 Z"
              ></path>
            </svg>
            <svg viewBox="0 0 52 50">
              <path
                fillRule="evenodd"
                d="M24.7645898,39.5840573 L12.1429915,48.4429333 C11.2736661,49.0530974 10.0743029,48.8430056 9.46413885,47.9736803 C9.11859903,47.4813757 9.02329363,46.8557583 9.20656779,46.2828952 L13.8326494,31.8230756 C14.0851943,31.033692 13.8035898,30.1713078 13.1338539,29.6830875 L0.789603411,20.6844439 C-0.0686486396,20.0588001 -0.257214855,18.8558648 0.368428982,17.9976128 C0.72688005,17.5058931 1.29690445,17.2128344 1.90538311,17.2074398 L17.3274689,17.0707128 C18.1445922,17.0634685 18.8679066,16.5406235 19.1310544,15.7669982 L24.0487554,1.30950336 C24.390778,0.303994871 25.4831678,-0.233866709 26.4886763,0.108155901 C27.0538959,0.30041472 27.497765,0.744283784 27.6900238,1.30950336 L32.6077248,15.7669982 C32.8708726,16.5406235 33.594187,17.0634685 34.4113103,17.0707128 L49.8333961,17.2074398 C50.8954404,17.2168555 51.7487645,18.0854455 51.7393488,19.1474898 C51.7339542,19.7559685 51.4408954,20.3259929 50.9491758,20.6844439 L38.6049253,29.6830875 C37.9351894,30.1713078 37.653585,31.033692 37.9061298,31.8230756 L42.5322114,46.2828952 C42.8558422,47.2944731 42.2981508,48.3768736 41.2865728,48.7005044 C40.7137097,48.8837786 40.0880923,48.7884731 39.5957878,48.4429333 L26.9741894,39.5840573 C26.3111644,39.1186918 25.4276148,39.1186918 24.7645898,39.5840573 Z"
              ></path>
            </svg>
            <svg viewBox="0 0 52 50">
              <path
                fillRule="evenodd"
                d="M24.7645898,39.5840573 L12.1429915,48.4429333 C11.2736661,49.0530974 10.0743029,48.8430056 9.46413885,47.9736803 C9.11859903,47.4813757 9.02329363,46.8557583 9.20656779,46.2828952 L13.8326494,31.8230756 C14.0851943,31.033692 13.8035898,30.1713078 13.1338539,29.6830875 L0.789603411,20.6844439 C-0.0686486396,20.0588001 -0.257214855,18.8558648 0.368428982,17.9976128 C0.72688005,17.5058931 1.29690445,17.2128344 1.90538311,17.2074398 L17.3274689,17.0707128 C18.1445922,17.0634685 18.8679066,16.5406235 19.1310544,15.7669982 L24.0487554,1.30950336 C24.390778,0.303994871 25.4831678,-0.233866709 26.4886763,0.108155901 C27.0538959,0.30041472 27.497765,0.744283784 27.6900238,1.30950336 L32.6077248,15.7669982 C32.8708726,16.5406235 33.594187,17.0634685 34.4113103,17.0707128 L49.8333961,17.2074398 C50.8954404,17.2168555 51.7487645,18.0854455 51.7393488,19.1474898 C51.7339542,19.7559685 51.4408954,20.3259929 50.9491758,20.6844439 L38.6049253,29.6830875 C37.9351894,30.1713078 37.653585,31.033692 37.9061298,31.8230756 L42.5322114,46.2828952 C42.8558422,47.2944731 42.2981508,48.3768736 41.2865728,48.7005044 C40.7137097,48.8837786 40.0880923,48.7884731 39.5957878,48.4429333 L26.9741894,39.5840573 C26.3111644,39.1186918 25.4276148,39.1186918 24.7645898,39.5840573 Z"
              ></path>
            </svg>
            <svg viewBox="0 0 52 50">
              <path
                fillRule="evenodd"
                d="M24.7645898,39.5840573 L12.1429915,48.4429333 C11.2736661,49.0530974 10.0743029,48.8430056 9.46413885,47.9736803 C9.11859903,47.4813757 9.02329363,46.8557583 9.20656779,46.2828952 L13.8326494,31.8230756 C14.0851943,31.033692 13.8035898,30.1713078 13.1338539,29.6830875 L0.789603411,20.6844439 C-0.0686486396,20.0588001 -0.257214855,18.8558648 0.368428982,17.9976128 C0.72688005,17.5058931 1.29690445,17.2128344 1.90538311,17.2074398 L17.3274689,17.0707128 C18.1445922,17.0634685 18.8679066,16.5406235 19.1310544,15.7669982 L24.0487554,1.30950336 C24.390778,0.303994871 25.4831678,-0.233866709 26.4886763,0.108155901 C27.0538959,0.30041472 27.497765,0.744283784 27.6900238,1.30950336 L32.6077248,15.7669982 C32.8708726,16.5406235 33.594187,17.0634685 34.4113103,17.0707128 L49.8333961,17.2074398 C50.8954404,17.2168555 51.7487645,18.0854455 51.7393488,19.1474898 C51.7339542,19.7559685 51.4408954,20.3259929 50.9491758,20.6844439 L38.6049253,29.6830875 C37.9351894,30.1713078 37.653585,31.033692 37.9061298,31.8230756 L42.5322114,46.2828952 C42.8558422,47.2944731 42.2981508,48.3768736 41.2865728,48.7005044 C40.7137097,48.8837786 40.0880923,48.7884731 39.5957878,48.4429333 L26.9741894,39.5840573 C26.3111644,39.1186918 25.4276148,39.1186918 24.7645898,39.5840573 Z"
              ></path>
            </svg>
            <svg viewBox="0 0 52 50">
              <path
                fillRule="evenodd"
                d="M24.7645898,39.5840573 L12.1429915,48.4429333 C11.2736661,49.0530974 10.0743029,48.8430056 9.46413885,47.9736803 C9.11859903,47.4813757 9.02329363,46.8557583 9.20656779,46.2828952 L13.8326494,31.8230756 C14.0851943,31.033692 13.8035898,30.1713078 13.1338539,29.6830875 L0.789603411,20.6844439 C-0.0686486396,20.0588001 -0.257214855,18.8558648 0.368428982,17.9976128 C0.72688005,17.5058931 1.29690445,17.2128344 1.90538311,17.2074398 L17.3274689,17.0707128 C18.1445922,17.0634685 18.8679066,16.5406235 19.1310544,15.7669982 L24.0487554,1.30950336 C24.390778,0.303994871 25.4831678,-0.233866709 26.4886763,0.108155901 C27.0538959,0.30041472 27.497765,0.744283784 27.6900238,1.30950336 L32.6077248,15.7669982 C32.8708726,16.5406235 33.594187,17.0634685 34.4113103,17.0707128 L49.8333961,17.2074398 C50.8954404,17.2168555 51.7487645,18.0854455 51.7393488,19.1474898 C51.7339542,19.7559685 51.4408954,20.3259929 50.9491758,20.6844439 L38.6049253,29.6830875 C37.9351894,30.1713078 37.653585,31.033692 37.9061298,31.8230756 L42.5322114,46.2828952 C42.8558422,47.2944731 42.2981508,48.3768736 41.2865728,48.7005044 C40.7137097,48.8837786 40.0880923,48.7884731 39.5957878,48.4429333 L26.9741894,39.5840573 C26.3111644,39.1186918 25.4276148,39.1186918 24.7645898,39.5840573 Z"
              ></path>
            </svg>
          </span>
        </span>
      </div>
      <div className="RatingDetails-1mxs7nqYlZCVIBBdMTGeUu">
        <p className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-body__2VaSM  styles-types-module_medium-body__hjzlF  ">
          Note établie sur <span>3&nbsp;086</span> avis récoltés par&nbsp;
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="styles-module_Link__GVqkR    styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz     "
            id="link-landing-trustedshop"
            href="https://www.trustedshops.fr/evaluation/info_X12ADF3C4F14ED060472977E1D70CFA55.html"
          >
            Trusted Shops
          </a>
          .
        </p>
      </div>
      <div className="RatingSource-3d_f2LUkurah5Io--RssNT">
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="styles-module_Link__GVqkR    styles-module_Button__J65Ds reset-module_ResetButton__1dTnc   styles-module_Accent__3MXDT          "
          id="link-landing-trustedshop-avis"
          href="https://www.trustedshops.fr/evaluation/info_X12ADF3C4F14ED060472977E1D70CFA55.html"
        >
          <div tabIndex="-1" className="styles-module_Content__oxWk7">
            <span className="styles-module_Children__2CK5r">
              Voir tous les avis
            </span>
          </div>
        </a>
      </div>
    </div>
    <div className="Testimonial-3LFChE7dMJyfl7mBNIKx-n">
      <div className="Testimonial-EcyC9QFzmCo5drptIph1t">
        <blockquote className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-header4__142rQ  styles-types-module_medium-header5__1wb6V  AuthorQuote-3gkYgqgUxYs-6YnCCdJohw">
          “ Quand on achète son premier véhicule et assurance, on est jamais
          serein. L’offre Ornikar était juste parfaite ! Un grand merci,{" "}
          <span className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz     ">
            je recommande !
          </span>
          ”
        </blockquote>
        <div className="Author-3AgP-tRKNlzv5yhsoax9cG">
          <div className="AvatarContainer-1acruv5Qw2qInxyUq8qWhD">
            <div className="styles-module_AvatarWrapper__2-SpI   ">
              <img src={img45ea9c751f4249a13ec58bbdf3acdc5c} alt="" />
            </div>
          </div>
          <div className="AuthorDetails-2mEvbPDZTWc2E2ubMUBwan">
            <cite className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header4__142rQ  styles-types-module_medium-header5__1wb6V  AuthorName-2vAHG-nUjpZI0HWuEdp9MP">
              Alex L.
            </cite>
            <div>Grenoble</div>
          </div>
          <div className="AuthorRating-3L854_unuigHd_U1BCYrQI">
            <span className="Rating-2YtL-a1AN57P9tbSwRox0C Small-2N7CzObSSe2qLT1HZi-IHM">
              <span
                style={{ width: "0%" }}
                className="EmptyStars-3A8RTh7rK8fRDo2NbzO9Ti"
              >
                <svg viewBox="0 0 52 50">
                  <defs>
                    <path
                      id="ratingstar_empty-a"
                      d="M24.7785625,39.8115848 L12.1672995,48.7186579 C11.2997716,49.3313749 10.099796,49.1248098 9.48707902,48.2572818 C9.13965184,47.7653699 9.04272687,47.1391096 9.22519704,46.5651865 L13.8599355,31.9875548 C14.1100271,31.2009421 13.8302531,30.3424652 13.1647026,29.8542565 L0.784984206,20.7732236 C-0.0714024168,20.1450288 -0.256388414,18.9415377 0.371806417,18.0851511 C0.730505293,17.5961549 1.29884972,17.3050081 1.9052761,17.2995981 L17.3459838,17.1618484 C18.1644087,17.1545471 18.8885404,16.6300331 19.1506354,15.8546758 L24.0661776,1.31299781 C24.4062899,0.306841549 25.4976562,-0.233093758 26.5038125,0.107018546 C27.0718285,0.299025724 27.5177846,0.744981824 27.7097918,1.31299781 L32.625334,15.8546758 C32.887429,16.6300331 33.6115607,17.1545471 34.4299856,17.1618484 L49.8706933,17.2995981 C50.9327371,17.3090728 51.7860129,18.1777102 51.7765382,19.2397539 C51.7711282,19.8461803 51.4799815,20.4145247 50.9909852,20.7732236 L38.6112668,29.8542565 C37.9457163,30.3424652 37.6659423,31.2009421 37.9160339,31.9875548 L42.5507724,46.5651865 C42.8725738,47.5773479 42.3129265,48.6587384 41.3007652,48.9805399 C40.7268422,49.1630101 40.1005818,49.0660851 39.6086699,48.7186579 L26.9974069,39.8115848 C26.3323656,39.34188 25.4436038,39.34188 24.7785625,39.8115848 Z"
                    ></path>
                  </defs>
                  <use fillRule="evenodd" xlinkHref="#ratingstar_empty-a"></use>
                </svg>
                <svg viewBox="0 0 52 50">
                  <defs>
                    <path
                      id="ratingstar_empty-a"
                      d="M24.7785625,39.8115848 L12.1672995,48.7186579 C11.2997716,49.3313749 10.099796,49.1248098 9.48707902,48.2572818 C9.13965184,47.7653699 9.04272687,47.1391096 9.22519704,46.5651865 L13.8599355,31.9875548 C14.1100271,31.2009421 13.8302531,30.3424652 13.1647026,29.8542565 L0.784984206,20.7732236 C-0.0714024168,20.1450288 -0.256388414,18.9415377 0.371806417,18.0851511 C0.730505293,17.5961549 1.29884972,17.3050081 1.9052761,17.2995981 L17.3459838,17.1618484 C18.1644087,17.1545471 18.8885404,16.6300331 19.1506354,15.8546758 L24.0661776,1.31299781 C24.4062899,0.306841549 25.4976562,-0.233093758 26.5038125,0.107018546 C27.0718285,0.299025724 27.5177846,0.744981824 27.7097918,1.31299781 L32.625334,15.8546758 C32.887429,16.6300331 33.6115607,17.1545471 34.4299856,17.1618484 L49.8706933,17.2995981 C50.9327371,17.3090728 51.7860129,18.1777102 51.7765382,19.2397539 C51.7711282,19.8461803 51.4799815,20.4145247 50.9909852,20.7732236 L38.6112668,29.8542565 C37.9457163,30.3424652 37.6659423,31.2009421 37.9160339,31.9875548 L42.5507724,46.5651865 C42.8725738,47.5773479 42.3129265,48.6587384 41.3007652,48.9805399 C40.7268422,49.1630101 40.1005818,49.0660851 39.6086699,48.7186579 L26.9974069,39.8115848 C26.3323656,39.34188 25.4436038,39.34188 24.7785625,39.8115848 Z"
                    ></path>
                  </defs>
                  <use fillRule="evenodd" xlinkHref="#ratingstar_empty-a"></use>
                </svg>
                <svg viewBox="0 0 52 50">
                  <defs>
                    <path
                      id="ratingstar_empty-a"
                      d="M24.7785625,39.8115848 L12.1672995,48.7186579 C11.2997716,49.3313749 10.099796,49.1248098 9.48707902,48.2572818 C9.13965184,47.7653699 9.04272687,47.1391096 9.22519704,46.5651865 L13.8599355,31.9875548 C14.1100271,31.2009421 13.8302531,30.3424652 13.1647026,29.8542565 L0.784984206,20.7732236 C-0.0714024168,20.1450288 -0.256388414,18.9415377 0.371806417,18.0851511 C0.730505293,17.5961549 1.29884972,17.3050081 1.9052761,17.2995981 L17.3459838,17.1618484 C18.1644087,17.1545471 18.8885404,16.6300331 19.1506354,15.8546758 L24.0661776,1.31299781 C24.4062899,0.306841549 25.4976562,-0.233093758 26.5038125,0.107018546 C27.0718285,0.299025724 27.5177846,0.744981824 27.7097918,1.31299781 L32.625334,15.8546758 C32.887429,16.6300331 33.6115607,17.1545471 34.4299856,17.1618484 L49.8706933,17.2995981 C50.9327371,17.3090728 51.7860129,18.1777102 51.7765382,19.2397539 C51.7711282,19.8461803 51.4799815,20.4145247 50.9909852,20.7732236 L38.6112668,29.8542565 C37.9457163,30.3424652 37.6659423,31.2009421 37.9160339,31.9875548 L42.5507724,46.5651865 C42.8725738,47.5773479 42.3129265,48.6587384 41.3007652,48.9805399 C40.7268422,49.1630101 40.1005818,49.0660851 39.6086699,48.7186579 L26.9974069,39.8115848 C26.3323656,39.34188 25.4436038,39.34188 24.7785625,39.8115848 Z"
                    ></path>
                  </defs>
                  <use fillRule="evenodd" xlinkHref="#ratingstar_empty-a"></use>
                </svg>
                <svg viewBox="0 0 52 50">
                  <defs>
                    <path
                      id="ratingstar_empty-a"
                      d="M24.7785625,39.8115848 L12.1672995,48.7186579 C11.2997716,49.3313749 10.099796,49.1248098 9.48707902,48.2572818 C9.13965184,47.7653699 9.04272687,47.1391096 9.22519704,46.5651865 L13.8599355,31.9875548 C14.1100271,31.2009421 13.8302531,30.3424652 13.1647026,29.8542565 L0.784984206,20.7732236 C-0.0714024168,20.1450288 -0.256388414,18.9415377 0.371806417,18.0851511 C0.730505293,17.5961549 1.29884972,17.3050081 1.9052761,17.2995981 L17.3459838,17.1618484 C18.1644087,17.1545471 18.8885404,16.6300331 19.1506354,15.8546758 L24.0661776,1.31299781 C24.4062899,0.306841549 25.4976562,-0.233093758 26.5038125,0.107018546 C27.0718285,0.299025724 27.5177846,0.744981824 27.7097918,1.31299781 L32.625334,15.8546758 C32.887429,16.6300331 33.6115607,17.1545471 34.4299856,17.1618484 L49.8706933,17.2995981 C50.9327371,17.3090728 51.7860129,18.1777102 51.7765382,19.2397539 C51.7711282,19.8461803 51.4799815,20.4145247 50.9909852,20.7732236 L38.6112668,29.8542565 C37.9457163,30.3424652 37.6659423,31.2009421 37.9160339,31.9875548 L42.5507724,46.5651865 C42.8725738,47.5773479 42.3129265,48.6587384 41.3007652,48.9805399 C40.7268422,49.1630101 40.1005818,49.0660851 39.6086699,48.7186579 L26.9974069,39.8115848 C26.3323656,39.34188 25.4436038,39.34188 24.7785625,39.8115848 Z"
                    ></path>
                  </defs>
                  <use fillRule="evenodd" xlinkHref="#ratingstar_empty-a"></use>
                </svg>
                <svg viewBox="0 0 52 50">
                  <defs>
                    <path
                      id="ratingstar_empty-a"
                      d="M24.7785625,39.8115848 L12.1672995,48.7186579 C11.2997716,49.3313749 10.099796,49.1248098 9.48707902,48.2572818 C9.13965184,47.7653699 9.04272687,47.1391096 9.22519704,46.5651865 L13.8599355,31.9875548 C14.1100271,31.2009421 13.8302531,30.3424652 13.1647026,29.8542565 L0.784984206,20.7732236 C-0.0714024168,20.1450288 -0.256388414,18.9415377 0.371806417,18.0851511 C0.730505293,17.5961549 1.29884972,17.3050081 1.9052761,17.2995981 L17.3459838,17.1618484 C18.1644087,17.1545471 18.8885404,16.6300331 19.1506354,15.8546758 L24.0661776,1.31299781 C24.4062899,0.306841549 25.4976562,-0.233093758 26.5038125,0.107018546 C27.0718285,0.299025724 27.5177846,0.744981824 27.7097918,1.31299781 L32.625334,15.8546758 C32.887429,16.6300331 33.6115607,17.1545471 34.4299856,17.1618484 L49.8706933,17.2995981 C50.9327371,17.3090728 51.7860129,18.1777102 51.7765382,19.2397539 C51.7711282,19.8461803 51.4799815,20.4145247 50.9909852,20.7732236 L38.6112668,29.8542565 C37.9457163,30.3424652 37.6659423,31.2009421 37.9160339,31.9875548 L42.5507724,46.5651865 C42.8725738,47.5773479 42.3129265,48.6587384 41.3007652,48.9805399 C40.7268422,49.1630101 40.1005818,49.0660851 39.6086699,48.7186579 L26.9974069,39.8115848 C26.3323656,39.34188 25.4436038,39.34188 24.7785625,39.8115848 Z"
                    ></path>
                  </defs>
                  <use fillRule="evenodd" xlinkHref="#ratingstar_empty-a"></use>
                </svg>
              </span>
              <span
                style={{ width: "100%" }}
                className="FullStars-38MAqhE3vPJNl5EIsA2GOe"
              >
                <svg viewBox="0 0 52 50">
                  <path
                    fillRule="evenodd"
                    d="M24.7645898,39.5840573 L12.1429915,48.4429333 C11.2736661,49.0530974 10.0743029,48.8430056 9.46413885,47.9736803 C9.11859903,47.4813757 9.02329363,46.8557583 9.20656779,46.2828952 L13.8326494,31.8230756 C14.0851943,31.033692 13.8035898,30.1713078 13.1338539,29.6830875 L0.789603411,20.6844439 C-0.0686486396,20.0588001 -0.257214855,18.8558648 0.368428982,17.9976128 C0.72688005,17.5058931 1.29690445,17.2128344 1.90538311,17.2074398 L17.3274689,17.0707128 C18.1445922,17.0634685 18.8679066,16.5406235 19.1310544,15.7669982 L24.0487554,1.30950336 C24.390778,0.303994871 25.4831678,-0.233866709 26.4886763,0.108155901 C27.0538959,0.30041472 27.497765,0.744283784 27.6900238,1.30950336 L32.6077248,15.7669982 C32.8708726,16.5406235 33.594187,17.0634685 34.4113103,17.0707128 L49.8333961,17.2074398 C50.8954404,17.2168555 51.7487645,18.0854455 51.7393488,19.1474898 C51.7339542,19.7559685 51.4408954,20.3259929 50.9491758,20.6844439 L38.6049253,29.6830875 C37.9351894,30.1713078 37.653585,31.033692 37.9061298,31.8230756 L42.5322114,46.2828952 C42.8558422,47.2944731 42.2981508,48.3768736 41.2865728,48.7005044 C40.7137097,48.8837786 40.0880923,48.7884731 39.5957878,48.4429333 L26.9741894,39.5840573 C26.3111644,39.1186918 25.4276148,39.1186918 24.7645898,39.5840573 Z"
                  ></path>
                </svg>
                <svg viewBox="0 0 52 50">
                  <path
                    fillRule="evenodd"
                    d="M24.7645898,39.5840573 L12.1429915,48.4429333 C11.2736661,49.0530974 10.0743029,48.8430056 9.46413885,47.9736803 C9.11859903,47.4813757 9.02329363,46.8557583 9.20656779,46.2828952 L13.8326494,31.8230756 C14.0851943,31.033692 13.8035898,30.1713078 13.1338539,29.6830875 L0.789603411,20.6844439 C-0.0686486396,20.0588001 -0.257214855,18.8558648 0.368428982,17.9976128 C0.72688005,17.5058931 1.29690445,17.2128344 1.90538311,17.2074398 L17.3274689,17.0707128 C18.1445922,17.0634685 18.8679066,16.5406235 19.1310544,15.7669982 L24.0487554,1.30950336 C24.390778,0.303994871 25.4831678,-0.233866709 26.4886763,0.108155901 C27.0538959,0.30041472 27.497765,0.744283784 27.6900238,1.30950336 L32.6077248,15.7669982 C32.8708726,16.5406235 33.594187,17.0634685 34.4113103,17.0707128 L49.8333961,17.2074398 C50.8954404,17.2168555 51.7487645,18.0854455 51.7393488,19.1474898 C51.7339542,19.7559685 51.4408954,20.3259929 50.9491758,20.6844439 L38.6049253,29.6830875 C37.9351894,30.1713078 37.653585,31.033692 37.9061298,31.8230756 L42.5322114,46.2828952 C42.8558422,47.2944731 42.2981508,48.3768736 41.2865728,48.7005044 C40.7137097,48.8837786 40.0880923,48.7884731 39.5957878,48.4429333 L26.9741894,39.5840573 C26.3111644,39.1186918 25.4276148,39.1186918 24.7645898,39.5840573 Z"
                  ></path>
                </svg>
                <svg viewBox="0 0 52 50">
                  <path
                    fillRule="evenodd"
                    d="M24.7645898,39.5840573 L12.1429915,48.4429333 C11.2736661,49.0530974 10.0743029,48.8430056 9.46413885,47.9736803 C9.11859903,47.4813757 9.02329363,46.8557583 9.20656779,46.2828952 L13.8326494,31.8230756 C14.0851943,31.033692 13.8035898,30.1713078 13.1338539,29.6830875 L0.789603411,20.6844439 C-0.0686486396,20.0588001 -0.257214855,18.8558648 0.368428982,17.9976128 C0.72688005,17.5058931 1.29690445,17.2128344 1.90538311,17.2074398 L17.3274689,17.0707128 C18.1445922,17.0634685 18.8679066,16.5406235 19.1310544,15.7669982 L24.0487554,1.30950336 C24.390778,0.303994871 25.4831678,-0.233866709 26.4886763,0.108155901 C27.0538959,0.30041472 27.497765,0.744283784 27.6900238,1.30950336 L32.6077248,15.7669982 C32.8708726,16.5406235 33.594187,17.0634685 34.4113103,17.0707128 L49.8333961,17.2074398 C50.8954404,17.2168555 51.7487645,18.0854455 51.7393488,19.1474898 C51.7339542,19.7559685 51.4408954,20.3259929 50.9491758,20.6844439 L38.6049253,29.6830875 C37.9351894,30.1713078 37.653585,31.033692 37.9061298,31.8230756 L42.5322114,46.2828952 C42.8558422,47.2944731 42.2981508,48.3768736 41.2865728,48.7005044 C40.7137097,48.8837786 40.0880923,48.7884731 39.5957878,48.4429333 L26.9741894,39.5840573 C26.3111644,39.1186918 25.4276148,39.1186918 24.7645898,39.5840573 Z"
                  ></path>
                </svg>
                <svg viewBox="0 0 52 50">
                  <path
                    fillRule="evenodd"
                    d="M24.7645898,39.5840573 L12.1429915,48.4429333 C11.2736661,49.0530974 10.0743029,48.8430056 9.46413885,47.9736803 C9.11859903,47.4813757 9.02329363,46.8557583 9.20656779,46.2828952 L13.8326494,31.8230756 C14.0851943,31.033692 13.8035898,30.1713078 13.1338539,29.6830875 L0.789603411,20.6844439 C-0.0686486396,20.0588001 -0.257214855,18.8558648 0.368428982,17.9976128 C0.72688005,17.5058931 1.29690445,17.2128344 1.90538311,17.2074398 L17.3274689,17.0707128 C18.1445922,17.0634685 18.8679066,16.5406235 19.1310544,15.7669982 L24.0487554,1.30950336 C24.390778,0.303994871 25.4831678,-0.233866709 26.4886763,0.108155901 C27.0538959,0.30041472 27.497765,0.744283784 27.6900238,1.30950336 L32.6077248,15.7669982 C32.8708726,16.5406235 33.594187,17.0634685 34.4113103,17.0707128 L49.8333961,17.2074398 C50.8954404,17.2168555 51.7487645,18.0854455 51.7393488,19.1474898 C51.7339542,19.7559685 51.4408954,20.3259929 50.9491758,20.6844439 L38.6049253,29.6830875 C37.9351894,30.1713078 37.653585,31.033692 37.9061298,31.8230756 L42.5322114,46.2828952 C42.8558422,47.2944731 42.2981508,48.3768736 41.2865728,48.7005044 C40.7137097,48.8837786 40.0880923,48.7884731 39.5957878,48.4429333 L26.9741894,39.5840573 C26.3111644,39.1186918 25.4276148,39.1186918 24.7645898,39.5840573 Z"
                  ></path>
                </svg>
                <svg viewBox="0 0 52 50">
                  <path
                    fillRule="evenodd"
                    d="M24.7645898,39.5840573 L12.1429915,48.4429333 C11.2736661,49.0530974 10.0743029,48.8430056 9.46413885,47.9736803 C9.11859903,47.4813757 9.02329363,46.8557583 9.20656779,46.2828952 L13.8326494,31.8230756 C14.0851943,31.033692 13.8035898,30.1713078 13.1338539,29.6830875 L0.789603411,20.6844439 C-0.0686486396,20.0588001 -0.257214855,18.8558648 0.368428982,17.9976128 C0.72688005,17.5058931 1.29690445,17.2128344 1.90538311,17.2074398 L17.3274689,17.0707128 C18.1445922,17.0634685 18.8679066,16.5406235 19.1310544,15.7669982 L24.0487554,1.30950336 C24.390778,0.303994871 25.4831678,-0.233866709 26.4886763,0.108155901 C27.0538959,0.30041472 27.497765,0.744283784 27.6900238,1.30950336 L32.6077248,15.7669982 C32.8708726,16.5406235 33.594187,17.0634685 34.4113103,17.0707128 L49.8333961,17.2074398 C50.8954404,17.2168555 51.7487645,18.0854455 51.7393488,19.1474898 C51.7339542,19.7559685 51.4408954,20.3259929 50.9491758,20.6844439 L38.6049253,29.6830875 C37.9351894,30.1713078 37.653585,31.033692 37.9061298,31.8230756 L42.5322114,46.2828952 C42.8558422,47.2944731 42.2981508,48.3768736 41.2865728,48.7005044 C40.7137097,48.8837786 40.0880923,48.7884731 39.5957878,48.4429333 L26.9741894,39.5840573 C26.3111644,39.1186918 25.4276148,39.1186918 24.7645898,39.5840573 Z"
                  ></path>
                </svg>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Testimonial;
