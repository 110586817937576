import cx from 'classnames';
import React, { Component, createRef, ReactElement } from 'react';
import MapboxGL, { AnySourceData, LngLatBoundsLike } from 'mapbox-gl';
import addMarker, { Popup } from 'components/InteractiveMap/addMarker';
import { MarkerProps } from 'components/Map/Marker';
import styles from './styles.module.css';
import 'mapbox-gl/dist/mapbox-gl.css';

// need this variable because of babel-plugin-minify-replace : typeof 'node' extends 'node' is not valid !
const target = __TARGET__;

const mapboxglPromise: typeof target extends 'node' ? undefined : Promise<typeof MapboxGL> = (target === 'node'
  ? undefined
  : import('mapbox-gl' /* webpackChunkName: 'mapbox-gl' */).then((res) => {
      const mapboxgl = res.default || res;
      mapboxgl.accessToken = process.env.MAP_BOX_API_KEY as string;
      return mapboxgl;
    })) as typeof target extends 'node' ? undefined : Promise<typeof MapboxGL>;

export interface Marker {
  lat: number;
  lng: number;
  popup?: Popup;
  type?: MarkerProps['type'];
}

const pinPadding = 150;

interface Point {
  lat: number;
  lng: number;
}

interface Layer {
  sourceName: string;
  source: AnySourceData;
  layers: any[];
}

export interface InteractiveMapProps {
  standalone?: boolean;
  markers?: Marker[];
  zoom?: number;
  forcedCenter?: Point;
  layers?: Layer[];
  onLoad?: (map: mapboxgl.Map) => void;
}

export default class InteractiveMap extends Component<InteractiveMapProps> {
  static defaultProps = {
    zoom: 8,
  };

  static mapboxglPromise = mapboxglPromise;

  mapContainerRef = createRef<HTMLDivElement>();

  map: MapboxGL.Map | undefined = undefined;

  componentDidMount(): void {
    if (__TARGET__ !== 'node') {
      mapboxglPromise.then((mapboxgl) => {
        const { markers, zoom } = this.props;

        const bounds: LngLatBoundsLike | undefined = markers?.reduce(
          (prevBound, marker) =>
            prevBound.extend(
              new mapboxgl.LngLatBounds({ lat: marker.lat, lng: marker.lng }, { lat: marker.lat, lng: marker.lng }),
            ),
          new mapboxgl.LngLatBounds(
            { lat: markers[0].lat, lng: markers[0].lng },
            { lat: markers[0].lat, lng: markers[0].lng },
          ),
        );

        const map = new mapboxgl.Map({
          container: this.mapContainerRef.current as HTMLDivElement,
          style: 'mapbox://styles/ornikar/cjlf9rdcm8zbt2sm2br1s4fo7',
          center: this.props.forcedCenter || bounds?.getCenter(),
          zoom,
        });
        this.map = map;

        if (markers && markers.length > 1 && !this.props.forcedCenter && bounds) {
          map.fitBounds(bounds, { padding: pinPadding, duration: 0 });
        }

        map.on('load', () => {
          if (this.props.onLoad) {
            this.props.onLoad(map);
          }

          map.addControl(new mapboxgl.NavigationControl(), 'top-left');

          if (markers) {
            markers.forEach(({ lat, lng, type, popup }) => {
              (addMarker as NonNullable<typeof addMarker>)(mapboxgl, map, lng, lat, type, popup);
            });
          }

          if (this.props.layers) {
            this.props.layers.forEach(({ sourceName, source, layers }) => {
              map.addSource(sourceName, source);
              layers.forEach((layer) => map.addLayer(layer));
            });
          }
        });
      });
    }
  }

  componentDidUpdate(prevProps: InteractiveMapProps): void {
    const map = this.map as MapboxGL.Map;
    // occurs with jest tests
    if (target === 'node') return;
    // ensure componentDidMount ended before calling update
    mapboxglPromise.then(() => {
      // component was unmount
      if (!map) return;

      if (prevProps.forcedCenter !== this.props.forcedCenter && this.props.forcedCenter) {
        map.setCenter(this.props.forcedCenter);
      }

      if (prevProps.zoom !== this.props.zoom && this.props.zoom) {
        map.setZoom(this.props.zoom);
      }
    });
  }

  render(): ReactElement {
    return (
      <div
        ref={this.mapContainerRef}
        className={cx(styles.MapContainer, {
          [styles.Standalone]: this.props.standalone,
        })}
      />
    );
  }
}
