import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { KittTransitionProps } from 'components/Transitions/propTypes';
import styles from './styles.module.css';

const classNames = {
  appear: styles.ZoomInEnter,
  appearActive: styles.ZoomInEnterActive,
  enter: styles.ZoomInEnter,
  enterActive: styles.ZoomInEnterActive,
  exit: styles.ZoomInExit,
  exitActive: styles.ZoomInExitActive,
};

export default function ZoomIn({
  appear,
  children,
  visible,
  onEnter,
  onExit,
  onEntered,
  onExited,
}: KittTransitionProps) {
  return (
    <CSSTransition
      unmountOnExit
      in={visible}
      timeout={{
        enter: 300,
        exit: 250,
      }}
      appear={appear}
      classNames={classNames}
      onEnter={onEnter}
      onExit={onExit}
      onEntered={onEntered}
      onExited={onExited}
    >
      {children()}
    </CSSTransition>
  );
}
