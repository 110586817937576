import React, { useCallback, useState } from "react";

import texts from "../../configs/texts";

import Question from "./wrapper";
import useFormField from "../../hooks/useFormField";
import Validate from "./validate";
import { InputText, InputFeedback, LargeLoader } from "@ornikar/kitt";
import { validCP } from "../../types/answers";

const doFetchCommunes = async (code_postal) => {
  const response = await fetch("/communes/" + code_postal);
  const json = await response.json();
  return json.map((obj) => obj.commune);
};

const Qcp = ({ answer, draft, ...props }) => {
  const [cp, cpField, state] = useFormField(draft.cp, validCP);
  const [ssvState, setSSVState] = useState({ loading: false, error: null });

  const next = useCallback(() => {
    if (state === "valid") {
      setSSVState({ loading: true, error: null });
      doFetchCommunes(cp).then((communes) => {
        if (communes.length) answer({ cp });
        else
          setSSVState({
            loading: false,
            error: texts.parking_codepostal.unknown,
          });
      });
    }
  }, [answer, cp, state]);

  if (ssvState.error) cpField.state = "invalid";

  return (
    <Question {...props} title={texts.cp.question} id="Qcp">
      <InputText
        {...cpField}
        onChange={(e) => {
          setSSVState({ loading: false, error: null });
          cpField.onChange(e);
        }}
        type="number"
        name="cp"
        autoComplete="postal-code"
        placeholder={texts.cp.placeholder}
        onKeyPress={(e) => e.key === "Enter" && next()}
      />
      {state === "invalid" && (
        <InputFeedback state={state}>{texts.cp.invalid}</InputFeedback>
      )}
      {ssvState.error && (
        <InputFeedback state="invalid">{ssvState.error}</InputFeedback>
      )}
      {ssvState.loading ? (
        <LargeLoader />
      ) : (
        <Validate
          id="btn-validate-cp"
          disabled={state !== "valid"}
          onClick={next}
        />
      )}
    </Question>
  );
};
export default Qcp;
