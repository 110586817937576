import cx from 'classnames';
import React, { ReactNode, ReactElement } from 'react';
import CloseButton, { CloseButtonProps } from './CloseButton';
import styles from './styles.module.css';

export interface ContentProps {
  children: ReactNode;
  className?: string;
  withCloseButton?: boolean;
  onClose?: CloseButtonProps['onClick'];
}

const Content = ({ children, className, withCloseButton, onClose }: ContentProps): ReactElement => (
  <div className={cx(styles.Container, className)}>
    {children}
    {withCloseButton && onClose && <CloseButton onClick={onClose} />}
  </div>
);

export default Content;
