import { Except } from 'type-fest';
import React, { ReactElement, ChangeEvent } from 'react';
import Dropdown, { DropdownProps } from '../Dropdown';
import styles from './styles.module.css';

interface Choice<Value extends string | number> {
  value: Value;
  label?: string;
  active?: boolean;
}

function getLabel<Values extends string | number>(
  selectedValue: undefined | Values,
  placeholder: string | undefined,
  choices: Choice<Values>[],
): string {
  if (!selectedValue) {
    return placeholder as string;
  }
  const selectedChoice = choices.find((ch) => ch.value === selectedValue);
  return selectedChoice?.label as string;
}

type NativeSelectCallback<Values> = (value: Values, e: ChangeEvent<HTMLSelectElement>) => void;

interface BaseDropdownSelectProps<Values extends string | number>
  extends Except<DropdownProps<Values>, 'label' | 'value' | 'onSelectItem' | 'variant' | 'children'> {
  name?: string;
  choices: Choice<Values>[];
  value?: Values;
  placeholder?: string;
  onChange?: DropdownProps<Values>['onSelectItem'] | NativeSelectCallback<Values>;
}

interface DropdownSelectWithPlaceholderProps<Values extends string | number> extends BaseDropdownSelectProps<Values> {
  placeholder: string;
}

interface DropdownSelectWithValueProps<Values extends string | number> extends BaseDropdownSelectProps<Values> {
  value: Values;
}
interface DropdownSelectWithValueAndPlaceholderProps<Values extends string | number>
  extends BaseDropdownSelectProps<Values> {
  value: Values;
}

export type DropdownSelectProps<Values extends string | number> =
  | DropdownSelectWithPlaceholderProps<Values>
  | DropdownSelectWithValueProps<Values>
  | DropdownSelectWithValueAndPlaceholderProps<Values>;

export default function DropdownSelect<Values extends string | number>(
  props: DropdownSelectProps<Values>,
): ReactElement {
  const { name, value: selectedValue, choices, placeholder, onChange, ...dropdownProps } = props;
  return (
    <div className={styles.DropdownSelect}>
      <Dropdown
        large
        label={getLabel(selectedValue, placeholder, choices)}
        value={selectedValue}
        onSelectItem={onChange as DropdownProps<Values>['onSelectItem']}
        {...dropdownProps}
      >
        {choices.map(({ value, active, label }) => (
          <Dropdown.Item key={value} value={value} active={active}>
            {label}
          </Dropdown.Item>
        ))}
      </Dropdown>
      <select
        className={styles.Select}
        name={name}
        value={choices.findIndex((choice) => choice.value === selectedValue)}
        onChange={
          onChange
            ? (e: ChangeEvent<HTMLSelectElement>): void => {
                const indexAsString = (e.target as any).value;
                if (!choices[indexAsString]) throw new Error(`Invalid index value: ${indexAsString}`);
                return onChange(choices[indexAsString].value, e as any);
              }
            : undefined
        }
      >
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {choices.map((choice, index) => (
          <option key={choice.value} value={index}>
            {choice.label}
          </option>
        ))}
      </select>
    </div>
  );
}
