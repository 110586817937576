import React, { ReactNode, ReactElement } from 'react';
import styles from './styles.module.css';

export interface FooterProps {
  children: ReactNode;
}

export default function Footer({ children }: FooterProps): ReactElement {
  return <div className={styles.Footer}>{children}</div>;
}

Footer.modalFooterSymbol = {};
