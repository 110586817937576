import React from "react";

const SorryMessage = () => (
  <>
    <h1 className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header2__1bOT8  styles-types-module_medium-header2__2OOVz  styles_Title__h1Kcp">
      <span className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7     ">
        Vous êtes un cas passionnant.
      </span>
    </h1>
    <div className="styles_Description__zIzcq">
      <div>
        <p>
          Malheureusement, nous ne sommes pas en mesure de vous proposer un
          devis <strong>estimatif</strong>.
        </p>

        <p>
          Pas d&apos;inquiétude, vous pouvez obtenir <strong>votre tarif définitif</strong> en
          répondant à quelques questions supplémentaires.
        </p>
      </div>
    </div>
  </>
);

export default SorryMessage;
