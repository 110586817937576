import { useEffect, useReducer } from "react";

import tracking from "../configs/tracking";
import { Answers } from "../types/answers";
import { mergeIgnoreUndefined, safeGetJSONFromLocalStorage, saveJSONLocalStorage } from "../utils/safelocalstorage";

const LOCAL_STORAGE_KEY = "fast_quote_stack";

type Action<Derived> = {
    type: "answer",
    payload: Partial<Answers>
} | {
    type: "back"
} | {
    type: "clearAll"
} | {
    type: "flow:done",
    payload: Derived
} | {
    type: "flow:err",
    payload: Error
}

type State<Derived> = {
    squashed: boolean,
    lastActionWasBack: boolean,

    currentAnswers: Partial<Answers>
    changesStack: Partial<Answers>[],
    draft: Partial<Answers>,

    derivedState: Derived,
    loading: boolean,
    error: Error,
}

const mergeAll = <X extends {}>(arr: X[]): X => arr.reduce((acc, change) => Object.assign(acc, change), {} as X)
const setStack = (changesStack: Partial<Answers>[]) => ({
    changesStack: changesStack,
    currentAnswers: mergeAll(changesStack)
})

const useStore = <Derived extends {}>(flow: (_: Partial<Answers>) => Promise<Derived>)
    : {
        draft: Partial<Answers>,
        answer: (_: Partial<Answers>) => void,
        back: undefined | (() => void),
        flow: Derived & { loading: boolean, error: Error },
        answers: Partial<Answers>,
        clearAll: () => void,
        squashed: true | undefined
    } => {
    const [state, dispatch] = useReducer(
        (state: State<Derived>, action: Action<Derived>): State<Derived> => {
            switch (action.type) {
                case "answer":
                    const changes = action.payload
                    tracking.answer(changes)
                    return {
                        ...state,
                        ...setStack(state.changesStack.concat(changes)),
                        loading: true,
                        draft: mergeIgnoreUndefined(state.draft, changes),
                        squashed: false,
                        lastActionWasBack: false,
                    }
                case "back":
                    const revertedChanges = state.changesStack.length > 0 ? state.changesStack[state.changesStack.length - 1] : {};
                    tracking.back(revertedChanges)
                    return {
                        ...state,
                        ...setStack(state.changesStack.slice(0, -1)),
                        loading: true,
                        lastActionWasBack: true,
                    }
                case "clearAll":
                    return {
                        ...state,
                        ...setStack([]),
                        loading: true,
                        draft: {},
                        squashed: false,
                        lastActionWasBack: false
                    }
                case "flow:err":
                    return {
                        ...state,
                        loading: false,
                        error: action.payload,
                        derivedState: {} as Derived
                    }
                case "flow:done":
                    // @ts-ignore
                    if (action.payload && action.payload.which === 'restitution') {
                        // squash changes after the fast_quote
                        return {
                            squashed: true,
                            lastActionWasBack: false,
                            draft: state.draft,
                            currentAnswers: state.currentAnswers,
                            changesStack: [state.currentAnswers],
                            derivedState: action.payload,
                            loading: false,
                            error: null,
                        }
                    } else {
                        return {
                            ...state,
                            loading: false,
                            derivedState: action.payload
                        }
                    }
            }
        },
        null,
        () => {
            const stored = safeGetJSONFromLocalStorage<State<Derived>>(LOCAL_STORAGE_KEY, {
                squashed: true,
                lastActionWasBack: false,
                changesStack: [],
                currentAnswers: {},
                draft: {},
                derivedState: {} as Derived,
                loading: true,
                error: null,
            })

            return {
                ...stored,
                ...setStack(stored.changesStack),
                squashed: true,
                lastActionWasBack: false,
                derivedState: {} as Derived,
                loading: true,
                error: null,
            }
        }
    );

    // runs the flow when state change
    useEffect(() => {
        let cancelled = false;
        (async () => {
            try {
                const derived = await flow(state.currentAnswers)
                if (!cancelled) dispatch({ type: "flow:done", payload: derived })
            } catch (err) {
                if (!cancelled) dispatch({ type: "flow:err", payload: err })
            }
        })()
        return () => cancelled = true
    }, [state.currentAnswers]);

    // store the stack every time it changes
    useEffect(() => {
        saveJSONLocalStorage(LOCAL_STORAGE_KEY, {
            changesStack: state.changesStack,
            draft: state.draft,
        });
    }, [state.changesStack, state.draft]);


    return {
        draft: state.draft,
        answer: (changes: Partial<Answers>) => {
            dispatch({ type: "answer", payload: changes });
        },
        back: (state.squashed || state.changesStack.length === 0) ? undefined :
            () => dispatch({ type: "back" }),
        flow: {
            ...state.derivedState,
            loading: state.loading,
            error: state.error
        },
        clearAll: () => dispatch({ type: "clearAll" }),
        answers: state.currentAnswers,
        squashed: state.squashed ? undefined : true,
    };
}

export default useStore;
