import React, { ComponentProps, ReactNode, ChangeEvent, ReactElement } from 'react';
import RadioButtonGroup from '../RadioButtonGroup';

const handleChange = (
  value: Choice['value'],
  event: ChangeEvent<HTMLInputElement>,
  onChange: InputRadioGroupOnChange,
  onBlur: () => void,
  onFocus: () => void,
): void => {
  if (onFocus) {
    onFocus();
  }
  onChange(value, event);
  if (onBlur) {
    onBlur();
  }
};

interface Choice<Value extends string = string> {
  value: string;
  label: NonNullable<ReactNode>;
  selected?: boolean;
}

type InputRadioGroupOnChange = (value: Choice['value'], event: ChangeEvent<HTMLInputElement>) => void;

export interface InputRadioGroupProps<Value extends string>
  extends Omit<ComponentProps<'input'>, 'onChange' | 'onClick'> {
  choices: Choice<Value>[];
  id?: string;
  name: string;
  stretch?: boolean;
  value?: string;
  onChange: InputRadioGroupOnChange;
  onBlur: () => void;
  onFocus: () => void;
}

export default function InputRadioGroup<Value extends string>({
  choices,
  id,
  name,
  stretch,
  value,
  onChange,
  onBlur,
  onFocus,
  ...props
}: InputRadioGroupProps<Value>): ReactElement {
  return (
    <RadioButtonGroup stretch={stretch}>
      {choices.map((choice) => (
        <RadioButtonGroup.RadioButton
          {...props}
          key={choice.value}
          checked={value === choice.value}
          value={choice.value}
          id={`${id}-${choice.value}`}
          name={name}
          onChange={(e): void => handleChange(choice.value, e, onChange, onBlur, onFocus)}
        >
          {choice.label}
        </RadioButtonGroup.RadioButton>
      ))}
    </RadioButtonGroup>
  );
}
