import React, { useCallback } from "react";

import { SvgButton } from "./button";
import texts from "../../configs/texts";
import Question from "./wrapper";

import SvgQLicenseYes from "../../assets/Q_license_yes.svg";
import SvgQLicenseNo from "../../assets/Q_license_no.svg";

const Qlicense = ({ answer, ...props }) => {
  const onYesClick = useCallback(() => answer({ license: true }), [answer]);
  const onNoClick = useCallback(() => answer({ license: false }), [answer]);

  return (
    <Question {...props} title={texts.license.question} id="Qlicense">
      <SvgButton id="btn-yes-license" onClick={onYesClick} Svg={SvgQLicenseYes}>
        {texts.commons.yes}
      </SvgButton>
      <SvgButton id="btn-no-license" onClick={onNoClick} Svg={SvgQLicenseNo}>
        {texts.commons.no}
      </SvgButton>
    </Question>
  );
};

export default Qlicense;
