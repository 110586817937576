import React, { ReactNode } from 'react';
import styles from './styles.module.css';

export interface EllipsedHeaderProps {
  children: NonNullable<ReactNode>;
}

export default function EllipsedHeader({ children }: EllipsedHeaderProps) {
  return <div className={styles.EllipsedHeader}>{children}</div>;
}
