import React, { useState } from "react";

import useInterval from "../../hooks/useInterval";

const waitCycles = 15;

const SwitchingWords = ({ words }) => {
  const [wordIdx, setWordIdx] = useState(0);
  const [letterIdx, setLetterIdx] = useState(1);
  const currentWord = words[wordIdx];

  useInterval(() => {
    if (waitCycles + currentWord.length === letterIdx) {
      setLetterIdx(1);
      setWordIdx((wordIdx + 1) % words.length);
    } else {
      setLetterIdx(letterIdx + 1);
    }
  }, 50);

  return (
    <span className="avoidwrap">
      <strong>{currentWord.substr(0, letterIdx)}</strong>
    </span>
  );
};

export default SwitchingWords;
