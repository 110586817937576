import cx from 'classnames';
import React, { EventHandler, MouseEventHandler, SyntheticEvent, ReactElement } from 'react';
import styles from './styles.module.css';

const handleWheelOrTouchMove: EventHandler<SyntheticEvent> = (e) => {
  // prevent scrolling body when modal is opened. specific to safari.
  e.preventDefault();
};

export interface OverlayProps {
  className?: string;
  onClick?: MouseEventHandler;
}

export default function Overlay({ className, onClick }: OverlayProps): ReactElement {
  return (
    <div
      aria-hidden
      className={cx(styles.Overlay, className)}
      onClick={onClick}
      onWheel={handleWheelOrTouchMove}
      onTouchMove={handleWheelOrTouchMove}
    />
  );
}
