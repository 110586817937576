import React, { ComponentProps, ReactElement } from 'react';
import styles from './styles.module.css';

export interface LegendProps extends Omit<ComponentProps<'figcaption'>, 'className'> {}

export default function Legend({ children, ...props }: LegendProps): ReactElement {
  return (
    <figcaption className={styles.Legend} {...props}>
      {children}
    </figcaption>
  );
}
