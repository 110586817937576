import React from "react";
import { Button } from "@ornikar/kitt";
import texts from "../../configs/texts";

const ValidateButton = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        type="primary"
        large
        style={{ alignSelf: "center", marginTop: "1em" }}
        {...props}
      >
        {texts.commons.validate}
      </Button>
    </div>
  );
};

export default ValidateButton;
