/* eslint-disable max-lines */
import cx from 'classnames';
import React, { Component, createRef, CSSProperties, ReactNode } from 'react';
import debounce from 'lodash.debounce';
import UnloadedMap, { UnloadedMapProps } from 'components/Map/UnloadedMap';
import MagnifyButton from 'components/Map/MagnifyButton';
import { MapCenter } from 'components/Map/types';
import MapMarker, { MapMarkerProps } from './MapMarker';
import MapPicture, { MapPictureProps } from './MapPicture';
import styles from './styles.module.css';

interface MapState {
  loaded?: boolean;
  pWidth: number;
  pHeight: number;
  hasError: boolean;
  showImage?: boolean;
  width?: number;
  height: number;
  center?: MapCenter;
  marker?: MapMarkerProps['size'];
}

export interface MapProps {
  width?: number;
  height: number;
  alt?: string;
  center?: MapCenter;
  marker?: MapMarkerProps['size'];
  markerType?: MapMarkerProps['type'];
  standalone?: boolean;
  rounded?: boolean;
  mapStyle?: CSSProperties;
  placeholder?: ReactNode;
  onMagnifyMapClick?: MapPictureProps['onMagnifyMapClick'];
}

type MapStateShouldShowMap = Required<Pick<MapState, 'showImage' | 'width' | 'center'>>;

/**
 * The map is shown when the picture has a size,
 * a center and when the UI is ready
 */
function shouldShowMap<T extends MapState>(state: T): T extends MapStateShouldShowMap ? true : false {
  return Boolean(state.showImage && state.width && state.center) as any;
}

export default class Map extends Component<MapProps, MapState> {
  static defaultProps = {
    alt: 'Map',
  };

  static MagnifyButton = MagnifyButton;

  mapRef = createRef<HTMLDivElement>();

  // Max width allowed by mapbox api
  maxWidth = 1280;

  constructor(props: MapProps) {
    super(props);
    const { w, h } = this.getPlaceholderSize();

    this.state = {
      loaded: undefined,
      pWidth: w,
      pHeight: h,
      hasError: false,
      showImage: true,
      width: props.width,
      height: props.height,
      center: props.center,
    };
  }

  static getDerivedStateFromProps(props: MapProps, state: MapState) {
    const newState: Partial<MapState> = {};
    if (props.height !== state.height) {
      newState.height = props.height;
    }

    // Show the loading state when the center changes
    if (
      props.center &&
      (!state.center ||
        props.center.zoom !== state.center.zoom ||
        props.center.long !== state.center.long ||
        props.center.lat !== state.center.lat)
    ) {
      newState.loaded = false;
      newState.center = props.center;
    } else if (state.center && !props.center) {
      newState.loaded = false;
      newState.center = props.center;
    }

    if (props.width != null && !state.width) {
      newState.loaded = true;
    }

    return Object.keys(newState).length !== 0 ? newState : null;
  }

  componentDidMount() {
    // When the width props is missing,
    // the Map takes the same as its parent node
    if (!this.props.width) {
      window.addEventListener('resize', this.handleResize);

      // In this case this.props.width is null,
      // so we need to provide an intial value to this.state.width
      this.setMapInitialWidth();
    }
  }

  componentDidUpdate(prevProps: MapProps, prevState: MapState) {
    if (this.props.width == null && prevProps.width) {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    }

    if (this.props.width !== null && !prevProps.width) {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  reloadMap: UnloadedMapProps['onReload'] = (e) => {
    e.stopPropagation();
    this.setState({
      hasError: false,
      showImage: true,
    });
  };

  handleResize = debounce(() => {
    const parentWidth = this.getParentWidth();
    if (this.state.width !== parentWidth && parentWidth < this.maxWidth) {
      const { w, h } = this.getPlaceholderSize(parentWidth, this.state.height);

      // Show loading UI, update placeholder and image size
      this.setState({
        loaded: false,
        pWidth: w,
        pHeight: h,
        width: parentWidth,
      });
    }
  }, 200);

  handleLoad = () => {
    this.setState({
      loaded: true,
      hasError: false,
    });
  };

  handleError = () => {
    this.setState({
      hasError: true,
      showImage: false,
    });
  };

  getParentWidth() {
    return Math.min(
      ((this.mapRef.current as HTMLDivElement).parentNode as HTMLElement).getBoundingClientRect().width,
      this.maxWidth,
    );
  }

  /**
   * elemWidth is based on the width props, if provided
   *
   * @param {*} elemWidth
   * @param {*} elemHeight
   */
  getPlaceholderSize(elemWidth: number = this.props.width || this.maxWidth, elemHeight: number = this.props.height) {
    const placeholderW = 500;
    const placeholderH = 300;

    if (elemWidth > elemHeight) {
      return {
        w: elemWidth,
        h: Math.max((elemWidth * placeholderH) / placeholderW, elemHeight),
      };
    }
    return {
      w: (elemHeight * placeholderW) / placeholderH,
      h: elemHeight,
    };
  }

  setMapInitialWidth() {
    this.setState({
      width: this.getParentWidth(),
    });
  }

  render() {
    const {
      alt,
      marker: markerSize,
      markerType,
      standalone,
      rounded,
      mapStyle,
      placeholder,
      onMagnifyMapClick,
    } = this.props;
    return (
      <div
        ref={this.mapRef}
        className={cx(styles.Map, {
          [styles.Standalone]: standalone,
          [styles.Rounded]: standalone || rounded,
        })}
        style={{
          width: this.state.width,
          height: this.state.height,
          ...mapStyle,
        }}
      >
        {shouldShowMap(this.state) && (
          <MapPicture
            width={this.state.width as number}
            height={this.state.height}
            center={this.state.center as NonNullable<MapState['center']>}
            alt={alt}
            onLoad={this.handleLoad}
            onError={this.handleError}
            onMagnifyMapClick={onMagnifyMapClick}
          />
        )}
        {markerSize && <MapMarker type={markerType} size={markerSize} loaded={this.state.loaded} />}
        <UnloadedMap
          visible={!this.state.loaded}
          placeholder={placeholder}
          placeholderStyle={{
            width: this.state.pWidth,
            height: this.state.pHeight,
          }}
          hasError={this.state.hasError}
          hasCenter={!!this.state.center}
          onReload={this.reloadMap}
        />
      </div>
    );
  }
}
