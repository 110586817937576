import cx from 'classnames';
import React, { Component, ReactNode, ReactElement } from 'react';
import { Portal } from 'react-portal';
import { ESCAPE } from '../../utils/keyCodes';
import Typography from '../Typography';
import styles from './styles.module.css';

declare global {
  interface Document {
    scrollTop: number;
    clientTop: number;
  }
}

export type OnEnter = () => void;
export type OnExit = () => void;
export type OnEntered = () => void;
export type OnExited = () => void;
export type OnClose = () => void;

export interface WrapperProps {
  visible?: boolean;
  iosBodyFreeze?: boolean;
  children: (childrenRenderProps: {
    onEnter: OnEnter;
    onExit: OnExit;
    onEntered: OnEntered;
    onExited: OnExited;
  }) => ReactNode;
  onEntered?: OnEntered;
  onExited?: OnExited;
  onClose: OnClose;
}
export interface WrapperState {
  animationInProgress: boolean;
}

export default class Wrapper extends Component<WrapperProps, WrapperState> {
  state = {
    animationInProgress: Boolean(this.props.visible),
  };

  isIOS?: boolean;

  scrollTop = 0;

  componentDidMount() {
    this.isIOS =
      typeof navigator !== 'undefined' &&
      navigator.userAgent.includes('AppleWebKit/') &&
      (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad'));

    if (this.props.visible) {
      this.toggleModal(this.props.visible);
    }
  }

  componentDidUpdate(prevProps: WrapperProps) {
    if (this.props.visible !== prevProps.visible) {
      this.toggleModal(Boolean(this.props.visible));
    }
  }

  componentWillUnmount() {
    this.detachKeyEvent();
    window.document.body.classList.remove('modal-open');
  }

  handleOnEnter: OnEnter = () => {
    this.setState({
      animationInProgress: true,
    });
  };

  handleOnEntered = () => {
    // On iOS, force body to position fixed and apply current scroll offset
    // as body top. Will be reverted on exit
    if (this.props.iosBodyFreeze && this.isIOS) {
      const top = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);
      this.scrollTop = top;
      document.body.style.top = `-${top}px`;
      document.body.classList.add('ios-body-freeze');
    }

    return this.props.onEntered && this.props.onEntered();
  };

  handleOnExit = () => {
    if (this.props.iosBodyFreeze && this.isIOS) {
      document.body.style.top = '';
      document.body.classList.remove('ios-body-freeze');
      window.requestAnimationFrame(() => window.scrollTo(0, this.scrollTop));
    }
  };

  handleOnExited = () => {
    this.setState({
      animationInProgress: false,
    });

    return this.props.onExited && this.props.onExited();
  };

  closeHelper = (event: KeyboardEvent) => {
    if (event.keyCode === ESCAPE) {
      this.props.onClose();
    }
  };

  toggleModal(visible: boolean) {
    if (visible) {
      this.attachKeyEvent();
      window.document.body.classList.add('modal-open');
    } else {
      this.detachKeyEvent();
      window.document.body.classList.remove('modal-open');
    }
  }

  attachKeyEvent() {
    window.addEventListener('keydown', this.closeHelper);
  }

  detachKeyEvent() {
    window.removeEventListener('keydown', this.closeHelper);
  }

  render(): ReactElement {
    return (
      <Portal>
        <Typography.div
          base="body"
          className={cx(styles.FixedContainer, {
            [styles.Hidden]: !this.state.animationInProgress,
          })}
        >
          {this.props.children({
            onEnter: this.handleOnEnter,
            onExit: this.handleOnExit,
            onEntered: this.handleOnEntered,
            onExited: this.handleOnExited,
          })}
        </Typography.div>
      </Portal>
    );
  }
}
