import { useState, useEffect, useRef, useCallback } from "react";

export default ([email, phone]) => {
  const mountedRef = useRef(false);
  const [state, setState] = useState({
    result: null,
    error: null,
    loading: false,
  });
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const run = useCallback(() => {
    setState({ result: null, error: null, loading: true });
    const params = new URLSearchParams();
    params.append("phone", phone);
    params.append("email", email);
    return fetch("/contact/validate", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: params,
    })
      .then((res) => res.json())
      .then(
        (res) => {
          if (mountedRef.current)
            setState({ result: res, error: null, loading: false });
          return res.accept;
        },
        (err) => {
          if (mountedRef.current)
            setState({ result: null, error: err, loading: false });
        }
      );
  }, [email, phone]);

  return [run, state];
};
