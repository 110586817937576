import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { KittTransitionProps } from 'components/Transitions/propTypes';
import styles from './styles.module.css';

const classNames = {
  appear: styles.FadeInEnter,
  appearActive: styles.FadeInEnterActive,
  enter: styles.FadeInEnter,
  enterActive: styles.FadeInEnterActive,
  exit: styles.FadeInExit,
  exitActive: styles.FadeInExitActive,
};

export default function FadeIn({
  appear,
  children,
  visible,
  onEnter,
  onExit,
  onEntered,
  onExited,
}: KittTransitionProps) {
  return (
    <CSSTransition
      unmountOnExit
      appear={appear}
      classNames={classNames}
      in={visible}
      timeout={250}
      onEnter={onEnter}
      onExit={onExit}
      onEntered={onEntered}
      onExited={onExited}
    >
      {children()}
    </CSSTransition>
  );
}
