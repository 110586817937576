import cx from 'classnames';
import React, { PureComponent, Children, ReactNode, MouseEvent } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './styles.module.css';

const getClassNames = (top: boolean) => ({
  enter: top ? styles.PopoverEnter : styles.PopoverEnterFromTop,
  enterActive: styles.PopoverEnterActive,
  exit: styles.PopoverExit,
  exitActive: styles.PopoverExitActive,
});

type PopoverPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export interface PopoverProps<Values> {
  children: NonNullable<ReactNode>;
  large?: boolean;
  visible?: boolean;
  className?: string;
  currentValue?: Values;
  position: PopoverPosition;
  onSelectItem?: (value: Values, e: MouseEvent) => void;
}

export default class Popover<Values> extends PureComponent<PopoverProps<Values>> {
  render() {
    const { position, large, onSelectItem, currentValue, visible, className } = this.props;
    return (
      <CSSTransition unmountOnExit timeout={250} classNames={getClassNames(position.startsWith('top'))} in={visible}>
        <div
          className={cx(
            styles.Popover,
            {
              [styles.PopoverTop]: position.startsWith('top'),
              [styles.PopoverBottom]: position.startsWith('bottom'),
              [styles.PopoverLeft]: position.endsWith('left'),
              [styles.PopoverRight]: position.endsWith('right'),
              [styles.Large]: large,
            },
            !!className && className,
          )}
        >
          <div>
            {Children.map(this.props.children, (child) =>
              React.cloneElement(child as any, {
                onClick: onSelectItem && ((e: MouseEvent) => onSelectItem((child as any).props.value, e)),
                active: !!currentValue && currentValue === (child as any).props.value ? true : undefined,
              }),
            )}
          </div>
        </div>
      </CSSTransition>
    );
  }
}
