import React, { CSSProperties, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import LoadingIcon from 'components/Map/LoadingIcon';
import PlaceHolder from 'components/Map/PlaceHolder';
import LoadingError, { LoadingErrorProps } from '../LoadingError';
import styles from './styles.module.css';

const classNames = {
  enter: styles.UnloadedMapEnter,
  enterActive: styles.UnloadedMapEnterActive,
  exit: styles.UnloadedMapExit,
  exitActive: styles.UnloadedMapExitActive,
  enterDone: styles.RemoveAfterTweak,
  appearDone: styles.RemoveAfterTweak,
};

export interface UnloadedMapProps {
  visible?: boolean;
  hasCenter?: boolean;
  hasError?: boolean;
  placeholder?: ReactNode;
  placeholderStyle?: Pick<CSSProperties, 'width' | 'height'>;
  onReload: LoadingErrorProps['onReload'];
}

export default function UnloadedMap({
  visible,
  hasCenter,
  hasError,
  placeholder,
  placeholderStyle,
  onReload,
}: UnloadedMapProps) {
  return (
    <CSSTransition unmountOnExit in={visible} classNames={classNames} timeout={1000}>
      {() => (
        <div className={styles.UnloadedMap}>
          <PlaceHolder placeholder={placeholder} style={placeholderStyle} />
          {hasError ? <LoadingError onReload={onReload} /> : hasCenter && <LoadingIcon />}
        </div>
      )}
    </CSSTransition>
  );
}
