import cx from 'classnames';
import React, { ReactNode } from 'react';
import styles from './styles.module.css';

interface ItemProps {
  active?: boolean;
  children: string;
}

function Item({ active, children }: ItemProps) {
  return (
    <li
      className={cx(styles.Item, {
        [styles.ActiveItem]: active,
      })}
    >
      <span>{children}</span>
    </li>
  );
}

export interface StepsProps {
  vertical?: boolean;
  dots?: boolean;
  children: NonNullable<ReactNode>;
}

export default function Steps({ vertical, dots, children }: StepsProps) {
  return (
    <ul
      className={cx(styles.Items, {
        [styles.VerticalItems]: vertical,
        [styles.Dotted]: dots,
      })}
    >
      {children}
    </ul>
  );
}

Steps.Item = Item;
