const safeGetJSONFromLocalStorage = <T>(key: string, defaultValue: T): T => {
    const json = localStorage.getItem(key);
    let value = defaultValue;
    if (json) {
        try {
            value = JSON.parse(json, preserveUndefinedReviver);
            // @ts-ignore
            if (value.length) throw new Error('old version')
        } catch (err) {
            localStorage.removeItem(key);
        }
    }
    return value;
};

const saveJSONLocalStorage = <T>(key: string, state: T) => {
    localStorage.setItem(key, JSON.stringify(state, preserveUndefinedReplacer));
}

const preserveUndefinedReplacer = (key: string, value: any) =>
    typeof value === 'undefined' ? null : value;
const preserveUndefinedReviver = (key: string, value: any) => {
    if (typeof value === 'object' && value !== null)
        Object.keys(value).forEach((k) => {
            if (value[k] === null) value[k] = undefined;
        })
    return value
}
const mergeIgnoreUndefined = (target: object, source: object) => {
    const copy = { ...target }
    Object.keys(source).forEach((k) => { if (source[k] !== undefined) copy[k] = source[k] })
    return copy
}

export {
    safeGetJSONFromLocalStorage,
    preserveUndefinedReplacer,
    preserveUndefinedReviver,
    mergeIgnoreUndefined,
    saveJSONLocalStorage
}
