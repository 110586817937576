import React from 'react';
import Icon from 'components/Icon';
import styles from './styles.module.css';

export default function LoadingIcon() {
  return (
    <div className={styles.LoaderIcon}>
      <Icon name="loading" />
    </div>
  );
}
