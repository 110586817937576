import React from "react";
import LogoBlack from "../../assets/ornikarassurance-primary.svg";
import texts from "../../configs/texts";

const RestitutionContainer = ({ style, children, className = "" }) => {
  return (
    <div
      id="top"
      style={style}
      className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-body__2VaSM  styles-types-module_medium-body__hjzlF  "
    >
      <div className="styles_Pattern__1UaTi">
        <div className="styles_Page__Y4-j3">
          <div className={className + " styles_PageContainer__1H8Ki"}>
            {children}
          </div>
          <div className="show-small styles_Reassurance__1Ddk6 styles_Reassurance__2yZjY">
            <div className="styles_Logos__hqaJm">
              <div className="styles_Illustration__3BiV_">
                <LogoBlack height="2em" />
              </div>
            </div>
            <div className="styles_Text__3Sn1V">
              <p className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-body-small__25Mh1  styles-types-module_medium-body-small__gWkir  ">
                {texts.legal}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestitutionContainer;
