import cx from 'classnames';
import React, { ChangeEventHandler, ReactNode, useState } from 'react';
import RadioBehaviour from '../../utils/RadioBehaviour';
import styles from './styles.module.css';

export interface RadioButtonProps {
  children: NonNullable<ReactNode>;
  id: string;
  name: string;
  value?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /** @deprecated Use onChange now */
  onClick?: never;
}

function RadioButton({ checked, children, onChange, onClick, value, name, id }: RadioButtonProps) {
  const [hasFocus, setFocus] = useState(false);

  return (
    <RadioBehaviour
      value={value}
      name={name}
      id={id}
      checked={checked}
      className={cx(styles.RadioButtonContainer, {
        [styles.Checked]: !!checked,
        [styles.WithFocus]: hasFocus,
      })}
      onChange={onChange || onClick}
      onFocus={() => {
        setFocus(true);
      }}
      onBlur={() => {
        setFocus(false);
      }}
    >
      <div className={styles.RadioButton}>{children}</div>
    </RadioBehaviour>
  );
}

export interface RadioButtonGroupProps {
  stretch?: boolean;
  children: NonNullable<ReactNode>;
}

export default function RadioButtonGroup({ stretch, children }: RadioButtonGroupProps) {
  return <div className={cx({ [styles.Stretch]: stretch })}>{children}</div>;
}

RadioButtonGroup.RadioButton = RadioButton;
