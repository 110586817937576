/* eslint-disable max-lines */
import React, { ChangeEventHandler, Component, FocusEventHandler, RefObject } from 'react';
import InputText from 'components/InputText';
import styles from './styles.module.css';

/**
 * Focus a field & select its value if it has any
 */
const jumpAndSelectField = (inputRef: RefObject<HTMLInputElement>, inputFieldVal: string) => {
  if (!inputRef.current) return;
  inputRef.current.focus();
  if (inputFieldVal && inputFieldVal.length !== 0) {
    inputRef.current.select();
  }
};

const toCharNumbers = (nbCharacters: number) => (numberValue: string): string => {
  let str = '';
  while (str.length < nbCharacters) {
    str += '0';
  }

  return (str + numberValue).slice(-1 * nbCharacters, nbCharacters);
};

const datePartNormalizers = {
  year: (year: string) => year,
  month: toCharNumbers(2),
  day: toCharNumbers(2),
};

const calcPlaceholderFromProps = (placeholder?: string) => {
  const defaultPlaceholder = {
    day: '10',
    month: '10',
    year: '1972',
  };
  if (!placeholder) return defaultPlaceholder;

  const date = placeholder.split('-');

  if (date.length !== 3) return defaultPlaceholder;

  return {
    day: date[2],
    month: date[1],
    year: date[0],
  };
};

// eslint-disable-next-line unicorn/no-unsafe-regex
const regexpDate = /^(\d*)?-(\d*)?-(\d*)?$/;

export interface InputDateProps {
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  id?: string;
  onChange: (date: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

/**
 * InputDate component
 *
 * It's composed of three inputs that can't exceed their max size
 * It reports is value to the containing element whenever all three values
 * are set, without checking for their correctness
 */
export default class InputDate extends Component<InputDateProps> {
  static defaultProps = {
    value: '',
  };

  placeholder = calcPlaceholderFromProps(this.props.placeholder);

  monthInputRef = React.createRef<HTMLInputElement>();

  yearInputRef = React.createRef<HTMLInputElement>();

  shouldIgnoreBlur = false;

  createHandlerInputBlur(
    field: 'year' | 'month' | 'day',
    cb?: (value: string) => void,
  ): undefined | FocusEventHandler<HTMLInputElement> {
    if (!cb) return undefined;

    return (e) => {
      if (this.shouldIgnoreBlur) {
        this.shouldIgnoreBlur = false;

        return null;
      }

      const { value } = e.target;
      const [, year = '', month = '', day = ''] = regexpDate.exec(this.props.value as string) || [];

      if (!year && !month && !day) {
        return cb('');
      }

      const newDate = {
        year: year && datePartNormalizers.year(year.slice(0, 4)),
        month: month && datePartNormalizers.month(month.slice(0, 2)),
        day: day && datePartNormalizers.day(day.slice(0, 2)),
        [field]: value && datePartNormalizers[field](value),
      };

      const date = `${newDate.year}-${newDate.month}-${newDate.day}`;
      return cb(date);
    };
  }

  createHandlerInputChange(
    field: 'year' | 'month' | 'day',
    cb?: (value: string) => void,
  ): undefined | ChangeEventHandler<HTMLInputElement> {
    if (!cb) return undefined;
    return (e) => {
      const { value } = e.target;
      const [, year = '', month = '', day = ''] = regexpDate.exec(this.props.value as string) || [];

      const newDate = {
        year,
        month,
        day,
        [field]: value,
      };

      const date = `${newDate.year.slice(0, 4)}-${newDate.month.slice(0, 2)}-${newDate.day.slice(0, 2)}`;

      // If the date is empty, we return an empty string rather than "--"
      if (date === '--') {
        return cb('');
      }

      // Jump to next field
      if (value && value.length === 2) {
        if (field === 'day') {
          this.ignoreNextBlur();
          jumpAndSelectField(this.monthInputRef, month);
        } else if (field === 'month') {
          this.ignoreNextBlur();
          jumpAndSelectField(this.yearInputRef, year);
        }
      }

      return cb(date);
    };
  }

  ignoreNextBlur() {
    this.shouldIgnoreBlur = true;
  }

  render() {
    const [, year = '', month = '', day = ''] = (this.props.value && regexpDate.exec(this.props.value)) || [];
    return (
      <div>
        <div className={styles.Wrapper}>
          <div className={styles.DayAndMonth}>
            <InputText
              type="number"
              placeholder={this.placeholder.day}
              min="1"
              max="31"
              value={day}
              autoFocus={this.props.autoFocus}
              disabled={this.props.disabled}
              id={this.props.id && `${this.props.id}-day`}
              onChange={this.createHandlerInputChange('day', this.props.onChange)}
              onBlur={this.createHandlerInputBlur('day', this.props.onBlur)}
              onFocus={this.props.onFocus}
            />
          </div>
          <div className={styles.DayAndMonth}>
            <InputText
              ref={this.monthInputRef}
              disabled={this.props.disabled}
              type="number"
              placeholder={this.placeholder.month}
              maxLength={2}
              min="1"
              max="12"
              value={month}
              id={this.props.id && `${this.props.id}-month`}
              onChange={this.createHandlerInputChange('month', this.props.onChange)}
              onBlur={this.createHandlerInputBlur('month', this.props.onBlur)}
              onFocus={this.props.onFocus}
            />
          </div>
          <div className={styles.Year}>
            <InputText
              ref={this.yearInputRef}
              disabled={this.props.disabled}
              type="number"
              placeholder={this.placeholder.year}
              maxLength={4}
              min="1900"
              max="2020"
              value={year}
              id={this.props.id && `${this.props.id}-year`}
              onChange={this.createHandlerInputChange('year', this.props.onChange)}
              onBlur={this.createHandlerInputBlur('year', this.props.onBlur)}
              onFocus={this.props.onFocus}
            />
          </div>
        </div>
      </div>
    );
  }
}
