import React, { useState, useMemo, useCallback } from "react";

import texts from "../../configs/texts";

import Button from "./button";
import Question from "./wrapper";
import { InputAutocomplete } from "@ornikar/kitt";

import allianzLogo from "../../assets/assus/allianz.jpg";
import axaLogo from "../../assets/assus/axa.jpg";
import caLogo from "../../assets/assus/ca.jpg";
import directLogo from "../../assets/assus/direct.jpg";
import gmfLogo from "../../assets/assus/gmf.jpg";
import macifLogo from "../../assets/assus/macif.jpg";
import matmutLogo from "../../assets/assus/matmut.jpg";
import olivierLogo from "../../assets/assus/olivier.jpg";
import bpLogo from "../../assets/assus/bp.jpg";
import sollyLogo from "../../assets/assus/solly.jpg";

const logos = {
  Matmut: matmutLogo,
  Macif: macifLogo,
  AXA: axaLogo,
  GMF: gmfLogo,
  Allianz: allianzLogo,
  "L’olivier": olivierLogo,
  "La Banque Postale": bpLogo,
  "Direct Assurance (Avanssur)": directLogo,
  "Crédit Agricole (Pacifica)": caLogo,
  "Solly Azar": sollyLogo,
};

const assus = [
  "Allianz",
  "Allsecur (Calypso)",
  "Amaguiz (Amaline)",
  "Aviva Assurances",
  "AXA",
  "Banque Populaire",
  "Caisse d’Épargne",
  "Crédit Agricole (Pacifica)",
  "Crédit Mutuel",
  "Direct Assurance (Avanssur)",
  "Eurofil (Aviva)",
  "Gan",
  "Generali",
  "La Banque Postale",
  "Société Générale",
  "Solly Azar",
  "GMF",
  "Groupama",
  "MAAF",
  "Macif",
  "MAIF",
  "Matmut",
  "MFA (Mutuelle Fraternelle d’Assurances)",
  "MMA",
  "Mutuelle de Poitiers",
  "Thélem Assurances",
  "AON",
  "April",
  "Assuronline (AssurOne)",
  "Euro Assurance",
  "L’olivier",
];

const QcurrentAssu = ({ answer, ...props }) => {
  const [currentAssuSearch, setcurrentAssuSearch] = useState("");
  const results = useMemo(
    () =>
      assus.filter(
        (b) => b.toLowerCase().indexOf(currentAssuSearch.toLowerCase()) >= 0
      ) || [],
    [assus, currentAssuSearch]
  );

  const skip = useCallback(() => answer({ currentAssu: null }));

  return (
    <Question
      {...props}
      title={texts.currentAssu.question}
      id="QcurrentAssu"
      style={{ minHeight: "none" }}
    >
      <InputAutocomplete
        value={currentAssuSearch}
        onInputValueChange={(v) => setcurrentAssuSearch(v)}
        onChange={(v) => {
          answer({ currentAssu: v });
        }}
        inputProps={{ placeholder: texts.currentAssu.placeholder }}
        emptyComponent={() => (
          <InputAutocomplete.Item>
            {texts.currentAssu.emptyLabel}
          </InputAutocomplete.Item>
        )}
        isOpen={results.length < 10}
      >
        {results.map((b) => (
          <InputAutocomplete.Item item={b} key={b}>
            {b}
          </InputAutocomplete.Item>
        ))}
      </InputAutocomplete>
      <div className="buttonsContainer">
        {Object.keys(logos).map((currentAssu, i) => (
          <Button
            key={currentAssu}
            stretch
            className={
              "brandButton " +
              (i > 7 ? "dropTwo " : "") +
              (i > 8 ? "dropOne " : "")
            }
            type="accent"
            onClick={() => answer({ currentAssu: currentAssu })}
          >
            <img
              className="buttonImg"
              src={logos[currentAssu]}
              alt={currentAssu}
            />
          </Button>
        ))}
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          stretch
          type="subtle-dark"
          id="btn-skip-subscription"
          style={{ textDecoration: "underline" }}
          onClick={skip}
        >
          {texts.current.unknown}
        </Button>
      </div>
    </Question>
  );
};
export default QcurrentAssu;
