import React from "react";

import Svg7e226bae04d0ee50f0e02d702fa87908 from "../../assets/7e226bae04d0ee50f0e02d702fa87908.svg";
import Svgb82792addabdc24a7a64189cdc816111 from "../../assets/b82792addabdc24a7a64189cdc816111.svg";
import Svg6ab860987331a6b092f77031eeffd652 from "../../assets/6ab860987331a6b092f77031eeffd652.svg";
import texts from "../../configs/texts";

const ThreeSquares = () => (
  <div className="Container-2eEOBYzvvYJeSH8Zo3CWn0 BlockContainer-2_S0gSBQnVmsZ1NwIHXBGR">
    <div className="TitleContainer-18z1tIOxM3nbm7f7xSYzKP BlockContainer-2_S0gSBQnVmsZ1NwIHXBGR">
      <h2
        className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header3__FKPGi  styles-types-module_medium-header3__3ZkRt  Title-2jkmyGmHlUGOdhNMWLMRmh"
      >
        {texts.landing.enfin}
      </h2>
    </div>
    <div className="CardsContainer-1xC44xZk0fjM73gMyZlTeK">
      <div className="Container-1UqTp-95VKx05XHwXfwFTr Card-3gs_c7Wcs7AIzWc_DpUEkK PermisBContainer-29vyWeMNPDDN2L6Ld-_3BK Orange-2IFrLMhuSFjRuD1DyEvtK4">
        <div className="PatternContainer-33uUR6xEhxN6P_6slSRlSK">
          <div className="Pattern-1quShsxvCruP5wl3_ckk4x Transparent-CLhlWFmNKZnheJepwnCwV PermisBPatternPosition-3QSnrGnc9ckJ-aw6p6tvNS"></div>
        </div>
        <Svg7e226bae04d0ee50f0e02d702fa87908
          className="Illustration-2DTjAbCZmzXJXoBmdmszGt PermisBIllustration-28ZjX0fZtLU7-gEUsBl5sw"
          alt="Voiture"
        />
        <h3 className="styles-types-module_Reset__1HOMT styles-variants-module_white-regular__2T6kG styles-types-module_base-header4__142rQ  styles-types-module_medium-header5__1wb6V  Title-61oAcjKO4SqGEY98sDVmZ">
          {texts.landing.vous_payez}
          <div className="styles-types-module_Reset__1HOMT styles-variants-module_white-bold__2a5zT styles-types-module_base-header3__FKPGi  styles-types-module_medium-header4__2YZKi  ">
            {texts.landing.meilleur_prix}
          </div>
        </h3>
      </div>
      <div className="Container-1UqTp-95VKx05XHwXfwFTr Card-3gs_c7Wcs7AIzWc_DpUEkK Blue-21NDjaXL7qy8cPLto75A9z">
        <div className="PatternContainer-33uUR6xEhxN6P_6slSRlSK">
          <div className="Pattern-1quShsxvCruP5wl3_ckk4x Transparent-CLhlWFmNKZnheJepwnCwV PermisEtrangerPatternPosition-1v-9jv-fIrVcPT2_2y7I82"></div>
        </div>
        <Svgb82792addabdc24a7a64189cdc816111
          className="Illustration-2DTjAbCZmzXJXoBmdmszGt PermisEtrangerIllustration-Vt2RaqIWL6FmF8V5TfZ8m"
          alt="Permis étranger"
        />
        <h3 className="styles-types-module_Reset__1HOMT styles-variants-module_white-regular__2T6kG styles-types-module_base-header4__142rQ  styles-types-module_medium-header5__1wb6V  Title-61oAcjKO4SqGEY98sDVmZ">
          {texts.landing.vous_pouvez}
          <div className="styles-types-module_Reset__1HOMT styles-variants-module_white-bold__2a5zT styles-types-module_base-header3__FKPGi  styles-types-module_medium-header4__2YZKi  ">
            {texts.landing.toutes_garanties}
          </div>
        </h3>
      </div>
      <div className="Container-1UqTp-95VKx05XHwXfwFTr Card-3gs_c7Wcs7AIzWc_DpUEkK Purple-uVAx1T_1IS43E2QtjoTE_">
        <div className="PatternContainer-33uUR6xEhxN6P_6slSRlSK">
          <div className="Pattern-1quShsxvCruP5wl3_ckk4x Transparent-CLhlWFmNKZnheJepwnCwV PermisAnnulePatternPosition-2-h3PYfSYcwTYxmEP1xnUW"></div>
        </div>
        <Svg6ab860987331a6b092f77031eeffd652
          className="Illustration-2DTjAbCZmzXJXoBmdmszGt PermisAnnuleIllustration-3soW3ZbpyS_sDDQ_rvAtdb"
          alt="Permis annulé ou invalidé"
        />
        <h3 className="styles-types-module_Reset__1HOMT styles-variants-module_white-regular__2T6kG styles-types-module_base-header4__142rQ  styles-types-module_medium-header5__1wb6V  Title-61oAcjKO4SqGEY98sDVmZ">
          {texts.landing.vous_etes_accompagnes}
          <div className="styles-types-module_Reset__1HOMT styles-variants-module_white-bold__2a5zT styles-types-module_base-header3__FKPGi  styles-types-module_medium-header4__2YZKi  ">
            {texts.landing.a_z}
          </div>
        </h3>
      </div>
    </div>
    <div className="ButtonContainer-2RjOxacqeydRoEvUR4YPBM BlockContainer-2_S0gSBQnVmsZ1NwIHXBGR">
      <a
        className="styles-module_Link__GVqkR    styles-module_Button__J65Ds reset-module_ResetButton__1dTnc   styles-module_Accent__3MXDT          "
        id="link-landing-cta1"
        href={texts.url.quote_form}
      >
        <div tabIndex="-1" className="styles-module_Content__oxWk7">
          <span className="styles-module_Children__2CK5r">
            {texts.landing.cta}
          </span>
        </div>
      </a>
    </div>
  </div>
);

export default ThreeSquares;
