import cx from 'classnames';
import React, { ReactNode, ReactElement } from 'react';
import Typography from '../Typography';
import styles from './styles.module.css';

export interface HeaderProps {
  children: ReactNode;
  tag?: keyof JSX.IntrinsicElements & string;
  subtle?: boolean;
  withCloseButton?: boolean;
}

export default function Header({ tag = 'div', subtle, children, withCloseButton = true }: HeaderProps): ReactElement {
  return (
    <div
      className={cx(styles.Header, {
        [styles.Subtle]: subtle,
      })}
    >
      <Typography
        tag={tag}
        variant={subtle ? 'regular' : 'bold'}
        base="body"
        medium={subtle ? undefined : 'header5'}
        className={cx(styles.HeaderContent, { [styles.HeaderContentWithCloseButton]: withCloseButton })}
      >
        {children}
      </Typography>
    </div>
  );
}
