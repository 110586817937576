import React, { ReactElement, ReactNode } from 'react';
import Media from 'react-media';
import { OnClose, OnEntered, OnExited } from 'components/Modal/Wrapper';
import SlideIn from '../Transitions/SlideIn';
import Overlay from '../Overlay';
import Modal from '../Modal';
import Body from './Body';
import FadeIn from './Transitions/OverlayFadeIn';
import styles from './styles.module.css';

export interface PanelProps {
  isBase?: boolean;
  children: NonNullable<ReactNode>;
  iosBodyFreeze?: boolean;
  visible?: boolean;
  withCloseButton?: boolean;
  appearAnimation?: boolean;
  onEntered?: OnEntered;
  onExited?: OnExited;
  onClose: OnClose;
}

function ViewportAgnosticPanel({
  appearAnimation,
  children,
  iosBodyFreeze = false,
  isBase,
  visible = false,
  withCloseButton = true,
  onClose,
  onEntered,
  onExited,
}: PanelProps) {
  return (
    <Modal.Wrapper
      iosBodyFreeze={iosBodyFreeze}
      visible={visible}
      onEntered={onEntered}
      onExited={onExited}
      onClose={onClose}
    >
      {({ onEnter, onExit, onEntered: wrapperOnEntered, onExited: wrapperOnExited }) => (
        <div className={styles.FlexContainer}>
          <SlideIn
            appear={appearAnimation}
            from={isBase ? 'bottom' : 'right'}
            visible={visible}
            onEnter={onEnter}
            onExit={onExit}
            onEntered={wrapperOnEntered}
            onExited={wrapperOnExited}
          >
            {() => (
              <Modal.Content className={styles.PanelContainer} withCloseButton={withCloseButton} onClose={onClose}>
                {children}
              </Modal.Content>
            )}
          </SlideIn>
          <FadeIn appear={appearAnimation} visible={visible}>
            {() => <Overlay onClick={onClose} />}
          </FadeIn>
        </div>
      )}
    </Modal.Wrapper>
  );
}

export default function Panel(props: PanelProps): ReactElement {
  return (
    <Media query="(min-width: 480px)">{(matches) => <ViewportAgnosticPanel isBase={!matches} {...props} />}</Media>
  );
}

Panel.Body = Body;
Panel.Footer = Modal.Footer;
Panel.Header = Modal.Header;
