import theme from '../../themes/default';

export default {
  /* see .Input in styles.css */
  input: {
    color: theme['--color-gunmetal-1000'],
    'font-size': '16px',
    'line-height': '35px',
    'font-weight': '700',
  },

  /* see .Input::placeholder in styles.css */
  '::placeholder': {
    color: theme['--color-gunmetal-400'],
    'font-weight': 100,
  },
};
