import React from "react";
import LogoBlack from "../../assets/ornikarassurance-primary.svg";
import texts from "../../configs/texts";

const Reassurances = () => (
  <div
    className="hide-small styles_Reassurance__1Ddk6 styles_Reassurance__2yZjY"
    style={{ alignSelf: "flex-end" }}
  >
    <div className="styles_Logos__hqaJm">
      <div className="styles_Illustration__3BiV_">
        <LogoBlack height="2em" />
      </div>
    </div>
    <div className="styles_Text__3Sn1V">
      <p className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-body-small__25Mh1  styles-types-module_medium-body-small__gWkir  ">
        {texts.legal}
      </p>
    </div>
  </div>
);

export default Reassurances;
