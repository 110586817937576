import React, { useState, useMemo } from "react";

import texts from "../../configs/texts";

import Button from "./button";
import useAsync from "../../hooks/useAsync";
import Question from "./wrapper";

import renaultLogo from "../../assets/brands/renault.jpg";
import volkswagenLogo from "../../assets/brands/volkswagen.jpg";
import peugeotLogo from "../../assets/brands/peugeot.jpg";
import fordLogo from "../../assets/brands/ford.jpg";
import audiLogo from "../../assets/brands/audi.jpg";
import citroenLogo from "../../assets/brands/citroen.jpg";
import daciaLogo from "../../assets/brands/dacia.png";
import fiatLogo from "../../assets/brands/fiat.jpg";
import opelLogo from "../../assets/brands/opel.jpg";
import toyotaLogo from "../../assets/brands/toyota.jpg";
import { InputAutocomplete } from "@ornikar/kitt";

const logos = {
  RENAULT: renaultLogo,
  CITROEN: citroenLogo,
  VOLKSWAGEN: volkswagenLogo,
  PEUGEOT: peugeotLogo,
  FORD: fordLogo,
  AUDI: audiLogo,
  FIAT: fiatLogo,
  OPEL: opelLogo,
  TOYOTA: toyotaLogo,
  DACIA: daciaLogo,
};

const Qbrand = ({ answer, ...props }) => {
  const [brands, err, loading] = useAsync(
    () => fetch("/brands").then((res) => res.json()),
    []
  );
  const [brandSearch, setBrandSearch] = useState("");
  const results = useMemo(
    () =>
      (brands &&
        brands.filter(
          (b) => b.toLowerCase().indexOf(brandSearch.toLowerCase()) >= 0
        )) ||
      [],
    [brands, brandSearch]
  );

  return (
    <Question
      {...props}
      title={texts.brand.question}
      id="Qbrand"
      style={{ minHeight: "none" }}
    >
      <InputAutocomplete
        value={brandSearch}
        onInputValueChange={(v) => setBrandSearch(v)}
        onChange={(v) => {
          answer({ brand: v });
        }}
        inputProps={{ placeholder: texts.brand.placeholder }}
        emptyComponent={() => (
          <InputAutocomplete.Item>
            {loading
              ? "Chargement"
              : err
              ? "Il y a un problème avec notre serveur, réessayez plus tard"
              : texts.brand.emptyLabel}
          </InputAutocomplete.Item>
        )}
        isOpen={results.length < 10}
      >
        {results.map((b) => (
          <InputAutocomplete.Item item={b} key={b}>
            {b}
          </InputAutocomplete.Item>
        ))}
      </InputAutocomplete>
      <div className="buttonsContainer">
        {Object.keys(logos).map((brand, i) => (
          <Button
            key={brand}
            stretch
            className={
              "brandButton " +
              (i > 7 ? "dropTwo " : "") +
              (i > 8 ? "dropOne " : "")
            }
            type="accent"
            onClick={() => answer({ brand: brand })}
          >
            <img className="buttonImg" src={logos[brand]} alt={brand} />
          </Button>
        ))}
      </div>
    </Question>
  );
};
export default Qbrand;
