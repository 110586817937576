import React, { ReactElement, ComponentProps, Ref } from 'react';
import { Except } from 'type-fest';
import { BaseButtonProps, ButtonType } from './baseTypes';
import getClassNames from './getClassNames';
import ButtonContent from './ButtonContent';

type HTMLButtonType = 'submit' | 'reset' | 'button';

export interface BaseRealButtonProps extends BaseButtonProps {
  type?: Exclude<ButtonType, 'link'>;
  href?: never;
  to?: never;
}

export interface BaseRealSubmitButtonProps extends BaseRealButtonProps {
  submit: true;
  reset?: never;
}

export interface BaseRealResetButtonProps extends BaseRealButtonProps {
  reset: true;
  submit?: never;
}

export type RealSubmitButtonProps = BaseRealSubmitButtonProps & Except<ComponentProps<'button'>, 'ref'>;
export type RealResetButtonProps = BaseRealResetButtonProps & Except<ComponentProps<'button'>, 'ref'>;
export type RealButtonProps = BaseRealSubmitButtonProps | BaseRealResetButtonProps;

/**
 * Will render the proper <button/> in the DOM with the proper type
 */
const getButtonType = (submit?: boolean, reset?: boolean): HTMLButtonType => {
  if (submit) {
    return 'submit';
  }

  if (reset) {
    return 'reset';
  }

  return 'button';
};

export default React.forwardRef(function RealButton(
  {
    children,
    type,
    disabled,
    submit,
    reset,
    icon,
    iconPosition = 'left',
    stretch,
    large,
    className,
    ...props
  }: RealButtonProps,
  ref: Ref<HTMLButtonElement>,
): ReactElement {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      ref={ref}
      className={getClassNames(children, !!icon, iconPosition, large, stretch, type, disabled, className)}
      disabled={disabled}
      type={getButtonType(submit, reset)}
      {...props}
    >
      <ButtonContent icon={icon} iconPosition={iconPosition}>
        {children}
      </ButtonContent>
    </button>
  );
});
