import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import MapboxGL from 'mapbox-gl';
import Marker, { MarkerProps, getMarkerSizeValues } from 'components/Map/Marker';

export interface Popup {
  createContent: () => ReactElement;
  options?: MapboxGL.PopupOptions;
}
const addPopup = (
  mapboxgl: typeof MapboxGL,
  marker: MapboxGL.Marker,
  createPopupContent: Popup['createContent'],
  options: Popup['options'] = {},
) => {
  const popupInstance = new mapboxgl.Popup(options);

  let closePopup: () => void;

  const handleClose = () => {
    closePopup();
  };

  closePopup = () => marker.togglePopup();

  // Set Popup Content only when opened
  const setPopupContent = () => {
    const div = document.createElement('div');
    ReactDOM.render(React.cloneElement(createPopupContent(), { onClose: handleClose }), div);

    popupInstance.setDOMContent(div);
  };

  const deletePopupContent = () => {
    popupInstance.setDOMContent(document.createElement('div'));
  };

  popupInstance.on('open', setPopupContent);
  popupInstance.on('close', deletePopupContent);

  marker.setPopup(popupInstance);
};

const cachedMap = new Map();

const cachedMarkerDom = (type: MarkerProps['type']) => {
  const html = cachedMap.get(type);
  const div = document.createElement('div');

  if (!html) {
    ReactDOM.render(<Marker type={type} />, div);
    cachedMap.set(type, div.innerHTML);
  } else {
    div.innerHTML = html;
  }

  return div;
};

const makeMarker = (
  mapboxgl: typeof MapboxGL,
  map: MapboxGL.Map,
  lng: number,
  lat: number,
  type: MarkerProps['type'],
  popup?: Popup,
) => {
  const markerSize = getMarkerSizeValues();
  const marker = new mapboxgl.Marker({
    element: cachedMarkerDom(type),
    offset: [0, -(markerSize.height / 2)],
  })
    .setLngLat([lng, lat])
    .addTo(map);

  if (popup) {
    addPopup(mapboxgl, marker, popup.createContent, popup.options);
  }

  return marker;
};

export default __TARGET__ === 'node' ? undefined : makeMarker;
