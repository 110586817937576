import React, { ComponentProps } from 'react';
import { InputFormState } from 'utils/form';
import InputText from 'components/InputText';
import styles from './styles.module.css';

type InputProps = ComponentProps<'input'>;

export interface InputPasswordProps {
  name: string;
  id?: string;
  state?: InputFormState;
  value?: string;
  disabled?: boolean;
  onChange: InputProps['onChange'];
  onBlur?: InputProps['onBlur'];
  onFocus?: InputProps['onFocus'];
}

interface InputPasswordState {
  type: 'password' | 'text';
}

export default class InputPassword extends React.Component<InputPasswordProps, InputPasswordState> {
  static defaultProps = {
    value: '',
  };

  state: InputPasswordState = { type: 'password' };

  passwordInputRef = React.createRef<HTMLInputElement>();

  componentDidUpdate(prevProps: InputPasswordProps, prevState: InputPasswordState) {
    if (prevState.type !== this.state.type && this.passwordInputRef.current) {
      this.passwordInputRef.current.focus();
    }
  }

  togglePassword = () => {
    this.setState((state) => ({
      type: state.type === 'password' ? 'text' : 'password',
    }));
  };

  render() {
    return (
      <div className={styles.InputPasswordContainer}>
        <InputText
          ref={this.passwordInputRef}
          type={this.state.type}
          value={this.props.value}
          name={this.props.name}
          id={this.props.id}
          icon={this.state.type === 'password' ? 'eye' : 'eye-off'}
          state={this.props.state}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          onIconClicked={this.togglePassword}
        />
      </div>
    );
  }
}
