import cx from 'classnames';
import React, { MouseEventHandler, ReactNode, ReactElement } from 'react';
import styles from './styles.module.css';

export interface DropdownItemProps {
  children: ReactNode;
  active?: boolean;
  className?: string;
  value?: number | string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export default function DropdownItem({ children, className, onClick, value, active }: DropdownItemProps): ReactElement {
  return (
    <div
      aria-hidden
      className={cx(styles.DropdownItem, className, {
        [styles.ActiveItem]: active,
      })}
      role="button"
      data-value={value}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
