import { isFuture, parse, isPast, addYears, subYears } from "date-fns";

export type DateType = string;
export const toDate = (date: DateType): Date => {
    return parse(date, 'yyyy-MM-dd', new Date())
}

export interface Answers {
    brand: string // Marque véhicule
    model: string // Modèle véhicule
    already: boolean // Avez-vous déjà été assuré ?
    still: "less1year" | "more1year" | "no" // Etes vous encore assuré ?
    subscription: DateType | "unknown" // YYYY-MM-DD date de souscription contract actuel ?
    currentAssu: string,
    currentPrice: number,
    malus: number // CRM 0.5 <-> 3.5
    when: "today" | "week" | "month" | "later" // Quand souhaitez vous être assuré 
    license: boolean // avez vous le permis
    licensewhen: DateType | "unknown" // YYYY-MM-DD quand allez vous passer le permis
    alreadycar: boolean // avez vous déjà le véhicule que vous souhaitez assurer
    cp: string // code postal
    age: string // age
    fname: string // prénom
    lname: string // nom
    email: string // email
    phone: string // téléphone
    contactOptin: boolean // j'accepte recevoir offre ...
}

const EMAILREGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validEmail = (email: string) => EMAILREGEXP.test(email)

const PHONEREGEXP = /^0[1-9](?:[\s.-]*\d{2}){4}$/;
export const validPhone = (phone: string) => PHONEREGEXP.test(phone)
export const fixPhone = (phone: string) => phone.replace(/. -/g, "")

const CPREGEXP = /^\d{5}$/;
export const validCP = (cp: string) => CPREGEXP.test(cp)

export const validAge = (age: string) => {
    const ageNum = parseInt(age, 10);
    return ageNum >= 18 && ageNum < 99;
}

const NAMEREGEXP = /[A-Za-zÀ-ÖØ-öø-ÿ][A-Za-zÀ-ÖØ-öø-ÿ \-]*/;
export const validFName = (v: string) => NAMEREGEXP.test(v) && v.trim() !== ""
export const validLName = (v: string) => NAMEREGEXP.test(v) && v.trim() !== ""

export const validSubscription = (date: DateType) => {
    if (isFuture(toDate(date)))
        return "La date ne peut pas être dans le futur.";
    if (isPast(addYears(toDate(date), 1)))
        return "La date ne peut pas être plus d'un an dans le passé.";
}

export const validLicensewhen = (date: DateType) => {
    if (isPast(toDate(date)))
        return "La date ne peut pas être dans le passé.";
    if (isFuture(subYears(toDate(date), 1)))
        return "La date ne peut pas être plus d'un an dans le futur.";
}