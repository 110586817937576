import React from "react";
import texts from "../../configs/texts";

import Question from "./wrapper";
import Button from "./button";
import Spacer from "./spacer";

const Qwhen = ({ answer, ...props }) => {
  return (
    <Question {...props} title={texts.when.question} id="Qwhen">
      <Button
        style={{ textAlign: "left" }}
        stretch
        id="btn-when-today"
        onClick={() => answer({ when: "today" })}
      >
        {texts.when.answers.today}
      </Button>
      <br />
      <Button
        style={{ textAlign: "left" }}
        stretch
        id="btn-when-week"
        onClick={() => answer({ when: "week" })}
      >
        {texts.when.answers.week}
      </Button>
      <br />
      <Button
        style={{ textAlign: "left" }}
        stretch
        id="btn-when-month"
        onClick={() => answer({ when: "month" })}
      >
        {texts.when.answers.month}
      </Button>
      <br />
      <Button
        style={{ textAlign: "left" }}
        stretch
        id="btn-when-later"
        onClick={() => answer({ when: "later" })}
      >
        {texts.when.answers.later}
      </Button>
      <Spacer />
    </Question>
  );
};

export default Qwhen;
