import { useState, useEffect } from 'react';

export default function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState(() => window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);

    const listener = (): void => setMatches(media.matches);
    media.addListener(listener);

    return (): void => media.removeListener(listener);
  }, [query]);

  // publish value for render
  return matches;
}
