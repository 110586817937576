// import Tooltip from "./landing/Tooltip";
import { Button } from "@ornikar/kitt";
import React from "react";
import contactOrnikar from "../../assets/contactornikar.png";
import LogoBlack from "../../assets/ornikarassurance-primary.svg";
import tracking from "../../configs/tracking";

const HeaderRestitution = ({ children }) => {
  return (
    <header className="styles-module_Header__3jJsx">
      <div className="styles-module_Content__1X_9b">
        <a className="styles-module_Link__GVqkR    " href="/">
          <LogoBlack className="styles-module_Logo__33vY1  styles-module_Logo__2EP3X " />
        </a>
        <nav
          className="styles-module_Nav__IYROW  styles-module_Medium__2v-qB"
          style={{ alignItems: "center" }}
        >
          {children}
          <div
            className="styles-module_Button__2zcg9"
            id="btn-header-restitution-contact"
          >
            <Button
              id="btn-header-restitution-contact"
              type="subtle"
              stretch
              href="tel:+33755537527"
            >
              <img
                src={contactOrnikar}
                alt="Contact"
                style={{ height: "1.5em", transform: "scale(1.5)" }}
              />
            </Button>
          </div>
        </nav>
        <div className="styles-module_BurgerButton__FXQbB">
          <Button
            type="subtle"
            href="tel:+33755537527"
            id="btn-header-restitution-contact-mobile"
            onClick={tracking.clickHeaderContact}
          >
            <img
              src={contactOrnikar}
              alt="Contact"
              style={{ height: "1.5em", transform: "scale(1.5)" }}
            />
          </Button>
        </div>
      </div>
    </header>
  );
};

export default HeaderRestitution;
