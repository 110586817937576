import React from 'react';
import styles from './styles.module.css';

export default function EmptyList() {
  return (
    <div className={styles.EmptyList}>
      Cette adresse postale n’est pas reconnue.
      <br />
      Essayez de l&apos;entrer à nouveau ou enregistrez une autre adresse.
    </div>
  );
}
