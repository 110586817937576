import React from "react";

import Qdate from "../long_questions/Qdate";

import Qalready from "./Qalready";
import Qbrand from "./Qbrand";
import Qmodel from "./Qmodel";
import Qstill from "./Qstill";
import Qwhen from "./Qwhen";
import Qcp from "./Qcp";
import Qage from "./Qage";
import Qmalus from "./Qmalus";
import Qnames from "./Qnames";
import Qemail from "./Qemail";
import Qalreadycar from "./Qalreadycar";
import Qlicense from "./Qlicense";
import QcurrentAssu from "./QcurrentAssu";
import QcurrentPrice from "./QcurrentPrice";
import { validLicensewhen, validSubscription } from "../../types/answers";

const Question = ({ which, ...props }) => {
  if (window.location.search.indexOf("DEBUG") > 0) {
    //FOR DEBUGGING
    props.answers.brand = "RENAULT";
    return (
      <>
        <Qbrand {...props} />
        <Qmodel {...props} />
        <Qalready {...props} />
        <Qstill {...props} />
        <Qmalus {...props} />
        <Qwhen {...props} />
        <Qlicense {...props} />
        <Qalreadycar {...props} />
        <Qcp {...props} />
        <Qage {...props} />
        <Qnames {...props} />
        <Qemail {...props} />
      </>
    ); /**/
  }

  switch (which) {
    case "brand":
      return <Qbrand {...props} />;
    case "model":
      return <Qmodel {...props} />;
    case "already":
      return <Qalready {...props} />;
    case "still":
      return <Qstill {...props} />;
    case "subscription":
      return (
        <Qdate
          {...props}
          which="subscription"
          validator={validSubscription}
          skippable
        />
      );
    case "currentAssu":
      return <QcurrentAssu {...props} />;
    case "currentPrice":
      return <QcurrentPrice {...props} />;
    case "malus":
      return <Qmalus {...props} />;
    case "when":
      return <Qwhen {...props} />;
    case "license":
      return <Qlicense {...props} />;
    case "licensewhen":
      return (
        <Qdate
          {...props}
          which="licensewhen"
          validator={validLicensewhen}
          skippable
        />
      );
    case "alreadycar":
      return <Qalreadycar {...props} />;
    case "cp":
      return <Qcp {...props} />;
    case "age":
      return <Qage {...props} />;
    case "fname":
    case "lname":
      return <Qnames {...props} />;
    case "email":
    case "phone":
      return <Qemail {...props} />;
    default:
      throw new Error("unhandled flow : " + which);
  }
};
export default Question;
