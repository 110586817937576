const pushDL = (arg) => window.dataLayer && window.dataLayer.push(arg);
const pushFB = (...args) => window.fbq && window.fbq(...args);

const tracking = {
  pushDL: (arg) => {
    pushDL(arg);
  },
  virtualPageView: (page) => {
    pushDL({ event: "virtualPageChange", virtualPage: page });
  },
  answer: (changes) => {
    const whichQuestion = Object.keys(changes)[0];
    pushDL({ event: "fastQuoteAnswer", whichQuestion });
    if (changes.email) {
      pushFB("track", "CustomizeProduct");
    }
  },
  back: (changes) => {
    const whichQuestion = Object.keys(changes)[0];
    pushDL({ event: "fastQuoteBack", whichQuestion });
  },
  clickHeaderLogo: () => {
    pushDL({ event: "clickLogo", where: "header" });
  },
  clickHeaderFAQ: () => {
    pushDL({ event: "clickFAQ", where: "header" });
  },
  clickHeaderHow: () => {
    pushDL({ event: "clickHow", where: "header" });
  },
  clickHeaderContact: () => {
    pushDL({ event: "clickContact", where: "header" });
    pushFB("track", "Contact");
  },
  clickLandingLearnMore: () => {
    pushDL({ event: "clickFAQ", where: "learn_more" });
  },

  // 3 blocks
  clickLandingCTA1: () => {
    pushDL({ event: "clickLandingCTA", where: "3blocks" });
  },
  // Step1
  clickLandingCTA2: () => {
    pushDL({ event: "clickLandingCTA", where: "step1" });
  },
  // Step6
  clickLandingCTA3: () => {
    pushDL({ event: "clickLandingCTA", where: "step6" });
  },
  // After steps
  clickLandingCTA4: () => {
    pushDL({ event: "clickLandingCTA", where: "afterSteps" });
  },
  // pre-footer
  clickLandingCTA5: () => {
    pushDL({ event: "clickLandingCTA", where: "endPage" });
  },

  clickFooterFAQ: () => {
    pushDL({ event: "clickFAQ", where: "footer" });
  },
  clickFooterHow: () => {
    pushDL({ event: "clickHow", where: "footer" });
  },
  clickFooterCGV: () => {
    pushDL({ event: "clickCGV", where: "footer" });
  },
  clickFooterFB: () => {
    pushDL({ event: "clickFB", where: "footer" });
  },
  clickFooterEmail: () => {
    pushDL({ event: "clickContact", where: "footer_email" });
    pushFB("track", "Contact");
  },
  clickFooterPhone: () => {
    pushDL({ event: "clickContact", where: "footer_phone" });
    pushFB("track", "Contact");
  },

  clickEndFastQuote: () => {
    pushFB("track", "SubmitApplication");
  },

  clickEndLongQuote: () => {
    pushFB("track", "CompleteRegistration");
  },

  clickConfirmCustomization: () => {
    pushFB("track", "Donate");
  },

  clickCalendlyCTA: () => {
    pushFB("track", "Schedule");
  }
};

export default tracking;
