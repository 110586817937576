/* eslint-disable complexity */
import cx from 'classnames';
import { ReactNode } from 'react';
import styles from './styles.module.css';
import { ButtonType } from './baseTypes';
import { ButtonContentProps } from './ButtonContent';

/**
 * Add the proper classes to the final Button element
 */
const getClassNames = (
  children: ReactNode,
  withIcon: boolean,
  iconPosition: ButtonContentProps['iconPosition'],
  large: undefined | boolean,
  stretch: undefined | boolean,
  type: ButtonType = 'secondary',
  disabled?: boolean,
  className?: string,
): string =>
  cx(
    styles.Button,
    {
      // Variants
      [styles.Primary]: type && type === 'primary',
      [styles.Secondary]: type === 'secondary',
      [styles.Accent]: type && type === 'accent',
      [styles.Danger]: type && type === 'danger',
      [styles.Subtle]: type && type === 'subtle',
      [styles.SubtleDark]: type && type === 'subtle-dark',
      [styles.Facebook]: type && type === 'facebook',
      // With icon
      [styles.OnlyIcon]: withIcon && !children,
      [styles.IconRight]: iconPosition && withIcon && children && iconPosition === 'right',
      // Size effects
      [styles.Large]: large,
      [styles.Stretch]: children && stretch,
      // Disabled
      [styles.Disabled]: disabled,
    },
    className,
  );

export default getClassNames;
