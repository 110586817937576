import React, { ComponentProps } from 'react';
import MagnifyButton, { MagnifyButtonProps } from 'components/Map/MagnifyButton';
import { MapCenter } from 'components/Map/types';
import styles from './styles.module.css';

const isRetina = () => {
  if (typeof window === 'undefined') {
    return true;
  }

  // requesting retina picture is up to the device capabilities;
  return window.devicePixelRatio && window.devicePixelRatio > 1;
};

/**
 * Make the url of the map
 *
 * @param width
 * @param height
 * @param center
 * @return {string}
 */
const makeMapUrl = (width: number, height: number, center: MapCenter) => {
  const mapId = process.env.MAP_BOX_MAP_ID;
  const token = process.env.MAP_BOX_API_KEY;
  return (
    `https://api.mapbox.com/styles/v1/ornikar/${mapId}/static/` +
    `${center.long},${center.lat},${center.zoom}/` +
    `${Math.round(width)}x${Math.round(height)}${isRetina() ? '@2x' : ''}` +
    `?access_token=${token}`
  );
};

type ImgProps = ComponentProps<'img'>;

export interface MapPictureProps {
  width: number;
  height: number;
  center: MapCenter;
  alt?: string;
  onLoad: ImgProps['onLoad'];
  onError: ImgProps['onError'];
  onMagnifyMapClick?: MagnifyButtonProps['onClick'];
}

export default function MapPicture({
  width,
  height,
  center,
  alt,
  onLoad,
  onError,
  onMagnifyMapClick,
}: MapPictureProps) {
  return (
    <div className={styles.MapPicture}>
      <img src={makeMapUrl(width, height, center)} alt={alt} onLoad={onLoad} onError={onError} />
      {onMagnifyMapClick && <MagnifyButton onClick={onMagnifyMapClick} />}
    </div>
  );
}
