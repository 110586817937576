import cx from 'classnames';
import React from 'react';
import appSet from 'components/Icon/appSet';
import styles from './styles.module.css';

export interface IconProps {
  name: keyof typeof appSet | 'loading';
  inlineAlign?: 'sub' | 'baseline';
  color?: string;
}

export default function Icon({ name, inlineAlign, color }: IconProps) {
  const wrapperProps = {
    'data-test': 'icon-wrapper',
    ...(color && { style: { color } }),
  };

  if (name === 'loading') {
    return (
      <div className={styles.Loader} {...wrapperProps}>
        <appSet.loader />
      </div>
    );
  }

  // Default to hash icon if name was not found
  const Graphic = appSet[name] || appSet.hash;

  return (
    <div
      className={cx(styles.IconContainer, {
        [styles.InlineAlign]: Boolean(inlineAlign),
        [styles.InlineAlignSub]: inlineAlign === 'sub',
      })}
      {...wrapperProps}
    >
      <Graphic />
    </div>
  );
}
