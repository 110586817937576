import classNames from 'classnames';
import { Except } from 'type-fest';
import React, {
  ComponentProps,
  ComponentType,
  createContext,
  ElementType,
  PropsWithChildren,
  ReactElement,
} from 'react';
import Link, { LinkProps, LinkImplementationRequiredProps, DefaultLinkImplementationProps } from '../Link';
import variants from './styles.variants.module.css';
import types from './styles.types.module.css';

type BaseTypographyType = 'header1' | 'header2' | 'header3' | 'header4' | 'body' | 'body-small';
type SmallTypographyType = BaseTypographyType;
type MediumTypographyType = BaseTypographyType | 'header5';
type LargeTypographyType = MediumTypographyType;

type TypographyVariant = 'regular' | 'bold' | 'light';
type TypographyColor = 'black' | 'white' | 'primary' | 'tertiary' | 'success';

const ColorContext = createContext((undefined as unknown) as TypographyColor);

interface TypographyTagBaseProps {
  className?: string;
}

export interface TypographyProps<P extends TypographyTagBaseProps = TypographyTagBaseProps> {
  tag: ElementType<P>;
  color?: TypographyColor;
  variant?: TypographyVariant;
  base?: BaseTypographyType;
  small?: SmallTypographyType;
  medium?: MediumTypographyType;
  large?: LargeTypographyType;
}

function InternalTypography<P extends TypographyTagBaseProps = TypographyTagBaseProps>({
  tag,
  className,
  color,
  variant = 'regular',
  base,
  small,
  medium = __DEV__ ? undefined : small || base,
  large,
  ...props
}: TypographyProps<P> & P): ReactElement {
  if (__DEV__) {
    if (!color) {
      throw new Error('color missing');
    }

    if (variant === 'light' && color !== 'black') {
      throw new Error('light variant is only available with black color');
    }

    if (large && large === medium) {
      throw new Error('No need to set the large prop if it is the same as medium !');
    }

    if (medium && medium === (small || base)) {
      throw new Error(`No need to set the medium prop if it is the same as ${small ? 'small' : 'base'} !`);
    }

    if (small && small === base) {
      throw new Error('No need to set the small prop if it is the same as base !');
    }

    // eslint-disable-next-line no-param-reassign
    if (!medium) medium = small || base;
  }

  const Tag = tag as ComponentType<P>;

  return (
    <Tag
      {...(props as any)}
      className={classNames(
        types.Reset,
        (variants as any)[`${color}-${variant}`],
        base && (types as any)[`base-${base}`],
        small && (types as any)[`small-${small}`],
        medium && (types as any)[`medium-${medium}`],
        large && (types as any)[`large-${large}`],
        className,
      )}
    />
  );
}

// @types/react currently don't consider children is part of the props
function Typography<P extends PropsWithChildren<TypographyTagBaseProps>>(props: TypographyProps<P> & P) {
  if (__DEV__) {
    const keys = Object.keys(props);
    const order = ['base', 'small', 'medium', 'large'];
    order.forEach((prop, index) => {
      if (index === 0 || !keys.includes(prop)) return;
      order.slice(0, index).forEach((propBefore) => {
        if (keys.includes(propBefore) !== undefined && keys.indexOf(propBefore) > keys.indexOf(prop)) {
          throw new Error(`prop ${prop} must be placed after ${propBefore}`);
        }
      });
    });
  }

  if (props.color) {
    // use the color and pass the color to the context for children
    return (
      <ColorContext.Provider value={props.color}>
        <InternalTypography {...props} />
      </ColorContext.Provider>
    );
  }

  // use parent color if defined or default to black
  return (
    <ColorContext.Consumer>
      {(colorInContext) => <InternalTypography {...props} color={colorInContext || 'black'} />}
    </ColorContext.Consumer>
  );
}

type TypographyWithoutTagProps<P extends TypographyTagBaseProps> = Except<TypographyProps<P>, 'tag'>;

interface CreateForTagConfig<Tag extends ElementType<TypographyTagBaseProps>> {
  tag: Tag;
  defaultType?: BaseTypographyType;
  smallType?: SmallTypographyType;
  mediumType?: MediumTypographyType;
}

function createForTag<Tag extends ElementType<TypographyTagBaseProps>>({
  tag,
  defaultType,
  smallType,
  mediumType,
}: CreateForTagConfig<Tag>): ComponentType<
  TypographyWithoutTagProps<ComponentProps<Tag>> & PropsWithChildren<ComponentProps<Tag>>
> {
  const TypographyWithTag = ({
    base = defaultType,
    small = smallType,
    medium = mediumType,
    ...props
  }: TypographyWithoutTagProps<ComponentProps<Tag>> & PropsWithChildren<ComponentProps<Tag>>): ReactElement => {
    return <Typography tag={tag} base={base} small={small} medium={medium} {...props} />;
  };
  if (__DEV__) {
    TypographyWithTag.displayName = `Typography.${tag}`;
  }
  return TypographyWithTag;
}

Typography.h1 = createForTag({ tag: 'h1', defaultType: 'header1' });
Typography.h2 = createForTag({ tag: 'h2', defaultType: 'header2' });
Typography.h3 = createForTag({ tag: 'h3', defaultType: 'header3' });
Typography.h4 = createForTag({ tag: 'h4', defaultType: 'header4' });
Typography.h5 = createForTag({ tag: 'h5', defaultType: 'body', mediumType: 'header5' });
Typography.div = createForTag({ tag: 'div' });
Typography.span = createForTag({ tag: 'span' });
Typography.p = createForTag({ tag: 'p' });
Typography.section = createForTag({ tag: 'section' });

export type TypographyLinkProps<P extends LinkImplementationRequiredProps> = TypographyWithoutTagProps<LinkProps<P>> &
  LinkProps<P>;

function TypographyLink<P extends LinkImplementationRequiredProps = DefaultLinkImplementationProps>({
  color,
  variant = 'bold',
  ...props
}: TypographyLinkProps<P>): ReactElement {
  return <Typography tag={Link} color={color} variant={variant === 'regular' ? undefined : variant} {...props} />;
}

Typography.Link = TypographyLink;

export default Typography;
