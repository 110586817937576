import React, { ReactNode, ReactElement } from 'react';
import Media from 'react-media';
import { KittTransitionProps } from '../Transitions/propTypes';
import SlideIn from '../Transitions/SlideIn';
import Rotate from '../Transitions/Rotate';

export interface TransitionProps extends Omit<KittTransitionProps, 'children'> {
  children: ReactNode;
}

export default function Transition({ children, ...props }: TransitionProps): ReactElement {
  return (
    <Media query="(min-width: 480px)">
      {(matches): ReactElement => {
        return matches ? (
          <Rotate {...props}>{(): ReactNode => children}</Rotate>
        ) : (
          <SlideIn from="bottom" {...props}>
            {(): ReactNode => children}
          </SlideIn>
        );
      }}
    </Media>
  );
}
