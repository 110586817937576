import React, { ReactNode, ReactElement } from 'react';
import FadeIn from '../Transitions/FadeIn';
import Overlay from '../Overlay';
import Transition from './Transition';
import Body from './Body';
import Header from './Header';
import Footer from './Footer';
import Content from './Content';
import Wrapper, { OnEntered, OnExited, OnClose } from './Wrapper';
import styles from './styles.module.css';

export interface ModalProps {
  visible?: boolean;
  children: ReactNode;
  fullscreen?: boolean;
  iosBodyFreeze?: boolean;
  withCloseButton?: boolean;
  appearAnimation?: boolean;
  onClose: OnClose;
  onEntered?: OnEntered;
  onExited?: OnExited;
}

/**
 * @deprecated
 */
export default function Modal({
  appearAnimation,
  children,
  fullscreen,
  iosBodyFreeze,
  visible,
  withCloseButton = true,
  onClose,
  onEntered,
  onExited,
}: ModalProps): ReactElement {
  return (
    <Wrapper
      iosBodyFreeze={iosBodyFreeze}
      visible={visible}
      onEntered={onEntered}
      onExited={onExited}
      onClose={onClose}
    >
      {({ onEnter, onExit, onEntered: wrapperOnEntered, onExited: wrapperOnExited }): ReactElement => (
        <div className={styles.FlexContainer}>
          <Transition
            appear={appearAnimation}
            visible={visible}
            onEnter={onEnter}
            onExit={onExit}
            onEntered={wrapperOnEntered}
            onExited={wrapperOnExited}
          >
            {(): ReactElement => (
              <Content
                className={fullscreen ? styles.ContainerFullScreen : undefined}
                withCloseButton={withCloseButton}
                onClose={onClose}
              >
                {children}
              </Content>
            )}
          </Transition>
          <FadeIn appear={appearAnimation} visible={visible}>
            {(): ReactElement => <Overlay onClick={onClose} />}
          </FadeIn>
        </div>
      )}
    </Wrapper>
  );
}

Modal.Header = Header;
Modal.Footer = Footer;
Modal.Body = Body;
Modal.Wrapper = Wrapper;
Modal.Content = Content;
