import React from "react";

const PatternBackground = ({ children }) => (
  <div style={{ position: "relative" }}>
    <div className="PatternContainer-33uUR6xEhxN6P_6slSRlSK">
      <div
        className="Pattern-1quShsxvCruP5wl3_ckk4x"
        style={{ backgroundPosition: "2400px -150px" }}
      ></div>
    </div>
    <div style={{ position: "relative" }}>{children}</div>
  </div>
);

export default PatternBackground;
