import React from "react";

import Question from "./wrapper";
import Button from "./button";
import texts from "../../configs/texts";
import Spacer from "./spacer";

const Qstill = ({ answer, ...props }) => {
  return (
    <Question
      {...props}
      className="still"
      title={texts.still.question}
      id="Qstill"
    >
      <Button
        stretch
        type="accent"
        id="btn-still-more1year"
        onClick={() => answer({ still: "more1year" })}
        __html={texts.still.answers.yes_more}
      />

      <br />
      <br />
      <Button
        stretch
        type="accent"
        id="btn-still-less1year"
        onClick={() => answer({ still: "less1year" })}
        __html={texts.still.answers.yes_less}
      />

      <br />
      <br />
      <Button
        stretch
        type="accent"
        id="btn-still-no"
        onClick={() => answer({ still: "no" })}
        __html={texts.still.answers.no}
      />
      <Spacer />
    </Question>
  );
};

export default Qstill;
