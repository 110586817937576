import React from 'react';
import Icon from 'components/Icon';
import styles from './styles.module.css';

export default function ManualAddress() {
  return (
    <div className={styles.ManualAddress}>
      <Icon name="pencil" />
      <span>Saisir mon adresse manuellement</span>
    </div>
  );
}
