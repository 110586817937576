import React from "react";
import texts from "../../configs/texts";
import { Icon } from "@ornikar/kitt";

const FourParts = () => (
  <div className="Guarantees-2B6iwIFDQFB3D5T1aKTIEo BlockContainer-2_S0gSBQnVmsZ1NwIHXBGR">
    {texts.landing.fourParts.map((part, idx) => (
      <div className="Guarantee-C6ySq8FT-1dIja_Z7Ir0I" key={idx}>
        <div className="styles-types-module_Reset__1HOMT styles-variants-module_primary-regular__CNGUD     IconContainer-1EJsG0kbjWbpgdq5X1SgbM">
          <Icon name={part.icon} />
        </div>
        <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header3__FKPGi  styles-types-module_medium-header4__2YZKi  Title-3h5zejohaee6YWtn7XZrDO">
          {part.title}
        </div>
        <p className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz     ">
          {part.content}
        </p>
      </div>
    ))}
  </div>
);

export default FourParts;
