/* eslint-disable react/no-unused-state */
import { Component, ReactNode } from 'react';

interface PromiseRenderState<Result> {
  status: 'pending' | 'resolved' | 'rejected';
  result: undefined | Result;
  error: undefined | Error;
  promise: undefined | Promise<Result>;
}

interface PromiseRenderProps<Result> {
  promise: Promise<Result>;
  children: (state: PromiseRenderState<Result>) => ReactNode;
}

export default class PromiseRender<Result> extends Component<PromiseRenderProps<Result>, PromiseRenderState<Result>> {
  state: PromiseRenderState<Result> = {
    status: 'pending',
    result: undefined,
    error: undefined,
    promise: undefined,
  };

  static getDerivedStateFromProps<Result>(props: PromiseRenderProps<Result>, state: PromiseRenderState<Result>) {
    return props.promise !== state.promise ? { status: 'pending', promise: props.promise } : null;
  }

  componentDidMount() {
    this.attachPromise(this.props.promise);
  }

  componentDidUpdate(prevProps: PromiseRenderProps<Result>) {
    if (prevProps.promise !== this.props.promise) {
      this.attachPromise(this.props.promise);
    }
  }

  attachPromise = (promise: Promise<Result>) => {
    const onSuccess = (result: Result) => {
      this.setState({ status: 'resolved', result });
      return result;
    };

    const onError = (error: Error) => {
      this.setState({ status: 'rejected', error });
      throw error;
    };

    promise.then(onSuccess).catch(onError);
  };

  render() {
    return this.props.children(this.state);
  }
}
