import React, { ReactNode } from 'react';
import Message, { MessageProps } from '../Message';

export interface NotificationProps {
  children: NonNullable<ReactNode>;
  type?: MessageProps['type'];
  centeredText?: MessageProps['centeredText'];
  onDelete?: MessageProps['onDismiss'];
}

export default function Notification({ type, children, centeredText, onDelete }: NotificationProps) {
  return (
    <Message noRadius type={type} centeredText={centeredText} onDismiss={onDelete}>
      {children}
    </Message>
  );
}
