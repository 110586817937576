import React, { useState, useCallback } from "react";
import { InputText, InputFeedback } from "@ornikar/kitt";
import texts from "../../configs/texts";

const CodePromo = () => {
  const [displayPromo, setDisplayPromo] = useState(false);
  const [codePromo, setCodePromo] = useState("");
  const [validState, setValidState] = useState(undefined);
  const onFieldBlur = useCallback(() => {
    setTimeout(() => {
      if (codePromo === "") setDisplayPromo(false);
      else setValidState("invalid");
    }, 200);
  }, [codePromo, setDisplayPromo, setValidState]);
  const onFieldChange = useCallback(
    (e) => {
      setValidState(undefined);
      setCodePromo(e.target.value);
    },
    [codePromo, setDisplayPromo, setValidState]
  );

  return (
    <div className="styles_Coupon__2RVRj">
      {!displayPromo ? (
        <div>
          <a
            id="link-display-promo"
            onClick={() => setDisplayPromo((x) => !x)}
            className="styles-module_Link__GVqkR    styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz     "
            dangerouslySetInnerHTML={texts.restitution.coupon_link}
          />
        </div>
      ) : (
        <div>
          <form className="styles_CouponForm__1Rw8l">
            <div className="styles-module_DefaultStyling__jYFJ9">
              <div className="styles-module_LabelContainer__1_BEJ">
                <label
                  htmlFor="couponKey"
                  className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-body__2VaSM  styles-types-module_medium-body__hjzlF  styles-module_Label__2c3jR"
                >
                  {texts.restitution.coupon_label}
                </label>
              </div>
              <InputText
                autoFocus
                state={validState}
                name="couponKey"
                id="couponKey"
                onBlur={onFieldBlur}
                onChange={onFieldChange}
                value={codePromo}
                autoComplete="off"
              />
              {validState == "invalid" && (
                <InputFeedback state="invalid">
                  {texts.restitution.coupon_error}
                </InputFeedback>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default CodePromo;
