import React from 'react';
import styles from './styles.module.css';

export default function LargeLoader() {
  return (
    <div className={styles.LargeLoader}>
      <svg width="60" height="60">
        <g className={styles.Base}>
          <circle cx="30" cy="30" r="27" fill="none" />
        </g>
        <g className={styles.Fill}>
          <circle cx="30" cy="30" r="27" fill="none" />
        </g>
      </svg>
    </div>
  );
}
