/* eslint-disable max-lines */
import cx from 'classnames';
import { Except } from 'type-fest';
import React, { Component, ComponentType, ReactComponentElement } from 'react';
import Downshift, { ChildrenFunction, DownshiftProps, GetInputPropsOptions, PropGetters } from 'downshift';
import Input from 'components/InputText';
import ResultsItem from './ResultsItem';
import styles from './styles.module.css';

export interface InputAutocompleteProps<Item = any>
  extends Except<
    DownshiftProps,
    | 'children'
    | 'defaultHighlightedIndex'
    | 'inputValue'
    | 'render'
    | 'stateReducer'
    | 'itemToString'
    | 'onChange'
    | 'onUserAction'
    | 'onInputValueChange'
  > {
  children?: ReactComponentElement<typeof ResultsItem>[] | ReactComponentElement<typeof ResultsItem>;
  defaultInputValue?: string;
  emptyComponent?: ComponentType<{}>;
  inputProps?: GetInputPropsOptions;
  itemToString?: (item: Item) => string | null;
  onChange?: (selectedItem: Item) => void;
  onInputValueChange?: (inputValue: string) => void;
}

interface InputAutocompleteState {
  inputValue?: string;
}

export default class InputAutocomplete<Item = any> extends Component<
  InputAutocompleteProps<Item>,
  InputAutocompleteState
> {
  static defaultProps = {
    inputProps: {},
    defaultInputValue: '',
  };

  static Item = ResultsItem;

  private cacheSelectedItem = null;

  private userInputtedValue: string = this.props.defaultInputValue || '';

  private results: ReactComponentElement<typeof ResultsItem>[] = [];

  state = {
    inputValue: this.props.defaultInputValue,
  };

  downshiftStateReducer: DownshiftProps['stateReducer'] = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEscape:
        if (this.cacheSelectedItem) {
          return {
            // @ts-expect-error
            selectedItem: this.cacheSelectedItem,
            ...changes,
          };
        }
        return {
          ...changes,
          inputValue: this.userInputtedValue,
        };
      case Downshift.stateChangeTypes.changeInput:
        return {
          ...changes,
          selectedItem: null,
        };
      default:
        return changes;
    }
  };

  setResults = (results: ReactComponentElement<typeof ResultsItem>[]) => {
    this.results = results;
  };

  handleChange: DownshiftProps['onChange'] = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  handleInputValueChange: DownshiftProps['onInputValueChange'] = (inputValue) => {
    if (inputValue === null) {
      // handleInputValueChange is called by itemToString(onblur) with null value.
      return;
    }
    if (this.props.onInputValueChange) {
      this.props.onInputValueChange(inputValue);
    }
  };

  handleItemToString: DownshiftProps['itemToString'] = (item) => {
    if (!item) {
      // itemToString is called by onblur event, with null value.
      return null;
    }
    if (this.props.itemToString) {
      return this.props.itemToString(item);
    }
    return item;
  };

  /*
   * see https://github.com/paypal/downshift/blob/master/stories/examples/react-autosuggest.js
   *
   * This function allows to change the displayed value in the input, while keeping the typed value in `userInputtedValue`.
   * Also, keep the last selected item (`cacheSelectedItem`), for when the user started typing something but cancelled with the `Esc` key.
   * Reproduce the behaviour observed in the Google Map's input.
   */
  onUserAction: DownshiftProps['onUserAction'] = (changes, { itemToString }) => {
    this.setState(({ inputValue }) => {
      const valueChanged = (value: string) => Object.prototype.hasOwnProperty.call(changes, value);

      if (changes.selectedItem) {
        this.cacheSelectedItem = changes.selectedItem;
      }

      // the menu is closing, either because the user selected something or close it (Esc or outside click)
      const isClosingMenu = valueChanged('isOpen') && !changes.isOpen;

      if (isClosingMenu) {
        if (!changes.selectedItem) {
          // we change back the input's value to what the user typed
          return { inputValue: this.userInputtedValue };
        }

        // If the user selected a item, we change the input's value
        const nextInputValue = itemToString(changes.selectedItem);
        if (nextInputValue === null) {
          // the selected item is a special mode
          return { inputValue: this.userInputtedValue };
        }

        this.userInputtedValue = nextInputValue;
        return { inputValue: nextInputValue };
      }
      if (valueChanged('inputValue')) {
        // the user typed something in the input, so we update the value
        const nextInputValue = changes.inputValue;
        this.userInputtedValue = nextInputValue;
        if (!nextInputValue) {
          // if the user deleted everything, we clear the selected item cache
          // (else if he/she clicked on the input then `Esc`, the value would have been the cache item)
          this.cacheSelectedItem = null;
        }
        return { inputValue: nextInputValue };
      }
      if (
        // highlightedIndex has changed
        valueChanged('highlightedIndex') &&
        (changes.type === Downshift.stateChangeTypes.keyDownArrowUp ||
          changes.type === Downshift.stateChangeTypes.keyDownArrowDown)
      ) {
        if (changes.highlightedIndex === 0) {
          // the index 0 is the invisible result for the user typed value
          return { inputValue: this.userInputtedValue };
        }

        // we change the input's value to the currently highlighted item
        let nextInputValue = itemToString(this.results[changes.highlightedIndex].props.item);
        if (nextInputValue === null) {
          // the selected item is a special mode
          nextInputValue = this.userInputtedValue;
        }
        return { inputValue: nextInputValue };
      }

      return { inputValue };
    });
  };

  renderResults = (
    inputValue: string | null | undefined,
    getItemProps: PropGetters['getItemProps'],
    highlightedIndex: number | null,
    selectedItem: Item,
  ) => {
    const { children, emptyComponent: EmptyComponent } = this.props;

    if (children && Array.isArray(children) && children.length !== 0) {
      const childrenArray = React.Children.toArray(children) as ReactComponentElement<typeof ResultsItem>[];

      // add the invisible value of what the user typed
      childrenArray.unshift(
        <ResultsItem key="_userInputtedValue" item={null} className={styles.ItemUserInputtedValue}>
          <span>{this.userInputtedValue}</span>
        </ResultsItem>,
      );
      this.setResults(childrenArray);

      return (
        <ul className={styles.Results}>
          {childrenArray.map((child, index) => {
            const isHighlighted = highlightedIndex === index;
            const isSelected = selectedItem === child.props.item;

            return (
              <li
                key={child.key}
                role="button"
                {...getItemProps({
                  index,
                  item: Object.prototype.hasOwnProperty.call(child.props, 'item') ? child.props.item : child.key,
                })}
                className={cx(
                  styles.Item,
                  child.props.className,
                  {
                    [styles.Highlighted]: isHighlighted,
                    // [styles.Selected]: isSelected,
                  },
                  isSelected && child.props.classes && child.props.classes.selected,
                  isHighlighted && child.props.classes && child.props.classes.highlighted,
                )}
              >
                {child}
              </li>
            );
          })}
        </ul>
      );
    }
    if (this.userInputtedValue && EmptyComponent) {
      return (
        <ul className={styles.Results}>
          <li className={styles.EmptyList}>
            <EmptyComponent />
          </li>
        </ul>
      );
    }
    return null;
  };

  renderDownshift: ChildrenFunction = ({
    // prop getters
    getInputProps,
    getItemProps,
    // actions
    openMenu,
    // state
    highlightedIndex,
    selectedItem,
    isOpen,
    inputValue,
  }) => {
    const { inputProps } = this.props;

    const downshiftInputProps = getInputProps({
      value: inputValue || '',
      onFocus: () => openMenu(),
      ...inputProps,
    });
    return (
      <div className={styles.InputAutocomplete}>
        <Input {...downshiftInputProps} />
        {!isOpen ? null : this.renderResults(inputValue, getItemProps, highlightedIndex, selectedItem)}
      </div>
    );
  };

  render() {
    const { inputProps, onChange, children, emptyComponent: EmptyComponent, ...props } = this.props;

    return (
      <Downshift
        {...props}
        defaultHighlightedIndex={0}
        inputValue={this.state.inputValue}
        render={this.renderDownshift}
        stateReducer={this.downshiftStateReducer}
        itemToString={this.handleItemToString}
        onChange={this.handleChange}
        onUserAction={this.onUserAction}
        onInputValueChange={this.handleInputValueChange}
      />
    );
  }
}
