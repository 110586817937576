import React, { ReactNode, useContext, ReactElement } from 'react';
import Button from '../Button';
import styles from './styles.module.css';
import { OnCloseContext } from '.';

export interface HeaderProps {
  children: NonNullable<ReactNode>;
  left?: ReactNode;
  right?: ReactNode;
}

export default function Header({ left, right, children }: HeaderProps): ReactElement {
  const onClose = useContext(OnCloseContext);

  return (
    <div className={styles.Header}>
      {left && <div className={styles.Left}>{left}</div>}
      <div className={left ? undefined : styles.Title}>{children}</div>
      {right !== undefined ? (
        right
      ) : (
        <div className={styles.Right}>
          <Button icon="cross" onClick={onClose} />
        </div>
      )}
    </div>
  );
}
