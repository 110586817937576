import React from 'react';
import styles from './styles.module.css';

export interface MinifiedStepsProps {
  progress?: number;
  total: number;
}

export default function MinifiedSteps({ progress = 1, total }: MinifiedStepsProps) {
  return (
    <div className={styles.Minified}>
      <div className={styles.Fill} style={{ width: `${progress * (100 / (total + 1))}%` }} />
    </div>
  );
}
