import { useEffect, useRef } from 'react';

export default function useEscapeKey(onClose: () => void, visible: boolean): void {
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;
  useEffect(() => {
    if (visible) {
      const handleKeyEvent = ({ key }: KeyboardEvent): void => {
        if (key === 'Escape') {
          onCloseRef.current();
        }
      };
      window.addEventListener('keydown', handleKeyEvent);
      return (): void => {
        window.removeEventListener('keydown', handleKeyEvent);
      };
    }
    return undefined;
  }, [visible]);
}
