import React, { useCallback, useState } from "react";

import Question from "./wrapper";
import Validate from "./validate";
import useFormField from "../../hooks/useFormField";
import texts from "../../configs/texts";
import {
  Label,
  InputText,
  InputFeedback,
  Checkbox,
  LargeLoader,
} from "@ornikar/kitt";
import { validEmail, validPhone, fixPhone } from "../../types/answers";
import useServerSideContactValidation from "../../hooks/useServerSideContactValidation";
import styles from "../../styles/fake-css-modules";

const Qemail = ({ answer, draft, confirmation, longquote, ...props }) => {
  const [contactOptin, setContactOptin] = useState(draft.contactOptin || false);
  const [email, emailProps, emailState] = useFormField(draft.email, validEmail);
  const [phone, phoneProps, phoneState] = useFormField(
    draft.phone,
    validPhone,
    fixPhone
  );
  let emailErrorMessage =
    emailState === "invalid" ? texts.email_phone.invalid_email : null;
  let phoneErrorMessage =
    phoneState === "invalid" ? texts.email_phone.invalid_phone : null;

  const [doSSValidate, ssValidation] = useServerSideContactValidation([
    email,
    phone,
  ]);

  if (ssValidation.result && !ssValidation.result.accept) {
    if (
      ssValidation.result.email.is_disposable_address &&
      ssValidation.result.email.is_disposable_address != "false"
    ) {
      emailErrorMessage = texts.email_phone.email_disposable;
    } else if (ssValidation.result.email.did_you_mean) {
      emailErrorMessage = texts.email_phone.email_did_you_mean.replace(
        "{did_you_mean}",
        ssValidation.result.email.did_you_mean
      );
    } else if (ssValidation.result.email.risk !== "low") {
      emailErrorMessage = texts.email_phone.invalid_email;
    }

    if (ssValidation.result.phone.status !== "DELIVERED") {
      phoneErrorMessage = texts.email_phone.invalid_phone;
    }
  }
  const csValid = emailState === "valid" && phoneState === "valid";

  const next = useCallback(() => {
    if (!csValid) return; // do nothing;
    doSSValidate().then((valid) => {
      if (valid) answer({ email, phone, contactOptin });
    });
  }, [answer, doSSValidate, contactOptin, csValid]);

  return (
    <Question
      {...props}
      title={
        longquote
          ? confirmation
            ? texts.email_phone.question_confirmation
            : texts.email_phone.question_longquote
          : texts.email_phone.question
      }
    >
      <div className={styles.DefaultStyling}>
        <Label htmlFor="email">{texts.email_phone.email}</Label>
        <InputText
          id="email"
          name="email"
          type="email"
          placeholder={texts.email_phone.placeholder_email}
          autoComplete="email"
          onKeyPress={(e) => e.key === "Enter" && next()}
          {...emailProps}
        />
        {emailErrorMessage && (
          <InputFeedback state="invalid">{emailErrorMessage}</InputFeedback>
        )}
      </div>
      <div className={styles.DefaultStyling}>
        <Label
          htmlFor="phone"
          dangerouslySetInnerHTML={{ __html: texts.email_phone.phone }}
        />
        <InputText
          id="phone"
          name="phone"
          type="number"
          placeholder={texts.email_phone.placeholder_phone}
          autoComplete="tel-national"
          onKeyPress={(e) => e.key === "Enter" && next()}
          {...phoneProps}
        />
        {phoneErrorMessage ? (
          <InputFeedback state="invalid">{phoneErrorMessage}</InputFeedback>
        ) : longquote ? null : (
          <InputFeedback>{texts.email_phone.phone_details}</InputFeedback>
        )}
      </div>
      {longquote ? null : (
        <div className={styles.DefaultStyling}>
          <Label htmlFor="contactOptin">{texts.email_phone.contactOptin}</Label>
          <Checkbox
            id="contactOptin"
            name="contactOptin"
            checked={contactOptin}
            onChange={useCallback(
              (e) => {
                setContactOptin(e.target.checked);
              },
              [setContactOptin]
            )}
          >
            {texts.email_phone.optin}
          </Checkbox>
        </div>
      )}

      {ssValidation.loading ? (
        <LargeLoader />
      ) : (
        <Validate id="btn-validate-email" disabled={!csValid} onClick={next} />
      )}
    </Question>
  );
};

export default Qemail;
