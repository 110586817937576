import cx from 'classnames';
import React, { ComponentProps, ReactComponentElement, ReactElement, ReactNode } from 'react';
import styles from './styles.module.css';

type ContentAlign = 'left' | 'right' | 'center';

const getContentAlignment = (align?: ContentAlign) =>
  cx({
    [styles.Left]: align && align === 'left',
    [styles.Right]: align && align === 'right',
    [styles.Center]: align && align === 'center',
  });

interface ThCellProps extends ComponentProps<'th'> {
  head?: true;
  children?: ReactNode;
  align?: ContentAlign;
}

interface TdCellProps extends ComponentProps<'td'> {
  head?: false;
  children?: ReactNode;
  align?: ContentAlign;
}

export type CellProps<Head extends boolean> = Head extends true ? ThCellProps : TdCellProps;

function Cell<Head extends boolean>({ head, children, align, ...props }: CellProps<Head>) {
  const As = head ? 'th' : 'td';
  return (
    <As className={getContentAlignment(align)} {...props}>
      {children}
    </As>
  );
}

export interface RowProps {
  children?: ReactComponentElement<typeof Cell> | ReactComponentElement<typeof Cell>[];
  align?: ContentAlign;
}

function Row({ children, align }: RowProps) {
  return <tr className={getContentAlignment(align)}>{children}</tr>;
}

interface Headers {
  [key: string]: ThCellProps['children'];
}

/**
 * Render all the Table's td
 */
const renderHeader = (headers: Headers) =>
  Object.keys(headers).map((header) => (
    <Cell key={header} head>
      {headers[header]}
    </Cell>
  ));

export interface TableProps {
  fixed?: boolean;
  showHeader?: boolean;
  header?: Headers;
  headerAlign?: ContentAlign;
  children?: ReactComponentElement<typeof Row> | ReactComponentElement<typeof Row>[];
}

export default function Table({
  fixed,
  header,
  children,
  showHeader = true,
  headerAlign = 'left',
}: TableProps): ReactElement {
  return (
    <div className={styles.TableContainer}>
      <table
        className={cx(styles.Table, {
          [styles.Fixed]: fixed,
        })}
      >
        {showHeader && header && !!Object.keys(header).length && (
          <thead>
            <Row align={headerAlign}>{renderHeader(header)}</Row>
          </thead>
        )}
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}

Table.Row = Row;
Table.Cell = Cell;
