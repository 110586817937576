import React from "react";

import imgArgus from "../../assets/press/argus.png";
import imgFurets from "../../assets/press/lesfurets.png";
import { Typography } from "@ornikar/kitt";

const Media = () => (
  <section className="MediaReassurance-10eoWQ4bFtZg58xnkZSmGe">
    <div className="Carousel-1PVTWMaq783WNjlL_tZAf6">
      <input
        type="radio"
        className="Radio-3n5yU7I1fe3FTBfHgZr13z"
        name="carousel"
        id="OuestFranceInput"
      />
      <label
        htmlFor="OuestFranceInput"
        className="ReassuranceItem-1ArNnIfWIJ6EeQfzjC8dyh OuestFranceItem-qljbEcCb91gVjSWybUkdQ"
      >
        <div className="ReassuranceText-2SCfFDzN57-2N6TxBxKbR4">
          <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-header4__142rQ  styles-types-module_medium-header4__2YZKi styles-types-module_large-header5__1hBEO ">
            “Evaluant le profil de risque du futur conducteur{" "}
            <span className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz     ">
              dès la phase d'apprentissage.
            </span>
            ”
          </div>
        </div>
        <img src={imgArgus} style={{ width: "200px", maxWidth: "90%" }} />
      </label>
      <input
        type="radio"
        className="Radio-3n5yU7I1fe3FTBfHgZr13z"
        name="carousel"
        id="France3Input"
      />
      <label
        htmlFor="France3Input"
        className="ReassuranceItem-1ArNnIfWIJ6EeQfzjC8dyh France3Item-BzCJauMiIFoJB_LJaoGA1"
      >
        <div className="ReassuranceText-2SCfFDzN57-2N6TxBxKbR4">
          <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header2__1bOT8  styles-types-module_medium-header3__3ZkRt  ">
            “Ornikar est leader en&nbsp;France.”
          </div>
        </div>
        <img
          className="France3Svg-aDTBRgGouPdzy30rFj-oJ"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNjQuNTgzIDIyNi4wMzgiPjxwYXRoIGQ9Ik04NC4zNDIgMTQ1LjY1M2MwIDIzLjI4OS0xOC44ODIgNDIuMTctNDIuMTcgNDIuMTdDMTguODc3IDE4Ny44MjQgMCAxNjguOTQzIDAgMTQ1LjY1NGMwLTIzLjI5MyAxOC44NzgtNDIuMTcxIDQyLjE3MS00Mi4xNzEgMjMuMjg5IDAgNDIuMTcxIDE4Ljg3OCA0Mi4xNzEgNDIuMTciIGZpbGw9IiMwMDgyZTYiLz48cGF0aCBkPSJNMjEzLjAxIDc1LjEzNmw0MC40MzQtNDkuNjY0VjBIMTE4LjEzN3YzOC4yMDZoNzIuODE1TDE1MC45MyA4NS41NnYyNy40NjNjOC4yNzgtNC4xNDEgMTcuODI3LTYuMzY5IDI2LjczOS02LjM2OSAyNC44MzQgMCA0My45MzcgMTcuNTA5IDQzLjkzNyA0MC43NDggMCAyMy44NzgtMjAuNjkzIDQwLjQzLTQxLjcxIDQwLjQzLTE2Ljg3IDAtMzIuMTUtOS4yMjYtNDEuMzg1LTIyLjI4M2wtMzAuODggMjUuNzg2YzE5Ljc0IDIyLjI4OCA0Ni4xNjQgMzQuNzAzIDczLjg2IDM0LjcwMyA0Ni4xNjUgMCA4My4wOTEtMzEuNTE4IDgzLjA5MS03OC42MzYgMC0zMy43NDUtMjEuMDEyLTYwLjQ4OS01MS41NzMtNzIuMjY2IiBmaWxsPSIjMjMyMzIzIi8+PC9zdmc+"
        />
      </label>
      <input
        type="radio"
        className="Radio-3n5yU7I1fe3FTBfHgZr13z"
        name="carousel"
        defaultChecked
        id="BfmInput"
      />
      <label
        htmlFor="BfmInput"
        className="ReassuranceItem-1ArNnIfWIJ6EeQfzjC8dyh BfmItem-JgvG06LaBAqMQeGqh5b8A"
      >
        <div className="ReassuranceText-2SCfFDzN57-2N6TxBxKbR4">
          <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-header4__142rQ  styles-types-module_medium-header4__2YZKi styles-types-module_large-header5__1hBEO ">
            “Ornikar s’attaque désormais à la{" "}
            <Typography.span variant="bold" color="black">
              vente d’assurances pour les jeunes conducteurs
            </Typography.span>
            ”
          </div>
        </div>
        <img src={imgFurets} style={{ width: "200px", maxWidth: "90%" }} />
      </label>
    </div>
  </section>
);

export default Media;
