import FadeIn from './FadeIn';
import SlideIn from './SlideIn';
import ZoomIn from './ZoomIn';
import Rotate from './Rotate';

const Transitions = {
  FadeIn,
  SlideIn,
  ZoomIn,
  Rotate,
};

export default Transitions;
