import cx from 'classnames';
import React, { ReactNode } from 'react';
import styles from './styles.module.css';

export type Position = 'bottom' | 'top' | 'left' | 'right';

export interface TooltipProps {
  children: NonNullable<ReactNode>;
  content: NonNullable<ReactNode>;
  defaultVisible?: boolean;
  fullWidth?: boolean;
  position: Position;
}

export default function Tooltip({ children, position = 'top', content, defaultVisible, fullWidth }: TooltipProps) {
  return (
    <span className={styles.Container}>
      <div
        className={cx(styles.Tooltip, {
          [styles.Visible]: defaultVisible,
          [styles.Bottom]: position === 'bottom',
          [styles.Top]: position === 'top',
          [styles.Left]: position === 'left',
          [styles.Right]: position === 'right',
          [styles.FullWidth]: fullWidth,
        })}
      >
        <div className={styles.Content}>{content}</div>
      </div>
      {children}
    </span>
  );
}
