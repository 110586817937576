import React, { ChangeEvent, FocusEvent } from 'react';
import { handleNativeInputEvent } from 'utils/inputHandlers';
import InputText, { InputTextProps } from 'components/InputText';
import InputDate, { InputDateProps } from '../InputDate';
import InputPassword, { InputPasswordProps } from '../InputPassword';

interface BaseInputProps<T, V> {
  name: string;
  id?: string;
  type?: 'date' | 'password' | string;
  onFocus?: T extends never ? () => void : (e: FocusEvent<T>) => void;
  onBlur?: T extends never ? () => void : (e: FocusEvent<T>) => void;
  onChange: T extends never ? (value: V) => void : (value: V, e: ChangeEvent<T>) => void;
}

interface BaseInputDateProps extends BaseInputProps<never, string> {
  type: 'date';
}
interface BaseInputPasswordProps extends BaseInputProps<HTMLInputElement, string> {
  type: 'password';
}
interface BaseInputTextProps extends BaseInputProps<HTMLInputElement, string> {
  type?: string;
}

export type InputProps =
  | (BaseInputDateProps & InputDateProps)
  | (BaseInputPasswordProps & InputPasswordProps)
  | (BaseInputTextProps & InputTextProps);

const Input = React.forwardRef(function InputWithForwardRef(
  { name, type, onChange, onFocus, onBlur, ...props }: InputProps,
  ref,
) {
  switch (type) {
    case 'date':
      return (
        <InputDate
          onChange={onChange as BaseInputDateProps['onChange']}
          onFocus={onFocus as BaseInputDateProps['onFocus']}
          onBlur={onBlur as BaseInputDateProps['onBlur']}
          {...props}
        />
      );
    case 'password':
      return (
        <InputPassword
          name={name}
          onChange={handleNativeInputEvent(onChange)}
          onFocus={onFocus}
          onBlur={onBlur}
          {...props}
        />
      );
    default:
      return (
        <InputText
          ref={ref}
          type={type}
          name={name}
          onChange={handleNativeInputEvent(onChange)}
          onFocus={onFocus}
          onBlur={onBlur}
          {...props}
        />
      );
  }
});

export default Input;
