import React, { ReactElement } from 'react';
import Media from 'react-media';
import Card, { CardProps } from '../Card';
import Map, { MapProps } from '../Map';
import { MagnifyButtonProps } from '../Map/MagnifyButton';
import styles from './styles.module.css';

const mapMaxWidth = 460;

const defaultHeight: MapCardHeight = {
  desktop: 225,
  mobile: 120,
};

const getCenterMapStyle = ({ desktop, mobile }: MapCardHeight, isDesktop: boolean) => ({
  height: `${isDesktop ? desktop : mobile}px`,
});

const getContentStyle = ({ desktop, mobile }: MapCardHeight, isDesktop: boolean) => ({
  height: `calc(100% - ${isDesktop ? desktop : mobile}px)`,
});

const getMapStyle = ({ desktop, mobile }: MapCardHeight, isDesktop: boolean) => ({
  top: `calc(50% - ${(isDesktop ? desktop : mobile) * 0.5}px)`,
});

export interface MapCardHeight {
  desktop: MapProps['height'];
  mobile: MapProps['height'];
}

export interface MapCardProps {
  children: CardProps['children'];
  center?: MapProps['center'];
  height?: MapCardHeight;
  depth?: CardProps['depth'];
  className?: CardProps['className'];
  onMagnifyMapClick?: MagnifyButtonProps['onClick'];
}

export default function MapCard({
  children,
  center,
  height,
  depth,
  className,
  onMagnifyMapClick,
}: MapCardProps): ReactElement {
  const renderedHeight = height || defaultHeight;
  return (
    <Media query="(min-width: 480px)">
      {(matches) => (
        <Card depth={depth} className={className}>
          <div className={styles.Top}>
            <div className={styles.CenterMap} style={height && getCenterMapStyle(height, matches)}>
              <Map
                center={center}
                marker="small"
                width={mapMaxWidth}
                height={matches ? renderedHeight.desktop : renderedHeight.mobile}
                mapStyle={height && getMapStyle(height, matches)}
              />
            </div>
            {onMagnifyMapClick && <Map.MagnifyButton onClick={onMagnifyMapClick} />}
          </div>
          <div className={styles.Content} style={height && getContentStyle(height, matches)}>
            {children}
          </div>
        </Card>
      )}
    </Media>
  );
}
