import { ReactElement, ReactNode } from 'react';

interface ResultsItemClasses {
  selected?: string;
  highlighted?: string;
}

export interface ResultsItemProps<Item = any> {
  children: NonNullable<ReactNode>;
  item: Item;
  className?: string;
  classes?: ResultsItemClasses;
}

export default function ResultsItem({ children }: ResultsItemProps): ReactElement {
  return children as ReactElement; // "as ReactElement" to fix typescript
}
