import React from "react";
import Tooltip from "../landing/Tooltip";
import texts from "../../configs/texts";
import "./CutePrice.scss";

const CutePrice = ({ notooltip, monthlyUnits, monthlyCents }) => {
  return (
    <div
      id="cute-price"
      className={parseInt(monthlyUnits, 10) > 99 ? "more99" : ""}
    >
      <span
        style={{
          fontSize: "0.5em",
          lineHeight: ".1em",
        }}
      >
        €
      </span>
      <span
        style={{
          fontSize: "1.5em",
          fontWeight: 700,
          lineHeight: ".8em",
        }}
      >
        {monthlyUnits}
      </span>
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          marginLeft: ".2em",
          justifyItems: "space-between",
          fontSize: "0.4em",
        }}
      >
        <span
          style={{
            lineHeight: "1em",
          }}
        >
          ,{monthlyCents}
          {notooltip ? (
            <></>
          ) : (
            <span style={{ position: "relative", top: "-.25em" }}>
              <Tooltip>{texts.landing.tooltip_quote} </Tooltip>
            </span>
          )}
        </span>
        <span
          style={{
            lineHeight: "2.6em",
          }}
        >
          /&nbsp;mois
        </span>
      </span>
    </div>
  );
};

export default CutePrice;
