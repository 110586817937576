import React, { ComponentProps } from 'react';
import StyledInputText, { StyledInputTextProps } from './StyledInputText';
import inputStyle from './inputStyle';

type InputProps = ComponentProps<'input'>;

export interface InputTextProps extends Omit<StyledInputTextProps<InputProps>, 'as'> {
  type?: InputProps['type'];
  /** @deprecated Please set width on container, not as a prop of an input */
  width?: never;
}

const InputTextForwardRef = React.forwardRef(function InputText(
  { type = 'text', ...props }: InputTextProps & InputProps,
  ref,
) {
  return <StyledInputText ref={ref} as="input" type={type} {...(props as any)} />;
});

const InputText: typeof InputTextForwardRef & {
  inputStyle: typeof inputStyle;
  StyledInputText: typeof StyledInputText;
} = Object.assign(InputTextForwardRef, { inputStyle, StyledInputText });

export default InputText;
