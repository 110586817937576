import cx from 'classnames';
import React, { ReactNode, Ref, ForwardRefExoticComponent, RefAttributes, ReactElement } from 'react';
import styles from './styles.module.css';

export interface BodyComponent extends ForwardRefExoticComponent<BodyViewProps & RefAttributes<HTMLDivElement>> {
  modalBodySymbol?: {};
}
interface BodyViewProps {
  children: ReactNode;
  className?: string;
}

const Body: BodyComponent = React.forwardRef(function BodyView(
  { children, className }: BodyViewProps,
  ref: Ref<HTMLDivElement>,
): ReactElement {
  return (
    <div ref={ref} className={cx(styles.Body, className)}>
      {children}
    </div>
  );
});

// https://github.com/gaearon/react-hot-loader/issues/304
// Workaround to fix an issue with react-hot-loader and element type comparison
Body.modalBodySymbol = {};

export default Body;
