import React, { useCallback } from "react";

import texts from "../../configs/texts";

import useFormField from "../../hooks/useFormField";
import { InputText, Label, InputFeedback } from "@ornikar/kitt";
import Question from "./wrapper";
import Validate from "./validate";
import { validFName, validLName } from "../../types/answers";
import styles from "../../styles/fake-css-modules";

const Qnames = ({ answer, draft, ...props }) => {
  const [fname, fNameProps, fnameState] = useFormField(draft.fname, validFName);
  const [lname, lNameProps, lnameState] = useFormField(draft.lname, validLName);

  const valid = fnameState === "valid" && lnameState === "valid";
  const next = useCallback(() => {
    if (valid) answer({ fname, lname });
  }, [answer, fname, lname, valid]);

  return (
    <Question {...props} title={texts.names.question} id="Qnames">
      <div className={styles.DefaultStyling}>
        <Label htmlFor="fname">{texts.names.fname}</Label>
        <InputText
          id="fname"
          {...fNameProps}
          autoComplete="given-name"
          placeholder="Prénom"
          onKeyPress={(e) => e.key === "Enter" && next()}
        />
        {fnameState === "invalid" && (
          <InputFeedback state={fnameState}>Valeur requise</InputFeedback>
        )}
      </div>
      <div className={styles.DefaultStyling}>
        <Label htmlFor="lname">{texts.names.lname}</Label>
        <InputText
          id="lname"
          {...lNameProps}
          autoComplete="family-name"
          placeholder="Nom"
          onKeyPress={(e) => e.key === "Enter" && next()}
        />
        {lnameState === "invalid" && (
          <InputFeedback state={lnameState}>Valeur requise</InputFeedback>
        )}
      </div>

      <Validate id="btn-validate-names" disabled={!valid} onClick={next} />
    </Question>
  );
};

export default Qnames;
