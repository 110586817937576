import cx from 'classnames';
import React, { ReactNode, ComponentProps, ChangeEvent, ChangeEventHandler } from 'react';
import styles from './styles.module.css';

const handleChange = (
  event: ChangeEvent<HTMLInputElement>,
  onChange: ChangeEventHandler<HTMLInputElement>,
  onFocus?: () => void,
  onBlur?: () => void,
) => {
  if (onFocus) {
    onFocus();
  }

  onChange(event);

  if (onBlur) {
    onBlur();
  }
};

export interface CheckboxProps extends ComponentProps<'input'> {
  id: string;
  checked?: boolean;
  right?: boolean;
  baseline?: boolean;
  children?: ReactNode;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onFocus?: () => void;
  onBlur?: () => void;
}

export default function Checkbox({
  id,
  children,
  checked,
  right,
  baseline,
  onFocus,
  onChange,
  onBlur,
  ...props
}: CheckboxProps) {
  return (
    <label
      htmlFor={id}
      className={cx(styles.CheckboxContainer, {
        [styles.AlignRight]: right,
        [styles.AlignBaseline]: baseline,
        [styles.Unlabeled]: !children,
      })}
    >
      <input
        id={id}
        type="checkbox"
        tabIndex={0}
        checked={checked}
        onChange={(e) => handleChange(e, onChange, onFocus, onBlur)}
        {...props}
      />
      <div
        className={cx(styles.Checkbox, {
          [styles.CheckboxChecked]: !!checked,
        })}
      >
        <svg viewBox="0 0 28 28">
          <path d="M7.3 15 l4.5 4 l10 -10" strokeWidth="3" strokeMiterlimit="10" />
        </svg>
      </div>
      {children && <div className={styles.Label}>{children}</div>}
    </label>
  );
}
