import React, { useState, useCallback } from "react";
import LogoBlack from "../assets/ornikarassurance-primary.svg";
import contactOrnikar from "../assets/contactornikar.png";
import { Button } from "@ornikar/kitt";
import texts from "../configs/texts";
import tracking from "../configs/tracking";

const Link = ({ burgerMenuOpen, children, ...props }) => (
  <a
    {...props}
    className={
      "styles-module_Link__GVqkR  styles-module_NoUnderline__jvLHR  styles-types-module_Reset__1HOMT " +
      (burgerMenuOpen
        ? "styles-variants-module_black-bold__1t5oz styles-types-module_base-header3__FKPGi  styles-types-module_medium-header3__3ZkRt "
        : "styles-variants-module_black-regular__2_1Sz ") +
      "styles-module_Item__2p_GN"
    }
  >
    {children}
  </a>
);

const Nav = ({ burgerMenuOpen, style, lockIn, className = "", close }) => (
  <nav
    className={
      "styles-module_Nav__IYROW  styles-module_Medium__2v-qB " + className
    }
    style={style}
  >
    {!lockIn && (
      <>
        <Link
          burgerMenuOpen={burgerMenuOpen}
          id="link-header-faq"
          onClick={() => {
            tracking.clickHeaderFAQ();
            close();
          }}
          href={texts.url.faq}
          rel="noopener noreferrer"
          target="_blank"
        >
          FAQ
        </Link>
        <Link
          burgerMenuOpen={burgerMenuOpen}
          href="/#steps"
          id="link-header-how"
          onClick={() => {
            tracking.clickHeaderHow();
            close();
          }}
        >
          Comment ça marche&nbsp;?
        </Link>
      </>
    )}
    <div className="styles-module_Button__2zcg9">
      <Button
        type="subtle"
        stretch
        href="tel:+33755537527"
        id="link-header-contact"
        onClick={() => {
          tracking.clickHeaderContact();
          close();
        }}
      >
        <img
          src={contactOrnikar}
          alt="Contact"
          style={{ height: "1.5em", transform: "scale(1.5)" }}
        />
      </Button>
    </div>
  </nav>
);

const Header = ({ children, lockIn = false }) => {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const open = useCallback(() => setBurgerMenuOpen(true, [setBurgerMenuOpen]));
  const close = useCallback(() => setBurgerMenuOpen(false), [
    setBurgerMenuOpen,
  ]);
  return (
    <header className="styles-module_Header__3jJsx">
      <div className="styles-module_Content__1X_9b">
        <a
          className="styles-module_Link__GVqkR    "
          href="/"
          id="link-header-logo"
          style={{ marginRight: "2em" }}
          onClick={tracking.clickHeaderLogo}
        >
          <LogoBlack className="styles-module_Logo__33vY1  styles-module_Logo__2EP3X " />
        </a>
        <Nav close={close} lockIn={lockIn} />
        <div className="styles-module_BurgerButton__FXQbB">
          {lockIn ? (
            <Button
              type="subtle"
              href="tel:+33755537527"
              id="btn-header-restitution-contact-mobile"
              onClick={tracking.clickHeaderContact}
            >
              <img
                src={contactOrnikar}
                alt="Contact"
                style={{ height: "1.5em", transform: "scale(1.5)" }}
              />
            </Button>
          ) : burgerMenuOpen ? (
            <Button icon="cross" onClick={close} />
          ) : (
            <Button
              icon="menu"
              type="primary"
              onClick={open}
              id="btn-header-burger"
            />
          )}
        </div>
      </div>
      {children}
      {burgerMenuOpen && (
        <Nav
          close={close}
          className="bmenuNav"
          lockIn={lockIn}
          burgerMenuOpen={burgerMenuOpen}
        />
      )}
    </header>
  );
};

export default Header;
