import React, { ReactElement, Ref } from 'react';
import LinkButton from './LinkButton';
import type { LinkButtonProps } from './LinkButton';
import RealButton from './RealButton';
import type { RealButtonProps } from './RealButton';
import DivButton from './DivButton';
import type { DivButtonProps } from './DivButton';

export { LinkButton, RealButton, DivButton };
export type { LinkButtonProps, RealButtonProps, DivButtonProps };

export type ButtonProps = LinkButtonProps | RealButtonProps | DivButtonProps;

/**
 * Render a link, a div or a button with the same styling
 */
export default React.forwardRef(function Button(
  props: ButtonProps,
  ref: Ref<HTMLAnchorElement | HTMLLinkElement | HTMLDivElement>,
): ReactElement {
  if (props.href != null || props.to !== undefined) {
    return <LinkButton ref={(ref as unknown) as Ref<HTMLAnchorElement>} {...(props as LinkButtonProps)} />;
  }

  if (__DEV__) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((props as any).type === 'link') {
      throw new Error('type=link is only allowed with href or to prop');
    }
  }

  if (props.submit || props.reset) {
    return <RealButton ref={(ref as unknown) as Ref<HTMLButtonElement>} {...(props as RealButtonProps)} />;
  }

  return <DivButton ref={(ref as unknown) as Ref<HTMLDivElement>} {...(props as DivButtonProps)} />;
});
