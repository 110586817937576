import React, { ReactElement } from 'react';
import DivButton, { DivButtonProps } from 'components/Button/DivButton';
import styles from './styles.module.css';

export interface MagnifyButtonProps {
  onClick: NonNullable<DivButtonProps['onClick']>;
}

export default function MagnifyButton({ onClick }: MagnifyButtonProps): ReactElement {
  return (
    <div className={styles.Magnify}>
      <DivButton type="primary" icon="maximize" onClick={onClick} />
    </div>
  );
}
