import cx from 'classnames';
import React, { ReactElement } from 'react';
import Dropdown, { DropdownProps, DropdownItemProps } from '../Dropdown';
import Link, { LinkProps, LinkImplementationRequiredProps, DefaultLinkImplementationProps } from '../Link';
import styles from './styles.module.css';

export type DropdownMenuProps<Values extends string = string> = DropdownProps<Values>;

export default function DropdownMenu<Values extends string>(props: DropdownMenuProps<Values>): ReactElement {
  return <Dropdown large {...props} />;
}

export type DropdownMenuItemProps<P extends LinkImplementationRequiredProps> = LinkProps<P> & {
  onClick?: DropdownItemProps['onClick'];
  value?: DropdownItemProps['value'];
};

function DropdownMenuItem<P extends LinkImplementationRequiredProps = DefaultLinkImplementationProps>(
  props: DropdownMenuItemProps<P>,
): ReactElement {
  const { className, onClick, ...linkProps } = props;
  return (
    <Dropdown.Item className={cx(styles.Item, className)} onClick={onClick}>
      <Link noBold {...linkProps} />
    </Dropdown.Item>
  );
}

DropdownMenu.Item = DropdownMenuItem;
