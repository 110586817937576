import React from "react";
import { Icon } from "@ornikar/kitt";

const Tooltip = ({ children }) => (
  <span className="InfoTooltip-Wcd8P4M3JyRkjNus9j-8e">
    <span className="InfoTooltipContainer-2lPWA5Crh5ubMxKZWiR0VL">
      <span className="styles-module_Container__1HgBd">
        <div className="styles-module_Tooltip__QG3wy   styles-module_Top__3X7Oy  ">
          <div className="styles-module_Content__Im8-k">{children}</div>
        </div>
        <Icon name="info" color="#B0BEC5" />
      </span>
    </span>
  </span>
);

export default Tooltip;
