const professions = {
    Salarié: "Salarié(e)",
    SalariéNonSédentaire: "Salarié(e) Non Sédentaire",
    Fonctionnaire: "Fonctionnaire",
    FonctionnaireNonSédentaire: "Fonctionnaire Non Sédentaire",
    Retraité: "Retraité(e)",
    SansProfession: "Sans Profession",
    ProfessionLibérale: "Profession Libérale",
    ProfessionLibéraleNonSédentaire: "Profession Libérale Non Sédentaire",
    Artisan: "Artisan(e)",
    Commercant: "Commercant(e)",
    Agriculteur: "Agriculteur(trice)",
    Etudiant: "Etudiant(e)",
    Ecclésiastique: "Ecclésiastique",
    VRP: "VRP",
};

const situation_familiale = {
    Marié: "Marié(e)",
    Concubin: "Concubin(e)",
    Célibataire: "Célibataire",
    Séparé: "Séparé(e)",
    Divorcé: "Divorcé(e)",
    Veuf: "Veuf(ve)",
    Pacsé: "Pacsé(e)",
};

const anciennete_assurance = {
    No: "Non, jamais assuré(e)",
    Less1year: "Oui, assuré(e) moins de 12 mois",
    Continu12: "Oui, assuré(e) sans interruption sur les 12 derniers mois",
    Continu24: "Oui, assuré(e) sans interruption sur les 24 derniers mois",
    Continu36:
        "Oui, assuré(e) sans interruption sur les 36 derniers mois (et plus)",
    Interrompu: "Oui, assuré(e) avec interruption sur les 36 derniers mois",
};

export default {
    url: {
        root: "/",
        quote_form: "#quote",
        faq: "https://faq.ornikar.com/fr/collections/2437454-ornikar-assurance",
        learn_more:
            "https://faq.ornikar.com/fr/articles/4230777-comment-changer-d-assurance-auto-dans-le-cadre-de-la-loi-consommation-loi-hamon",
        cgv_mention_legales: "https://ornikar.com/a-propos/mentions-legales",
        comment_ca_marche: "/#steps",
        facebook: "https://www.facebook.com/Ornikar-Assurance-105187634589763",
    },
    landing: {
        no_excuses:
            "Vous n'avez plus d'excuses pour continuer à payer trop cher&nbsp;!",
        cta: "J'obtiens mon devis",
        cta_restitution: "J'effectue mon devis",
        clear_all: "Tout effacer",
        age: "Age",
        cp: "Code postal",
        situation: "Votre situation",
        vehicule: "Votre véhicule",
        a_partir_29: { __html: "A partir de <span>29&nbsp;€/mois</span> " },
        seulement: "seulement",
        arretez_trop_cher: "Arrêtez de payer trop cher",
        tooltip_price: "Prix constaté pour une formule au tiers",
        tooltip_quote:
            "Formule de base, vous pourrez ajouter des options en fonction de vos besoins.",
        half: "50% moins cher",
        tooltip_half: "Selon notre étude",
        simple_et_pas_chere: {
            __html: "<strong>simple</strong> et <strong>pas chère</strong>",
        },
        switching_words: ["pas chère", "simple", "transparente"],
        jeune_conducteur: "jeune conducteur",
        premiere_assurance: "La 1ère assurance auto",
        enfin: "Enfin, un assureur qui vous fait confiance",
        vous_payez: "Vous payez toujours le ",
        meilleur_prix: "Meilleur prix",
        vous_pouvez: "Vous pouvez choisir ",
        toutes_garanties: "toutes vos garanties",
        vous_etes_accompagnes: "Vous êtes accompagné ",
        a_z: "de A-Z",
        step: "Étape",
        fourParts: [
            {
                icon: "check-circle",
                title: "Un tarif imbattable",
                content:
                    "Nous souhaitons vous apporter une solution dans un secteur où l’assurance est très couteuse pour les jeunes conducteurs.",
            },
            {
                icon: "life-buoy",
                title: "Un contrat personnalisé",
                content:
                    "Vous prenez le volant pour la première fois ? Il est nécessaire que vous ayez des garanties qui vous correspondent.",
            },
            {
                icon: "thumbs-up",
                title: "100% sécurisée",
                content:
                    "Vos informations sont complètement personnelles ! Ni stockées, ni réutilisées.",
            },
            {
                icon: "calendar",
                title: "Sans frais cachés",
                content:
                    "Pas comme chez les autres assureurs, Vous payez que pour ce que vous souhaitez.",
            },
        ],
        on_soccupe_resiliation: "On s’occupe de la résilliation de votre contrat",
        savoir_plus: "En savoir plus",
        deja_assure: { __html: "Déjà assuré&nbsp;? Vous payez trop cher ?" },
    },
    restitution: {
        coupon_label: "Code Promo",
        coupon_link: { __html: "J'ai un code promo&nbsp;! " },
        coupon_error: "Ce code n'existe pas ou est périmé",
    },
    commons: {
        yes: "Oui",
        no: "Non",
        validate: "Valider",
        unknown: "Je ne sais pas",
    },
    already: {
        question: "Avez-vous déjà été assuré ?",
    },
    still: {
        question: "Êtes-vous encore assuré ?",
        answers: {
            yes_less: "Oui, depuis <strong>moins d'un an</strong>",
            yes_more: "Oui, depuis <strong>plus d'un an</strong>",
            no: "Non",
        },
    },
    currentAssu: {
        question: "Qui est votre assureur actuel ?",
        placeholder: "Tapez le nom",
        emptyLabel: "Marque inconnue",
    },
    currentPrice: {
        question: "Combien payez-vous actuellement ?",
        less: " ou moins",
        more: " et plus",
    },
    current: {
        question: "Votre assureur actuel",
        currentAssu: "Qui est votre assureur ?",
        currentPrice: "Vous payez combien ?",
        currentAssuPlaceholder: "Choisissez dans la liste",
        unknown: "Je ne sais pas",
    },
    brand: {
        question: "Quelle est la marque de votre véhicule&nbsp;?",
        placeholder: "Tapez la marque",
        nope: "Vous ne trouvez pas la marque de votre véhicule ?",
        emptyLabel: "Marque inconnue",
    },
    model: {
        question: "Quel est le modèle de votre véhicule ?",
        placeholder: "Tapez le modèle",
        nope: "Vous ne trouvez pas votre modèle ?",
        emptyLabel: "Modèle inconnu",
    },
    subscription: {
        question: "Quelle est la date de souscription de votre contrat actuel ?",
    },
    whyresilie: {
        question: "Pour quelle(s) raison(s) votre contrat a-t-il été résilié ?",
        answers: {
            sinistres: "A la suite de plusieurs sinistres",
            false: "A la suite de fausses déclarations",
            nopayment: "Non paiement de prime",
            none: "Aucun",
        },
    },
    sinistres: {
        question:
            "Combien de sinistres avez-vous eu au cours de ses 3 dernières années ? ",
        answers: ["Collision/accident", "Bris de glace", "En stationnement", "Vol"],
    },
    when: {
        question: "Quand souhaitez-vous être assuré ?",
        answers: {
            today: "Aujourd’hui !",
            week: "Cette semaine",
            month: "Ce mois-ci",
            later: "Dans plus d’un mois",
        },
    },
    licensewhen: {
        question: "Quelle est votre date de passage du permis&nbsp;?",
        unknown: "Je ne sais pas encore",
    },
    license: {
        question: "Avez-vous déjà obtenu le permis ?",
    },
    alreadycar: {
        question: "Avez-vous déjà le véhicule à assurer ?",
    },
    malus: {
        question: "Quel est votre niveau de bonus / malus actuel ?",
        emptyLabel: "Indiquez le malus sous la forme 0.90",
    },
    dob: {
        question: "Quel est votre date de naissance ?",
    },
    age: {
        question: "Quel âge avez-vous ?",
        placeholder: "19",
        invalid: "Indiquez votre âge en chiffres.",
    },
    cp: {
        question: "Quel est votre code postal ?",
        placeholder: "00000",
        invalid: "Code postal incorrect",
    },
    email_phone: {
        question: "Quelles sont vos <strong>VRAIES</strong> coordonnées ?",
        question_longquote: "Quelles sont vos coordonnées ?",
        question_confirmation: "Vérifiez vos coordonnées pour la signature électronique de votre contrat.",
        email: "Votre adresse email :",
        placeholder_email: "Entrez votre adresse email",
        phone: "Votre téléphone :",
        placeholder_phone: "Entrez votre téléphone",
        invalid_email: "Email invalide",
        invalid_phone: "Téléphone invalide",
        email_disposable: "Email jetable non-accepté",
        email_did_you_mean: "Email invalide. Vouliez-vous dire {did_you_mean} ?",
        phone_details: "Celui-ci nous permettra de vous envoyer votre estimation.",
        optin: `J'accepte de recevoir des offres commerciales de la
      part d'Ornikar et de ses partenaires commerciaux.`,
    },
    names: {
        question: "Quel est votre nom ?",
        fname: "Votre prénom :",
        lname: "Votre nom :",
    },
    energie: {
        question: "Quel est le carburant de votre véhicule ?",
    },
    puissance_fiscale: {
        question: "Quelle est la puissance fiscale ?",
        help: "C'est le champ 'P.6' sur la carte grise.",
    },
    date_mise_circulation: {
        question: "Quelle est la date de mise en circulation ?",
        help: "C'est le champ 'B' sur la carte grise.",
        notAfterAcquisition: "La date de mise en circulation ne peut être après la date d'acquisition.",
        notBefore20: "Nous ne pouvons pas assurer les véhicules de plus de 20ans."
    },
    code_auto: {
        question: "Quelle est la version de votre véhicule ?",
        help: `
      Vous pouvez trouver cette information sur le bon de commande ou le champ "D.2.1" de la carte grise
      `,
    },
    acquisition: {
        question: "Comment allez-vous acquérir votre véhicule ?",
        possede: "Je possède déjà le véhicule",
        neuf: "Achat neuf",
        occasion: "Achat d'occasion",
    },
    date_acquisition: {
        question: "Quand allez-vous acheté votre véhicule ?",
        question_past: "Quand avez-vous acheté votre véhicule ?",
        notBeforeMEC: "La date d'acquisition ne peut être avant la date de mise en circulation"
    },
    deja_assure: {
        question: "Votre véhicule est-il déjà assuré ?",
    },
    financement: {
        question: "Comment allez-vous financé votre véhicule ?",
        question_past: "Comment avez-vous financé votre véhicule ?",
        comptant: "Achat comptant",
        credit: "Achat à crédit",
        LLD: "Financement par LLD",
        LOA: "Financement par LOA",
    },
    usage: {
        question: "Quel sera l'usage de votre véhicule ?",
        question_past: "Quel est l'usage du véhicule ?",
        prive: "Privé",
        trajets: "Privé et trajets travail",
        affaires: "Privés et affaires",
        tous: "Tous, visite clientèle",
        help: `Indiquez "affaires" si vous utilisez occasionellement le véhicule pour des déplacements professionels.
    Indiquez "visite clientèle" si vous utilisez régulièrement le véhicule pour aller d'un client à un autre.
    `,
    },
    usage_restitution: {
        prive: "Usage privé",
        trajets: "Trajets travail",
        affaires: "Affaires",
        tous: "Tous usages",
    },
    parking: {
        question: "Où allez-vous garer le véhicule ?",
        question_past: "Où garez-vous le véhicule ?",
        public: "Dans la rue ou parking public",
        parking: "Terrain ou parking collectif privé clos",
        garage: "Garage, box privé clos",
    },
    parking_restitution: {
        public: "Parking public",
        parking: "Parking privé",
        garage: "Garage privé",
    },
    parking_codepostal: {
        question: "Indiquez le code postal de la ville où sera garé le véhicule.",
        question_past:
            "Indiquez le code postal de la ville où est garé le véhicule.",
        unknown: "Ce code postal n'existe pas",
    },
    parking_commune: {
        question: "Indiquez la commune où sera garé le véhicule.",
        question_past: "Indiquez la commune où est garé le véhicule.",
    },
    kilometrage: {
        question: "Combien de kilomètres ferez-vous chaque année ?",
        question_past: "Combien de kilomètres faites vous chaque année ?",
        help: "La moyenne en france est de 15000km / an",
    },
    conducteur_principal: {
        question: "Qui sera le conducteur principal ?",
        question_past: "Qui est le conducteur principal ?",
        Vous: "Vous seulement",
        VousEtConjoint: "Vous et votre conjoint",
    },
    titulaire_carte_grise: {
        question: "Qui sera le titulaire de la carte grise ?",
        question_past: "Qui est le titulaire de la carte grise ?",
        Souscripteur: "Vous",
        SouscripteurOuConjoint: "Votre conjoint",
    },
    conduite_exclusive: {
        question: "Prêterez-vous votre véhicule ?",
        question_past: "Prêtez-vous votre véhicule ?",
        Oui: "Non",
        Non: "Oui",
    },
    principal_sexe: {
        question: "Quel est votre sexe ?",
    },
    secondary_sexe: {
        question: "Quel est le sexe de votre conjoint ?",
    },
    birthdate: {
        minimum18: "Minimum 18ans.",
        maximum99: "Maximum 99ans."
    },
    principal_birthdate: {
        question: "Quelle est votre date de naissance ?",
    },
    secondary_birthdate: {
        question: "Quelle est la date de naissance de votre conjoint ?",
    },
    principal_situation_familiale: {
        question: "Quelle est votre situation familiale ?",
        ...situation_familiale,
    },
    secondary_situation_familiale: {
        question: "Quelle est la situation familiale de votre conjoint ?",
        ...situation_familiale,
    },
    principal_profession: {
        question: "Quelle est votre profession ?",
        ...professions,
    },
    secondary_profession: {
        question: "Quelle est la profession de votre conjoint ?",
        ...professions,
    },
    obtention: {
        notBefore18: "La date ne peut pas être avans l'âge de 18ans.",
        notTooLate: "La date ne peut pas être plus de 3 mois dans le futur.",
    },
    obtention_permis: {
        help: `Tel qu'indiqué sur votre permis de conduire. Seuls les permis français sont acceptés.`,
    },
    principal_obtention_permis: {
        question: "Quand avez-vous obtenu le permis ?",
    },
    secondary_obtention_permis: {
        question: "Quand votre conjoint a-t-il obtenu le permis ?",
    },
    principal_conduite_accompagnee: {
        question: "Avez-vous fait de la conduite accompagnée ?",
        Yes: "Oui",
        No: "Non",
    },
    secondary_conduite_accompagnee: {
        //@TODO sexe?
        question: "Votre conjoint a-t-il fait de la conduite accompagnée ?",
        Yes: "Oui",
        No: "Non",
    },
    principal_malus: {
        question: "Quel est votre bonus/malus ?",
        help: `Votre bonus-malus figure sur votre relevé d'informations qui peut être accessible sur 
    votre espace client ou vous être fourni par votre assureur actuel sur simple demande. Si vous 
    n'avez jamais été assuré, votre bonus-malus est égal à 1.`,
    },
    secondary_malus: {
        question: "Quel est le bonus/malus de votre conjoint ?",
    },
    principal_anciennete_assurance: {
        question: "Avez-vous été assuré(e) ?",
        ...anciennete_assurance,
    },
    secondary_anciennete_assurance: {
        //@TODO sexe?
        question: "Votre conjoint a-t-il été assuré ?",
        ...anciennete_assurance,
    },
    principal_infractions: {
        question: "De combien d'infractions avez-vous fait l'objet ?",
    },
    secondary_infractions: {
        //@TODO sexe?
        question: "De combien d'infractions votre conjoint a-t-il fait l'objet ?",
    },
    principal_sinistres: {
        question: "Nombre de sinistres ?",
    },
    secondary_sinistres: {
        //@TODO sexe?
        question: "Nombre de sinistres de votre conjoint ?",
    },
    adresse: {
        question: "Quelle est votre addresse postale ?",
        cp: "Code Postal",
        name: "Nom",
        city: "Commune",
        adress1: "Adresse",
        invalid: "maximum 38 charactère, indiquez uniquement la rue, le code postal de parking sera utilisé."
    },
    date_effet_souhaite: {
        question: "Je m'assure",
        notInThePast: "La date ne peut pas être dans le passé.",
        notTooLate: "La date ne peut pas être plus de 3 mois dans le futur.",
        notBeforeAcquisition: "La date ne peut pas être avant l'acquisition."
    },
    immatriculation: {
        question: "Etat du véhicule",
        immatriculation: "Numéro de plaque",
        kilometrage_compteur: "Kilométrage au compteur",
    },
    iban: {
        question: "Quelles sont vos coordonnées bancaire ?",
        help: "Vos données sont transmises à notre partenaire de paiement de manière sécurisée et ne seront pas conservées par Ornikar Assurances .",
    },
    longquote: {
        intro:
            "Prenez le temps de répondre correctement à chaque question pour obtenir un tarif juste et un contrat valide qui vous protégera en cas de pépin.",
    },
    blocks: {
        vehicule: "Votre véhicule",
        usage: "Usage",
        principal_driver: "Conducteur principal",
        secondary_driver: "Conducteur secondaire",
        contract: "Prochain contrat",
        cta: "J'obtiens mon devis",
        immatriculation: "Immatriculation",
        confirm_contact: "Vérification données",
        iban: "Paiement",
        hamon: "Loi HAMON",
        final_cta: 'Confirmation'
    },
    hamon: {
        question: 'Souhaitez-vous profiter de la loi Hamon ?',
        refContrat: "Quelle est votre numéro de contrat actuel ?",
        adresse_question: "Quelle est l'adresse de votre assureur actuel ?"
    },
    customization: {
        title: "Votre devis définitif",
        cta: "Ça m'intéresse !",
        right_title: "Je m'assure pour",
        avec: "avec",
        free: "OFFERT",
        story_title: "Vis ma vie d'assuré",
        taxi_joker: {
            label: "Taxi Joker",
            explication: "Parce qu’on sait que boire ou conduire, il faut choisir. Mais parfois avec un verre dans le nez pas évident de se décider. Une fois par an, un trajet taxi offert jusqu'à 50€.",
            story: "Anniversaire de mon meilleur pote, je lui ai dit. Bon je viens pour un verre pas plus. Résultat, j’ai fini complétement pété. Il fallait que je rentre chez moi mais mes amis m'ont interdit. Du coup, j’ai pris un taxis. Résultat qui pique 48€ de taxis. J’ai envoyé à Ornikar Assurance qui m’a remboursé.",
            author: "Théophile P.",
            avatar: "m1"
        },
        assistance0km: {
            label: "Assistance 0km",
            explication: "Elle n’est pas obligatoire. Elle augmente un peu la prime. Mais faites nous confiance, si votre véhicule est en panne à 23km de chez vous et non 50km c’est vous qui devrez payer.",
            story: "Je suis partie en vacance avec mes amis, et la voiture ne démarrait plus. Ornikar assurance m’a envoyé une dépanneuse et je suis allé récupérer mon véhicule le lendemain",
            author: "Enguerrand C."
            ,
            avatar: "m2"
        },
        BDG: {
            label: "Bris de Glace",
            explication: "On vous permettra d’être couvert et d’avoir une indemnisation en cas de sinistre sur votre pare-brise, votre lunette arrière ou encore vos vitre latérales.  ",
            story: "Je roulais j’ai pris sur mon pare-brise un gros impact. J’ai pensé à pub Carglass du coup, j’ai appelé Ornikar Assurance. ils ont pris en charge.",
            author: "Justine C.",
            avatar: "f1"
        },
        VOL: {
            label: "Vol et incendie",
            explication: "Si votre véhicule est détruit lors d’un incendie ou volé on vous rembourse à la valeur de l’argus (pas au prix d’achat de votre véhicule mais au prix réel du marché).",
            story: "J’avais une Fiat 500 que je me suis fait volé. Je suis allé déposé plainte. Ornikar Assurance m’a remboursé 6749€. J’ai pu m’en acheter une nouvelle avec une meilleure couleur.",
            author: "Michael B.",
            avatar: "m3"
        },
        TR: {
            label: "Tous Risques",
            explication: "Si vous pensez que vous êtes un peintre en voiture. Cette garantie est faite pour vous. Si vous avez un accident responsable, on rembourse à la fois la personne endommagé mais également votre véhicule. ",
            story: "J’étais en voiture, j’ai eu un coup de fil, et au moment de freiner j’ai embouti la voiture de devant. Résultat constat,… Ornikar Assurance a remboursé tout mon pare-chocs",
            author: "Raouf L.",
            avatar: "m4"

        },
        protection_conducteur: {
            label: "Garanties du conducteur",
            explication: "Peu d’assurés le savent, mais la garantie personnelle du conducteur est optionnelle. C’est pourtant cette clause du contrat qui prend en charge les frais liés aux blessures ou au décès du conducteur jusqu'à 150 000€.",
            story: "Pas d’histoire là-dessus et heureusement.",
            author: null,
        },
        defense_penale: {
            label: "Défense pénale et recours",
            explication: "Vous êtes victime d’un accident de la route et le responsable prend la fuite. Nous vous remboursons vos frais d’avocat et d’expertise afin de récupérer votre indemnisation auprès du conducteur responsable de l’accident de voiture.",
            story: "J’étais à Cannes, un type m’a foncé dedans. Il est parti direct. Ma voiture était morte alors que je venais de l’avoir. Ornikar Assurance a payé tous les frais d’avocats. Y’en avait pour 3230€ !",
            author: "Damien B",
            avatar: "m5"
        },
        RC: {
            label: "Responsabilité civile",
            explication: "Pas compliqué, elle est obligatoire. Si vous avez un accident responsable, nous allons rembourser le conducteur.",
            story: "En garant ma voiture, j’ai arraché le retro. de la Porsche de Michel. Ce rétro. coûtait 1700€. Ornikar assurance a tout remboursé. On comprend pourquoi on paye !",
            author: "Lucas A",
            avatar: "m6"
        },
        assistance_juridique: {
            label: "Protection juridique",
            explication: "Rien à voir avec la défense pénale et recours, si vous avez un litige avec une autre personne qui souhaite vous attaquer. Nous vous accompagnons pour vous défendre. ",
            story: "On m’a grillé une prio à droite. J’ai jamais vu quelqu’un d’aussi mauvaise foi. Il disait que j’étais responsable. J’ai appelé Ornikar Assurance. Ils ont payé les frais et ont convaincu la faute de l’autre tiers",
            author: "Garance K",
            avatar: "f2"
        },
        mandatory: "Garanties obligatoires pour les jeunes conducteurs",
        mandatory_no_change: "Cette garantie est obligatoire pour les jeunes conducteurs",
        options: "Vos options",
        before_cute_price: "Je m'assure pour",
        after_cute_price: "Je suis couvert pour",
    },
    calendly: {
        cta: "Prendre Rendez-Vous"
    },
    calendar: {
        nextMonth: "Mois prochain",
        previousMonth: "Mois précédent",
        openMonthSelector: "Ouvrir le sélecteur de mois",
        openYearSelector: "Ouvrir le sélecteur d'année",
        closeMonthSelector: "Fermer le sélecteur de mois",
        closeYearSelector: "Fermer le sélecteur d'année",
        defaultPlaceholder: "Choisir...",
        from: "du",
        to: "au",
    },
    days: [
        {
            name: "Dimanche",
            short: "D",
            isWeekend: true,
        },
        {
            name: "Lundi",
            short: "L",
        },
        {
            name: "Mardi",
            short: "M",
        },
        {
            name: "Mercredi",
            short: "M",
        },
        {
            name: "Jeudi",
            short: "J",
        },
        {
            name: "Vendredi",
            short: "V",
        },
        {
            name: "Samedi",
            short: "S",
            isWeekend: true,
        },
    ],
    months: [
        "",
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ],
    legal: `ORNIKAR ASSURANCES, SA par action simplifiée à associé unique au capital
  de 10 000€, a débuté son activité en mai 2020 et est enregistré à
  l'Orias sous le numéro 200 053 80. MARIANNE FORMATION est président de
  la société ORNIKAR ASSURANCES. Le siège social de cette entreprise est actuellement
  situé 170 Boulevard de la Villette - 75019 Paris 19, ORNIKAR ASSURANCES
  évolue sur le secteur d'activité: Activités auxiliaires de services
  financiers et d'assurance. ©2020`,
};
