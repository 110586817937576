import cx from 'classnames';
import React, { MouseEventHandler, ReactElement, ReactNode } from 'react';
import Icon from 'components/Icon';
import styles from './styles.module.css';

type DropdownButtonVariant = 'primary' | 'actions';
type DropdownButtonPosition = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';

export interface DropdownButtonProps {
  children: ReactNode;
  position: DropdownButtonPosition;
  avatar?: ReactElement;
  variant?: DropdownButtonVariant;
  open?: boolean;
  large?: boolean;
  withoutArrowIcon?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export default function DropdownButton({
  avatar,
  position,
  variant,
  large,
  open,
  children,
  withoutArrowIcon,
  onClick,
}: DropdownButtonProps): ReactElement {
  const isChevronUp = (open && !position.startsWith('top')) || (!open && position.startsWith('top'));
  const icon = isChevronUp ? 'chevron-up' : 'chevron-down';

  return (
    <div
      aria-hidden
      className={cx(styles.DropdownButton, {
        [styles.Stretch]: large,
        [styles.Primary]: variant === 'primary',
        [styles.WithAvatar]: !!avatar,
        // When the dropdown is open, we want dropdown to have the active style
        'kitt-active': open,
      })}
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      {/* A negative value (usually tabindex="-1" means that the element should be focusable, but should not be reachable via sequential keyboard navigation)
          -> Because we want to trigger content focus when we use tab to focus button
          -> See https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press */}
      <div tabIndex={-1} className={cx(styles.Content, { [styles.OnlyIcon]: !children && !withoutArrowIcon })}>
        {!!avatar && <div className={styles.Avatar}>{avatar}</div>}
        {children && <span className={styles.Children}>{children}</span>}
        {!withoutArrowIcon && (
          <span className={styles.Icon}>
            <Icon name={icon} />
          </span>
        )}
      </div>
    </div>
  );
}
