import cx from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import styles from './styles.module.css';

export interface HighlightProps {
  dark?: boolean;
  children: NonNullable<ReactNode>;
}

export default function Highlight({ dark, children }: HighlightProps): ReactElement {
  return (
    <div
      className={cx(styles.Highlight, {
        [styles.Dark]: dark,
      })}
    >
      {children}
    </div>
  );
}
