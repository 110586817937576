import React, { useState, useMemo } from "react";

import texts from "../../configs/texts";

import Question from "./wrapper";
import { InputAutocomplete } from "@ornikar/kitt";

import useAsync from "../../hooks/useAsync";

const Qmodel = ({ answers, answer, ...props }) => {
  const [modelSearch, setModelSearch] = useState("");
  const [models, err, loading] = useAsync(
    () =>
      fetch("/brands/" + answers.brand + "/models").then((res) => res.json()),
    [answers.brand]
  );
  const results = useMemo(
    () =>
      (models &&
        models.filter(
          (b) => b.toLowerCase().indexOf(modelSearch.toLowerCase()) >= 0
        )) ||
      [],
    [models, modelSearch]
  );

  return (
    <Question {...props} title={texts.model.question} id="Qmodel">
      <div id="QmodelWrapper" style={{ height: "180px" }}>
        <InputAutocomplete
          value={modelSearch}
          onInputValueChange={(v) => setModelSearch(v)}
          onChange={(v) => {
            answer({ model: v });
          }}
          inputProps={{
            placeholder: texts.model.placeholder,
          }}
          emptyComponent={() => (
            <InputAutocomplete.Item>
              {loading
                ? "Chargement"
                : err
                ? "Il y a un problème avec notre serveur, réessayez plus tard"
                : texts.model.emptyLabel}
            </InputAutocomplete.Item>
          )}
          isOpen
        >
          {results.map((m) => (
            <InputAutocomplete.Item item={m} key={m}>
              {m}
            </InputAutocomplete.Item>
          ))}
        </InputAutocomplete>
      </div>
    </Question>
  );
};

export default Qmodel;
