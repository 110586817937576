import { useState, useCallback } from "react";

type DateString = string; // YYYY-MM-DD
type Validator = (_: DateString) => string | undefined;
type UDFState = {
  date: DateString,
  valid: "valid" | undefined,
  error: undefined
} | {
  date: DateString,
  valid: "invalid",
  error: string
}

type DateFieldProps = {
  value: UDFState["date"],
  state: UDFState["valid"],
  onChange: (string) => void
}

const stringValueToState = (value: string, validator: Validator): UDFState => {
  if (!value) return { date: value, valid: undefined, error: undefined };
  const parts = value.split("-");
  if (parts.length != 3) return { date: value, valid: undefined, error: undefined };
  let [year, month, day] = parts;

  if (day.length === 1 && month != '') day = '0' + day;
  if (month.length === 1 && year != '') month = '0' + month;
  value = `${year}-${month}-${day}`;

  if (year.length < 4) return { date: value, valid: undefined, error: undefined };
  if (year === "" || month === "" || day === "")
    return { date: value, valid: undefined, error: undefined };

  let yearNumber = parseInt(year, 10);
  let monthNumber = parseInt(month, 10);
  let dayNumber = parseInt(day, 10);
  const date = new Date(Date.UTC(yearNumber, monthNumber - 1, dayNumber));

  if (date.toISOString().substr(0, 10) !== value)
    return {
      date: value,
      valid: "invalid",
      error: "La date n'est pas valide",
    };

  const error = validator(value);
  if (error) return { date: value, valid: "invalid", error };

  return { date: value, valid: "valid", error: undefined };
};


export default (initValue = "", validator: Validator = () => undefined)
  : [UDFState["date"], DateFieldProps, 'valid' | undefined, undefined]
  | [UDFState["date"], DateFieldProps, 'invalid', string] => {
  const [state, setState] = useState<UDFState>(() => {
    return stringValueToState(initValue, validator);
  });
  const onChange = useCallback(
    (value) => {
      const newState = stringValueToState(value, validator);
      setState(newState)
    },
    [setState, validator]
  );
  /*const onBlur = useCallback(
    () => {
      //const newState = stringValueToState(value, validator);
      console.log('onBlur');
      // setState(newState)
    },
    [setState, validator]
  );*/
  const fieldProps = { value: state.date, state: state.valid, onChange /*, onBlur*/ }
  // @TODO ugly TS
  return state.valid === 'invalid' ? [
    state.date,
    fieldProps,
    state.valid,
    state.error,
  ] : [
      state.date,
      fieldProps,
      state.valid,
      undefined,
    ];
};
