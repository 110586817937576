import { useEffect } from 'react';

export default function useCSSBodyClass(visible: boolean): void {
  useEffect(() => {
    window.document.body.classList.toggle('modal-open', visible);
    return (): void => {
      window.document.body.classList.remove('modal-open');
    };
  }, [visible]);

  // make sure to remove the class when removing the modal
  useEffect(() => {
    return (): void => {
      window.document.body.classList.remove('modal-open');
    };
  }, []);
}
