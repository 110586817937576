import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "regenerator-runtime/runtime";

import Question from "./components/questions/index";
import ProgressBar from "./components/ProgressBar";
import Restitution from "./components/Restitution";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Landing from "./components/Landing";
import "@ornikar/kitt/dist/kitt-default.css";
import "./styles/ornikar-main-extracted.css";
import "./styles/styles.scss";
import GiftCarContainer from "./components/GiftCarContainer";
import { Typography } from "@ornikar/kitt";
import useStore from "./hooks/useStore";
import tracking from "./configs/tracking";
import flow from "./configs/flow";

const App = () => {
  const {
    draft,
    answer,
    back,
    flow: { err, loading, which, percent },
    answers,
    clearAll,
  } = useStore(flow);

  useEffect(() => {
    if (which) tracking.virtualPageView("/fast_quote/" + which);
  }, [which]);

  if (err || loading) return null;

  // force false when assurone bug
  // window.FeatureFlagLongQuote = false;

  if (which === "restitution") {
    return (
      <>
        <Restitution
          answers={answers}
          answer={answer}
          onClick={() => {
            tracking.clickEndFastQuote();
            if (window.FeatureFlagLongQuote)
              window.location = "/longquote.html";
            else
              alert(
                `Un problème technique nous empêche de vous proposer le formulaire de devis définitif. On vous recontacte dès que c'est résolu !`
              );
          }}
          clearAll={clearAll}
        />
      </>
    );
  } else if (which) {
    return (
      <>
        <Header />
        <Landing>
          <GiftCarContainer>
            <Typography.h4
              className="quote-form-header"
              color="black"
              style={{ textAlign: "center" }}
            >
              Obtenez votre estimation
            </Typography.h4>
            <ProgressBar id="progress-bar" percent={percent} />
            <Question
              back={back}
              which={which}
              draft={draft}
              answers={answers}
              answer={answer}
            />
          </GiftCarContainer>
        </Landing>
        <Footer />
      </>
    );
  } else {
    // probably loading
    return <></>;
  }
};

ReactDOM.render(<App />, document.getElementById("app"));
