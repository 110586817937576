import React, { ReactElement, ComponentProps, Ref } from 'react';
import { Except } from 'type-fest';
import { ButtonType, BaseButtonProps } from './baseTypes';
import getClassNames from './getClassNames';
import ButtonContent from './ButtonContent';

export interface BaseDivButtonProps extends BaseButtonProps {
  type?: Exclude<ButtonType, 'link'>;
  href?: never;
  to?: never;
}

export type DivButtonProps = BaseDivButtonProps & Except<ComponentProps<'div'>, 'ref'>;

export default React.forwardRef(function DivButton(
  {
    children,
    type,
    disabled,
    icon,
    iconPosition = 'left',
    stretch,
    large,
    onClick,
    className,
    ...props
  }: DivButtonProps,
  ref: Ref<HTMLDivElement>,
): ReactElement {
  return (
    <div
      ref={ref}
      aria-hidden
      className={getClassNames(children, !!icon, iconPosition, large, stretch, type, disabled, className)}
      role="button"
      tabIndex={0}
      onClick={disabled ? undefined : (onClick as DivButtonProps['onClick'])}
      {...(props as DivButtonProps)}
    >
      <ButtonContent icon={icon} iconPosition={iconPosition}>
        {children}
      </ButtonContent>
    </div>
  );
});
