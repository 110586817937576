import cx from 'classnames';
import React, { ChangeEventHandler, ReactNode } from 'react';
import RadioBehaviour from '../../utils/RadioBehaviour';
import styles from './styles.module.css';

export interface RadioProps {
  id: string;
  name: string;
  checked?: boolean;
  right?: boolean;
  children: NonNullable<ReactNode>;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export default function Radio({ id, onChange, children, checked, name, value, right }: RadioProps) {
  return (
    <RadioBehaviour
      id={id}
      name={name}
      checked={checked}
      value={value}
      className={cx(styles.RadioContainer, {
        [styles.AlignRight]: right,
      })}
      onChange={onChange}
    >
      <div
        className={cx(styles.OuterRadio, {
          [styles.Checked]: checked,
        })}
      >
        <div
          className={cx({
            [styles.InnerRadio]: checked,
          })}
        />
      </div>
      <div className={styles.RadioLabel}>{children}</div>
    </RadioBehaviour>
  );
}
