import React from "react";

const BannerNumberOne = () => (
  <div
    className="Container-1UqTp-95VKx05XHwXfwFTr BlazeCoquelicot-24niov24y_qtmJpKM-gM3k"
    style={{ height: "auto", marginTop: "30px" }}
  >
    <div className="PatternContainer-33uUR6xEhxN6P_6slSRlSK">
      <div
        className="Pattern-1quShsxvCruP5wl3_ckk4x Transparent-CLhlWFmNKZnheJepwnCwV"
        style={{ backgroundPosition: "2400px 1519px" }}
      ></div>
    </div>
    {/* @ TODO texts. me */}
    <div className="Content-una4U27L41Wj1vtLcFnsN">
      <div className="Banner-3hN1t7RTjd00v_Ksl7CUUB">
        <div className="styles-types-module_Reset__1HOMT styles-variants-module_white-regular__2T6kG styles-types-module_base-header4__142rQ  styles-types-module_medium-header4__2YZKi  Content-r2p6YjoO6JTz1oGSZCj7-">
          Avec déjà plus de{" "}
          <span className="styles-types-module_Reset__1HOMT styles-variants-module_white-bold__2a5zT     ">
            <span>1&nbsp;528&nbsp;647</span>&nbsp;utilisateurs
          </span>
          , Ornikar est la{" "}
          <span className="styles-types-module_Reset__1HOMT styles-variants-module_white-bold__2a5zT     ">
            1ère auto-école de France
          </span>{" "}
          et bientôt la{" "}
          <span className="styles-types-module_Reset__1HOMT styles-variants-module_white-bold__2a5zT     ">
            1ère assurance pour les jeunes condcuteurs
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default BannerNumberOne;
