import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { KittTransitionProps } from '../propTypes';
import styles from './styles.module.css';

const fromRightClassNames = {
  appear: styles.SlideInFromRightEnter,
  appearActive: styles.SlideInFromRightEnterActive,
  enter: styles.SlideInFromRightEnter,
  enterActive: styles.SlideInFromRightEnterActive,
  exit: styles.SlideInFromRightExit,
  exitActive: styles.SlideInFromRightExitActive,
};

const fromBottomClassNames = {
  appear: styles.SlideInFromBottomEnter,
  appearActive: styles.SlideInFromBottomEnterActive,
  enter: styles.SlideInFromBottomEnter,
  enterActive: styles.SlideInFromBottomEnterActive,
  exit: styles.SlideInFromBottomExit,
  exitActive: styles.SlideInFromBottomExitActive,
};

type From = 'right' | 'bottom';

const getClassNames = (from: From) => {
  switch (from) {
    case 'bottom':
      return fromBottomClassNames;
    case 'right':
    default:
      return fromRightClassNames;
  }
};

export interface SlideInProps extends KittTransitionProps {
  from?: From;
}

export default function SlideIn({
  appear,
  children,
  from = 'right',
  visible,
  onEnter,
  onExit,
  onEntered,
  onExited,
}: SlideInProps) {
  return (
    <CSSTransition
      unmountOnExit
      in={visible}
      timeout={600}
      appear={appear}
      classNames={getClassNames(from)}
      onEnter={onEnter}
      onExit={onExit}
      onEntered={onEntered}
      onExited={onExited}
    >
      {children()}
    </CSSTransition>
  );
}
