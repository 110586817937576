import React from "react";

import Svgc17b1f685a306b7cd3829eb926616c85 from "../assets/c17b1f685a306b7cd3829eb926616c85.svg";
import ThreeSquareBlock from "./landing/ThreeSquares";
import BannerNumberOne from "./landing/BannerNumberOne";
import StepsBlock from "./landing/Steps";
import TestimonialBlock from "./landing/Testimonial";
import Fourparts from "./landing/Fourparts";
import Media from "./landing/Media";
import Tooltip from "./landing/Tooltip";

import texts from "../configs/texts";
import PatternBackground from "./PatternBackground";
import HeroSubtitle from "./HeroSubtitle";
import Separator from "./Separator";
import { Button, Typography } from "@ornikar/kitt";
import SwitchingWords from "./landing/SwitchingWords";

const Landing = ({ children }) => (
  <main id="top">
    <PatternBackground>
      <div id="quote-wrap">
        <div id="quote-hero">
          <h1 className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header2__1bOT8  styles-types-module_medium-header2__2OOVz styles-types-module_large-header1__Xl4o8 Title-uByUsGR7aM5OH2Ex7hYEW">
            <span className="avoidwrap">
              {texts.landing.premiere_assurance}
            </span>{" "}
            <br />
            <span className="avoidwrap">{texts.landing.jeune_conducteur}</span>
            <br />
            {/*{" "}
            <span
              className="avoidwrap"
              dangerouslySetInnerHTML={texts.landing.simple_et_pas_chere}
            />*/}
            <SwitchingWords words={texts.landing.switching_words} />
          </h1>
          <div className="Content-2d3WHvJGiyEp-zrHkSJm6E">
            <span
              className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7 show-small"
              dangerouslySetInnerHTML={texts.landing.a_partir_29}
            />{" "}
            <HeroSubtitle className="hide-small" />
          </div>
        </div>
        <div id="quote-form">
          <div className="anchor" id="quote" />
          {children}
        </div>
        <div
          className="show-small"
          style={{ textAlign: "center", width: "100%" }}
        >
          <HeroSubtitle />
        </div>
      </div>
      <Separator />
      <div
        style={{ textAlign: "center" }}
        className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-header4__142rQ  styles-types-module_medium-header4__2YZKi  DrivingHours-9Qtnu1vsCVlwDPINhuYoN"
      >
        <span
          className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz     "
          dangerouslySetInnerHTML={texts.landing.deja_assure}
        />{" "}
        {texts.landing.on_soccupe_resiliation}
        <a
          className="styles-module_Link__GVqkR    styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-body__2VaSM  styles-types-module_medium-body__hjzlF  "
          href={texts.url.learn_more}
          id="link-landing-learnMore"
          rel="noopener noreferrer"
          target="_blank"
        >
          {texts.landing.savoir_plus}
        </a>
      </div>
    </PatternBackground>
    <Media />
    <ThreeSquareBlock />
    <BannerNumberOne />
    <StepsBlock />
    <div
      className="SuccessRate-1YKvwe1Kvbz4m16hlOVLQQ"
      style={{ textAlign: "center" }}
    >
      <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header2__1bOT8  styles-types-module_medium-header2__2OOVz styles-types-module_large-header1__Xl4o8 SuccessRateTitle-aZ4_OA7hDa4bELKyx4br1">
        <span dangerouslySetInnerHTML={texts.landing.a_partir_29} />{" "}
        {texts.landing.seulement}
        <Tooltip>{texts.landing.tooltip_price}</Tooltip>
      </div>
      <div className="TextContainer-NyJ0_WJV-j2eo4uv4eQ2g">
        <span className="NonBreakablePart-3zl4334fF0WDXcn-uU9kcK">
          {texts.landing.arretez_trop_cher}
        </span>
      </div>
      <div className="ButtonContainer-2oVhoGPNlDW5Z8lsY9yOZg">
        <Button
          type="primary"
          large
          id="link-landing-cta4"
          href={texts.url.quote_form}
        >
          {texts.landing.cta}
        </Button>
      </div>
    </div>
    <Separator className="PageContentSeparator-mGjj5acWlVciFI_yMhL_e SeparatorStepsFormationType-nu4dLjmXUOTQYrdBBioYG" />
    <TestimonialBlock />
    <Separator className="Separator-gXnlJ7aV5geUWgAhow-no" />
    <Fourparts />
    <div
      className="Container-1UqTp-95VKx05XHwXfwFTr"
      style={{ height: "auto" }}
    >
      <div className="PatternContainer-33uUR6xEhxN6P_6slSRlSK">
        <div
          className="Pattern-1quShsxvCruP5wl3_ckk4x"
          style={{ backgroundPosition: "-435px 550px" }}
        ></div>
      </div>
      <div className="Content-una4U27L41Wj1vtLcFnsN">
        <div className="MainProductsSection-3NHtNRJ9HurvwPwAefi39b">
          <div
            className="MainProducts-2nmkCRFGAFAkZa6eyvZTkI"
            style={{ justifyContent: "center" }}
          >
            <div className="Product-3W3oEvT4Mg63oUrtd8-EEE">
              <Svgc17b1f685a306b7cd3829eb926616c85
                className="ProductImage-3f_1994l1GFLRDOQpfs4Zo"
                alt="entraînement code de la route"
              />
              <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header2__1bOT8  styles-types-module_medium-header2__2OOVz  ProductName-a0U7BbA-Mky7zP8ZQYJwG">
                1ère assurance auto
              </div>
              <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_large-header3__3ZyRX ProductNameSecondPart-3Zh_3_nWrQQSwdCXV9RVT_">
                {texts.landing.jeune_conducteur}{" "}
                {
                  <span
                    dangerouslySetInnerHTML={texts.landing.simple_et_pas_chere}
                  />
                }
              </div>
              <div className="ProductFeatures-10hB5d3bAkz7htQsLgDIno">
                <div className="ProductPricing-3IwlPq4qaCKUsZn29lg8c3">
                  <div
                    className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7 styles-types-module_base-header3__FKPGi  styles-types-module_medium-header3__3ZkRt  "
                    style={{ margin: "1em auto" }}
                  >
                    <span dangerouslySetInnerHTML={texts.landing.a_partir_29} />{" "}
                    <Tooltip>{texts.landing.tooltip_price}</Tooltip>
                  </div>
                  <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header4__142rQ  styles-types-module_medium-header5__1wb6V  ProductClaim-3Kx8n4RKtoAQH3_HKPIFdw">
                    {texts.landing.half}{" "}
                    <Tooltip>{texts.landing.tooltip_half}</Tooltip>
                  </div>
                </div>
                <div className="ProductCTA-2U9HS7NCqrs3V13lO4-ate">
                  <a
                    className="styles-module_Link__GVqkR    styles-module_Button__J65Ds reset-module_ResetButton__1dTnc styles-module_Primary__1ZGLH         styles-module_Large__3pknB   "
                    href={texts.url.quote_form}
                    id="link-landing-cta5"
                  >
                    <div tabIndex="-1" className="styles-module_Content__oxWk7">
                      <span className="styles-module_Children__2CK5r">
                        {texts.landing.cta}
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Typography.div base="body-small">
          En effectuant votre estimation tarifaire, vous acceptez nos conditions
          générales et notre charte de confidentialité. Ces informations sont
          collectées par Ornikar Assurances dans le but de vous fournir une
          assurance adaptée à vos besoins et d&apos;améliorer nos services. Vous
          avez un droit de regard sur vos données personnelles et vous pouvez
          les exercer en nous écrivant à{" "}
          <a href="mailto:donneespersoassurance@ornikar.com">
            donneespersoassurance@ornikar.com
          </a>
          . Pour plus d&apos;informations sur vos droits et la manière dont nous
          traitons vos données personnelles, veuillez consulter{" "}
          <a
            href="https://www.ornikar.com/a-propos/charte-de-confidentialite"
            target="_blank"
            rel="noopener noreferrer"
          >
            notre politique de confidentialité
          </a>
          .
        </Typography.div>
      </div>
    </div>
  </main>
);

export default Landing;
