import cx from 'classnames';
import React, { ReactElement, ReactNode, useState, createContext } from 'react';
import { Portal } from 'react-portal';
import FadeIn from 'components/Transitions/FadeIn';
import Overlay from 'components/Overlay';
import Typography from 'components/Typography';
import Transition from './Transition';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import useEscapeKey from './hooks/useEscapeKey';
import useCSSBodyClass from './hooks/useCSSBodyClass';
import useIOSBodyFreeze from './hooks/useIOSBodyFreeze';
import styles from './styles.module.css';

export interface BetaModalProps {
  visible: boolean;
  children: ReactNode;
  fullscreen?: boolean;
  iosBodyFreeze?: boolean;
  appearAnimation?: boolean;
  onClose: () => void;
  onEntered?: () => void;
  onExited?: () => void;
}

export const OnCloseContext = createContext<(() => void) | undefined>(undefined);

export default function BetaModal({
  visible,
  children,
  fullscreen,
  iosBodyFreeze,
  appearAnimation,
  onClose,
  onEntered,
  onExited,
}: BetaModalProps): ReactElement {
  // Close modal on Escape
  useEscapeKey(onClose, visible);
  // Add or remove class based on visible value
  useCSSBodyClass(visible);

  const [animationProgress, setAnimationProgress] = useState<boolean>(() => {
    return visible;
  });
  const handleEnter = (): void => {
    setAnimationProgress(true);
  };

  const handleOnExited = (): void => {
    setAnimationProgress(false);
    if (onExited) {
      onExited();
    }
  };

  // callbacks with iosFreeze
  const { handleOnEntered, handleOnExit } = useIOSBodyFreeze(iosBodyFreeze, onEntered);

  return (
    <OnCloseContext.Provider value={onClose}>
      <Portal>
        <Typography.div
          base="body"
          className={cx({
            [styles.Hidden]: !animationProgress,
          })}
        >
          <div
            className={cx(styles.ModalContainer, {
              [styles.Fullscreen]: fullscreen,
            })}
          >
            <Transition
              appear={appearAnimation}
              visible={visible}
              onEnter={handleEnter}
              onExit={handleOnExit}
              onEntered={handleOnEntered}
              onExited={handleOnExited}
            >
              <div className={styles.ContentLayout}>{children}</div>
            </Transition>
            <FadeIn appear={appearAnimation} visible={visible}>
              {(): ReactElement => <Overlay onClick={onClose} />}
            </FadeIn>
          </div>
        </Typography.div>
      </Portal>
    </OnCloseContext.Provider>
  );
}

BetaModal.Header = Header;
BetaModal.Body = Body;
BetaModal.Footer = Footer;
