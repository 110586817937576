import { Answers } from "../types/answers";
import { Question } from "../types/question";

const flow = (answers: Partial<Answers>): { which: Question, percent: number } => {
    if (answers.brand === undefined) {
        return { which: "brand", percent: 0 };
    }

    if (answers.model === undefined) {
        return { which: "model", percent: 13 };
    }

    if (answers.already === undefined) {
        return { which: "already", percent: 25 };
    }

    // START - PREVIOUS INSURANCE
    if (answers.already === true) {
        if (answers.still === undefined) {
            return { which: "still", percent: 30 };
        }

        if (answers.still === "less1year") {
            if (answers.subscription === undefined) {
                return { which: "subscription", percent: 32 };
            }
        }

        if (answers.still !== "no") {
            if (answers.currentAssu === undefined) {
                return { which: "currentAssu", percent: 34 }
            }
            if (answers.currentPrice === undefined) {
                return { which: "currentPrice", percent: 34 }
            }
        }

        if (answers.still === "more1year" || answers.still === "no") {
            if (answers.malus === undefined) {
                return { which: "malus", percent: 34 };
            }
        }
    }

    // START - WHEN & CAR
    if (answers.already === false || answers.malus <= 1) {
        if (answers.when === undefined) {
            return { which: "when", percent: 38 };
        }

        if (answers.when === "month" || answers.when === "later") {
            if (answers.license === undefined) {
                return { which: "license", percent: 42 };
            }
            if (answers.license === false) {
                if (answers.licensewhen === undefined) {
                    return { which: "licensewhen", percent: 46 };
                }
            }
        }

        if (answers.alreadycar === undefined) {
            return { which: "alreadycar", percent: 50 };
        }
    }

    // START - PERSONAL
    if (answers.cp === undefined) {
        return { which: "cp", percent: 63 };
    }

    if (
        answers.age === undefined ||
        answers.age === "" ||
        isNaN(parseInt(answers.age, 0))
    ) {
        return { which: "age", percent: 75 };
    }
    if (answers.fname === undefined) {
        return { which: "fname", percent: 83 };
    }
    if (answers.lname === undefined) {
        return { which: "lname", percent: 83 };
    }
    if (answers.email === undefined) {
        return { which: "email", percent: 95 };
    }
    if (answers.phone === undefined) {
        return { which: "phone", percent: 95 };
    }

    return { which: "restitution", percent: 100 };
};

export default flow;
