import cx from 'classnames';
import React, { ReactElement } from 'react';
import MarkerDefault from './markers/marker.svg';
import MarkerLarge from './markers/marker-large.svg';
import PositionMarker from './markers/position-marker.svg';
import styles from './styles.module.css';

type MarkerType = 'position';
type MarkerSize = 'default' | 'small' | 'large';

export const getMarkerSizeValues = (size?: MarkerSize) => {
  if (size === 'large') return { width: 54, height: 56 };
  return { width: 40, height: 40 };
};

const getMarker = (type?: MarkerType, size?: MarkerSize) => {
  // size prop has no incidence on position marker;
  if (type === 'position') return <PositionMarker />;
  switch (size) {
    case 'large':
      return <MarkerLarge />;
    default:
      return <MarkerDefault />;
  }
};

export interface MarkerProps {
  type?: MarkerType;
  size?: MarkerSize;
}

export default function Marker({ type, size }: MarkerProps): ReactElement {
  return (
    <div
      className={cx({
        [styles.DefaultMarker]: type !== 'position',
        [styles.LargeMarker]: size && size === 'large',
      })}
    >
      {getMarker(type, size)}
    </div>
  );
}
