import React, { MouseEventHandler, ReactElement } from 'react';
import Icon from 'components/Icon';
import styles from './styles.module.css';

export interface CloseButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function CloseButton({ onClick }: CloseButtonProps): ReactElement {
  return (
    <button className={styles.CloseButton} type="button" onClick={onClick}>
      <Icon name="cross" />
    </button>
  );
}
