import React from "react";

const LoadingMessage = () => (
  <>
    <h1 className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header2__1bOT8  styles-types-module_medium-header2__2OOVz  styles_Title__h1Kcp">
      Chargement
    </h1>
    <div className="styles_Description__zIzcq">
      Veuillez patienter quelques instants, nous calculons une première
      estimation de votre tarif.
    </div>
  </>
);

export default LoadingMessage;
