import React from "react";
import Tooltip from "./landing/Tooltip";
import texts from "../configs/texts";

const HeroSubtitle = ({ className }) => (
  <div
    className={
      className +
      " styles-types-module_base-header-16__142rQ  styles-types-module_medium-header5__1wb6V"
    }
  >
    <strong
      dangerouslySetInnerHTML={{ __html: texts.landing.no_excuses }}
    ></strong>
    <br />
    <br />
    <p>
      Découvrez une première estimation de votre tarif en moins de 30 secondes.{" "}
      <br />
      Prêt ? Partez !<br />
      <br />
    </p>
    <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-light__3NfKb styles-types-module_base-header4__142rQ  styles-types-module_medium-header4__2YZKi  TheoryHeroContent-2KpY4BzVK80jMQM5IFq3Pe">
      <span className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7">
        A partir de 29€/mois{" "}
        <Tooltip>{texts.landing.tooltip_price}</Tooltip>
      </span>
    </div>
  </div>
);

export default HeroSubtitle;
