import React from "react";
import { Typography } from "@ornikar/kitt";

const SorryMessage = () => (
  <>
    <h1 className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header2__1bOT8  styles-types-module_medium-header2__2OOVz  styles_Title__h1Kcp">
      <Typography.span variant="bold">Toutes nos excuses</Typography.span>
    </h1>
    <div className="styles_Description__zIzcq">
      <div>
        <p>
          Malheureusement, votre dossier ne permet pas de bénéficier de nos
          services.
        </p>

        <p>
          Nous vous remercions pour votre compréhension et vous souhaitons{" "}
          <strong className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7">
            bonne chance dans vos recherches !
          </strong>
        </p>
      </div>
    </div>
  </>
);

export default SorryMessage;
