type GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
type PlaceResult = google.maps.places.PlaceResult;

declare global {
  interface Window {
    resolveGoogleMapsAutocomplete?: (() => void) | null;
  }
}

export type GoogleMaps = typeof google.maps;

export const loadGoogleMaps = (): Promise<GoogleMaps> => {
  if (typeof google === 'object' && google.maps) {
    return Promise.resolve(google.maps);
  }

  return new Promise((resolve) => {
    window.resolveGoogleMapsAutocomplete = function resolveGoogleMapsAutocomplete() {
      resolve(google.maps);
      window.resolveGoogleMapsAutocomplete = null;
    };

    const tag = document.createElement('script');
    tag.async = true;
    tag.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&signed_in=true&libraries=places&callback=resolveGoogleMapsAutocomplete`;
    document.body.appendChild(tag);
  });
};

const matchStreetNumberWithOriginalAddress = (streetNumber = '', address = ''): string => {
  const regex = / *(\d*[-/_]{0,1}\d*) *(bis|ter|quater|quinquies|sexies|septies|octies|novies|decies|[a-z])? /i;
  const [, number = '', extension = ''] = regex.exec(address) || [];
  if (number) {
    return `${number}${extension}`;
  }

  return streetNumber;
};

const findAddressComponent = (name: string, components: GeocoderAddressComponent[]): string | undefined => {
  let i;
  for (i = 0; i < components.length; i += 1) {
    const component = components[i];

    if (Array.prototype.indexOf.call(component.types, name) !== -1) {
      if (name === 'locality' && /paris.*\d{1,2}.*arrondissement/i.exec(component.long_name)) {
        return 'Paris';
      }

      return component.long_name;
    }
  }

  return undefined;
};

export interface FormattedAddress {
  source: 'google';
  lat?: number;
  lng?: number;
  streetNumber?: string;
  streetName?: string;
  zipcode?: string;
  city: string;
}

export const normalizeAddressFromGoogle = (
  { address_components: addressComponents = [], geometry }: PlaceResult,
  originalAddress?: string,
): FormattedAddress => {
  const coordinates =
    geometry && Object.prototype.hasOwnProperty.call(geometry, 'location')
      ? {
          lng: geometry.location.lng(),
          lat: geometry.location.lat(),
        }
      : {};

  return {
    ...coordinates,
    streetNumber: matchStreetNumberWithOriginalAddress(
      findAddressComponent('street_number', addressComponents),
      originalAddress,
    ),
    streetName: findAddressComponent('route', addressComponents),
    zipcode: findAddressComponent('postal_code', addressComponents),
    city: findAddressComponent('locality', addressComponents) as string,
    source: 'google',
  };
};
