import cx from 'classnames';
import { Except } from 'type-fest';
import React, { ChangeEventHandler, ComponentProps, FocusEventHandler } from 'react';
import { handleNativeInputEvent } from 'utils/inputHandlers';
import InputIcon from 'components/InputIcon';
import { InputFormState } from 'utils/form';
import styles from './styles.module.css';

export interface TextAreaProps extends Except<ComponentProps<'textarea'>, 'rows' | 'onChange' | 'onBlur'> {
  state?: InputFormState;
  disabled?: boolean;
  rows?: number;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
}

export default function TextArea({ state, disabled, onChange, onBlur, rows, ...props }: TextAreaProps) {
  return (
    <div className={styles.Container}>
      <textarea
        className={cx(styles.TextArea, {
          [styles.WithDefaultHeight]: !rows,
          [styles.WithIcon]: state,
          [styles.Disabled]: disabled,
        })}
        disabled={disabled}
        rows={rows}
        onChange={handleNativeInputEvent(onChange)}
        onBlur={handleNativeInputEvent(onBlur)}
        {...props}
      />
      <div className={styles.TextAreaShadow} />
      {state && <InputIcon disabled={disabled} icon={state === 'valid' ? 'check' : 'cross'} />}
    </div>
  );
}
