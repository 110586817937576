import React, { ReactNode, ReactElement } from 'react';
import { Except } from 'type-fest';
import { KittTransitionProps } from '../Transitions/propTypes';
import SlideIn from '../Transitions/SlideIn';
import Rotate from '../Transitions/Rotate';
import useMediaQuery from './hooks/useMediaQuery';

interface TransitionProps extends Except<KittTransitionProps, 'children'> {
  children: ReactNode;
}

export default function Transition({ children, ...props }: TransitionProps): ReactElement {
  const matches = useMediaQuery('(min-width: 480px)');
  return matches ? (
    <Rotate {...props}>{(): ReactNode => children}</Rotate>
  ) : (
    <SlideIn from="bottom" {...props}>
      {(): ReactNode => children}
    </SlideIn>
  );
}
