import React from "react";

import LogoWhite from "../assets/ornikarassurance-primary-white.svg";
import texts from "../configs/texts";
import tracking from "../configs/tracking";

const Footer = () => (
  <footer className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz     Footer-3frVEcyx5nx0v22H_KVG96">
    <div className="FooterContent-31y0MgmGnXCOijgpMETYUF">
      <div className="FirstPart-iB2HjDqgXN1JOZP5-bMGQ">
        <LogoWhite className="styles-module_Logo__33vY1 styles-module_LogoWhite__QFIzj Logo-QDJCzqWK8fd7hsS62E_pg" />
        <br />
        <span>
          La 1ère assurance auto jeune conducteur <strong>simple</strong> et{" "}
          <strong>pas chère</strong>
        </span>
      </div>
      <div className="SecondPart-1JMtWmT3kbO5q14n448_no">
        <div className="FooterLinks-3wmwKbeZMtWFP-fWlqgVj9">
          <span className="BlockOnMobile-WEOQ7T3d3ccgtKsAjnBQX">
            <span className="Item-2T2OtotkqBzhiOGr0_1mDe">
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="styles-module_Link__GVqkR    styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz     "
                href={texts.url.faq}
                id="link-footer-FAQ"
                onClick={tracking.clickFooterFAQ}
              >
                FAQ
              </a>
            </span>
            <span className="Item-2T2OtotkqBzhiOGr0_1mDe">
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="styles-module_Link__GVqkR    styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz     "
                href={texts.url.comment_ca_marche}
                id="link-footer-How"
                onClick={tracking.clickFooterHow}
              >
                Comment ça marche ?
              </a>
            </span>
            <span className="Item-2T2OtotkqBzhiOGr0_1mDe">
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="styles-module_Link__GVqkR    styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz     "
                href={texts.url.cgv_mention_legales}
                id="link-footer-CGV"
                onClick={tracking.clickFooterCGV}
              >
                CGV et mentions légales
              </a>
            </span>
            <wbr className="AllowBreak-351iDo2A_NMAuqs8sv7WcV" />
            <span className="Item-2T2OtotkqBzhiOGr0_1mDe">
              <a
                className="styles-module_Link__GVqkR    styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz     "
                href="mailto:assurance@ornikar.com"
                id="link-footer-Email"
                onClick={tracking.clickFooterEmail}
              >
                Email assurance@ornikar.com
              </a>
            </span>
            <span className="Item-2T2OtotkqBzhiOGr0_1mDe">
              <a
                className="styles-module_Link__GVqkR    styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz     "
                href="tel:+33755537527"
                id="link-footer-Phone"
                onClick={tracking.clickFooterPhone}
              >
                Téléphone +33755537527
              </a>
            </span>
          </span>
        </div>
        <div className="Social-3ls63_lvdk-Mn85MSzjvI-">
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="styles-module_Link__GVqkR    styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz     "
            href={texts.url.facebook}
            id="link-footer-FB"
            onClick={tracking.clickFooterFB}
          >
            <img src="data:image/svg+xml;base64,PHN2ZyBhcmlhLWxhYmVsbGVkYnk9InNpbXBsZWljb25zLWZhY2Vib29rLWljb24iIGZpbGw9IiNmZmYiIHJvbGU9ImltZyIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlIGlkPSJzaW1wbGVpY29ucy1mYWNlYm9vay1pY29uIj5GYWNlYm9vayBpY29uPC90aXRsZT48cGF0aCBkPSJNMjIuNjc2IDBIMS4zMjRDLjU5MyAwIDAgLjU5MyAwIDEuMzI0djIxLjM1MkMwIDIzLjQwOC41OTMgMjQgMS4zMjQgMjRoMTEuNDk0di05LjI5NEg5LjY4OXYtMy42MjFoMy4xMjlWOC40MWMwLTMuMDk5IDEuODk0LTQuNzg1IDQuNjU5LTQuNzg1IDEuMzI1IDAgMi40NjQuMDk3IDIuNzk2LjE0MXYzLjI0aC0xLjkyMWMtMS41IDAtMS43OTIuNzIxLTEuNzkyIDEuNzcxdjIuMzExaDMuNTg0bC0uNDY1IDMuNjNIMTYuNTZWMjRoNi4xMTVjLjczMyAwIDEuMzI1LS41OTIgMS4zMjUtMS4zMjRWMS4zMjRDMjQgLjU5MyAyMy40MDggMCAyMi42NzYgMCIvPjwvc3ZnPgo=" />
          </a>
        </div>
      </div>
    </div>
    <div className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-body-small__25Mh1  styles-types-module_medium-body-small__gWkir  Legal-3JczryTdyBhRc3ZHb96zEf">
      <div className="FooterLegal-1TGwyAR4yV_C_9WvZyI6OG">{texts.legal}</div>
    </div>
  </footer>
);

export default Footer;
