import cx from 'classnames';
import React, { MouseEventHandler, ReactNode, KeyboardEvent, ReactElement } from 'react';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { ESCAPE } from '../../utils/keyCodes';
import styles from './styles.module.css';

type MessageType = 'success' | 'warning' | 'danger';

const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, cb: () => void) => {
  if (event.keyCode === ESCAPE) {
    cb();
  }
};

const getIcon = (type?: MessageType) => {
  switch (type) {
    case 'success':
      return <Icon name="check" />;
    case 'danger':
      return <Icon name="alert-triangle" />;
    case 'warning':
      return <Icon name="alert-circle" />;
    default:
      return <Icon name="info" />;
  }
};

interface DismissButtonProps {
  onClick: MouseEventHandler;
  type?: MessageType;
}

const DismissButton = ({ onClick, type }: DismissButtonProps): ReactElement => {
  const hasWhiteIcon = type === 'success' || type === 'danger';

  return (
    <button
      type="button"
      className={cx(styles.DismissButton, {
        [styles.Info]: !type,
        [styles.Confirmation]: type === 'success',
        [styles.Warning]: type === 'warning',
        [styles.Error]: type === 'danger',
      })}
      onClick={onClick}
    >
      <div className={cx(styles.Icon, { [styles.Light]: hasWhiteIcon })}>
        <Icon name="cross" />
      </div>
    </button>
  );
};

export interface MessageProps {
  type?: MessageType;
  children: NonNullable<ReactNode>;
  noRadius?: boolean;
  centeredText?: boolean;
  onDismiss?: () => void;
}

export default function Message({
  type,
  children,
  onDismiss,
  noRadius,
  centeredText,
  ...props
}: MessageProps): ReactElement {
  const isWhite = type === 'success' || type === 'danger';

  return (
    <div
      className={cx(styles.Alert, {
        [styles.Alert_Success]: type === 'success',
        [styles.Alert_Warning]: type === 'warning',
        [styles.Alert_Danger]: type === 'danger',
        [styles.Dismissable]: !!onDismiss,
        [styles.WithRadius]: !noRadius,
        [styles.Center]: centeredText,
      })}
      {...props}
    >
      <Typography.div className={styles.Content} color={isWhite ? 'white' : 'black'} variant="bold">
        {!centeredText && getIcon(type)}
        <div className={styles.Text}>{children}</div>
      </Typography.div>
      {onDismiss && (
        <div className={styles.MediumDismiss}>
          <DismissButton type={type} onClick={onDismiss} />
        </div>
      )}
      {onDismiss && (
        <div
          role="button"
          tabIndex={0}
          className={styles.BaseDismiss}
          aria-label="Dismiss"
          onClick={onDismiss}
          onKeyDown={(e): void => handleKeyDown(e, onDismiss as NonNullable<typeof onDismiss>)}
        />
      )}
    </div>
  );
}
