import React from "react";
import { Button } from "@ornikar/kitt";

const QuestionButton = ({
  children,
  __html,
  onClick,
  style = {},
  className = "",
  ...props
}) => {
  return (
    <Button
      type="accent"
      {...props}
      style={{ ...style, lineHeight: "2" }}
      className={"question-button " + className}
      onClick={onClick}
    >
      {__html ? <span dangerouslySetInnerHTML={{ __html }} /> : children}
    </Button>
  );
};

export const SvgButton = ({ onClick, children, Svg, ...props }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "40%",
        maxWidth: "200px",
      }}
    >
      <div style={{ width: "100%", paddingTop: "100%" }}></div>
      <QuestionButton
        {...props}
        type="accent"
        stretch
        onClick={onClick}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          margin: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Svg
            style={{
              width: "100%",
              maxHeight: "5em",
              height: "auto",
              marginBottom: ".5em",
              marginTop: ".5em",
            }}
          />
          <div>{children}</div>
        </div>
      </QuestionButton>
    </div>
  );
};

export default QuestionButton;
