import React, { ReactNode } from 'react';
import ModalBody from '../Modal/Body';
import styles from './styles.module.css';

interface BodyProps {
  children: ReactNode;
}

export default function Body({ children }: BodyProps) {
  return <ModalBody className={styles.PanelBody}>{children}</ModalBody>;
}

Body.modalBodySymbol = ModalBody.modalBodySymbol;
