import cx from 'classnames';
import React, { ReactElement } from 'react';
import Typography from 'components/Typography';
import UserSVG from '../Icon/set/user.svg';
import styles from './styles.module.css';

const getFirstCharacter = (string: string): string => (string ? string[0] : '');

const getInitials = (firstname: string, lastname: string): string =>
  (getFirstCharacter(firstname) + getFirstCharacter(lastname)).toUpperCase();

export interface AvatarProps {
  src?: string;
  alt?: string;
  firstname?: string;
  lastname?: string;
  large?: boolean;
  light?: boolean;
}

function AvatarContent({ src, alt = '', firstname, lastname, large }: AvatarProps): ReactElement {
  if (src) {
    return <img src={src} alt={alt} />;
  }

  if (firstname && lastname) {
    const initials = getInitials(firstname, lastname);

    return large ? (
      <Typography.div base="header4" variant="bold" color="white">
        {initials}
      </Typography.div>
    ) : (
      <>{initials}</>
    );
  }

  return (
    <div className={styles.DefaultAvatar}>
      <UserSVG />
    </div>
  );
}

export default function Avatar(props: AvatarProps): ReactElement {
  return (
    <div
      className={cx(styles.AvatarWrapper, {
        [styles.TextAvatar]: !props.src,
        [styles.Large]: props.large,
        [styles.Light]: props.light,
      })}
    >
      <AvatarContent {...props} />
    </div>
  );
}
