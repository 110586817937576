import React from "react";

const Separator = ({ className }) => (
  <hr
    align="center"
    className={"Separator-2iDYalYlAa5fh40Eknasko " + className}
  />
);

export default Separator;
