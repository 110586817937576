import React, { ReactChildren, StyleHTMLAttributes } from "react";
import { Button, Typography, Message } from "@ornikar/kitt";
import texts from "../../configs/texts";
import { FunctionComponent } from "react";
import { LongQuoteQuestion } from "../../types/longquote_flow";

export interface QuestionWrapperProps {
  title?: string;
  id: string;
  back?: () => void;
  className?: string;
  helptext?: string;
  which: LongQuoteQuestion["question"];
  use_past_tense?: boolean;
  style?: StyleHTMLAttributes<HTMLDivElement>;
}

const QuestionWrapper: FunctionComponent<QuestionWrapperProps> = ({
  title,
  children,
  id,
  back,
  className,
  helptext,
  which,
  use_past_tense,
  style = {},
}) => {
  const textsLabel =
    texts[which] &&
    ((use_past_tense ? texts[which].question_past : undefined) ||
      texts[which].question);
  const label = title || textsLabel || which;

  return (
    <div
      id={id}
      className={(className || "") + " question"}
      style={{ minHeight: "28em", ...style }}
    >
      <Typography.h4
        style={{
          color: "#38454c",
          margin: "1em 0px 1.5em 0px",
          fontSize: "1.3em",
          fontWeight: "bold",
        }}
      >
        {back ? (
          <Button
            data-cy="back"
            type="subtle"
            icon="arrow-left"
            onClick={back}
          />
        ) : (
          <Button type="subtle-dark" icon="chevron-right" />
        )}{" "}
        <span dangerouslySetInnerHTML={{ __html: label }} />
      </Typography.h4>
      <div className="question-body">
        {children}
        {helptext && (
          //@ts-expect-error: kitt Message pass props to div, but typing is wrong
          <Message style={{ marginTop: "2em" }}>{helptext}</Message>
        )}
      </div>
    </div>
  );
};

export default QuestionWrapper;
