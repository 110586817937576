import React, { useCallback, useEffect } from "react";

import texts from "../../configs/texts";

import Question, { QuestionWrapperProps } from "../questions/wrapper";
import { LongQuoteAnswers, DateType } from "../../types/longquote";
import Validate from "../questions/validate";
import { Button, InputDate, InputFeedback } from "@ornikar/kitt";
import useDateField from "../../hooks/useDateField";
import { LongQuoteQuestion } from "../../types/longquote_flow";
import { FunctionComponent } from "react";

const Qdate: FunctionComponent<
  QuestionWrapperProps & {
    answer: (_: Partial<LongQuoteAnswers>) => void;
    draft: Partial<LongQuoteAnswers>;
    validator: (d: DateType) => string | undefined;
    skippable: boolean;
  }
> = (props) => {
  const { which, answer, draft, validator, skippable } = props;

  const [date, dateFieldProps, state, msg] = useDateField(
    draft[which],
    validator
  );

  const next = useCallback(() => {
    if (state === "valid") answer({ [which]: date });
  }, [date, state, answer, which]);

  useEffect(() => {
    const listener = (e) => {
      if (
        (e.target.id === `Q${which}-dateinput-day` ||
          e.target.id === `Q${which}-dateinput-month` ||
          e.target.id === `Q${which}-dateinput-year`) &&
        e.key === "Enter"
      ) {
        next();
      }
    };
    window.addEventListener("keypress", listener);
    return () => window.removeEventListener("keypress", listener);
  }, [next]);

  const skip = useCallback(() => {
    answer({ [which]: "unknown" });
  }, [answer, which]);

  return (
    <Question {...props} id={`Q${which}`}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <InputDate {...dateFieldProps} id={`Q${which}-dateinput`} />
        <div style={{ marginTop: ".5em", height: "1em", marginBottom: "1em" }}>
          <InputFeedback state={state}>
            {(state === "invalid" ? msg : " ") as string}
          </InputFeedback>
        </div>
        {skippable && (
          <Button
            type="subtle-dark"
            id={`btn-skip-${which}`}
            style={{ textDecoration: "underline" }}
            onClick={skip}
          >
            {(texts[which] && texts[which].unknown) || texts.commons.unknown}
          </Button>
        )}
      </div>
      <Validate
        id={`btn-validate-${which}`}
        disabled={state !== "valid"}
        onClick={next}
      />
    </Question>
  );
};
export default Qdate;
