import useAsync from "./useAsync";

const useQuote = (answers) => {
  return useAsync(
    () =>
      fetch("/quote", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(answers),
      }).then((res) => res.json()),
    [answers]
  );
};

export default useQuote;
