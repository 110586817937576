import cx from 'classnames';
import React, { ReactNode } from 'react';
import styles from './styles.module.css';

export interface CardProps {
  children: NonNullable<ReactNode>;
  /** className props is only meant to be used to override the max-width */
  className?: string;
  depth?: 'base' | 'medium' | 'high';
}

export default function Card({ children, className, depth = 'base' }: CardProps) {
  return (
    <div
      className={cx(
        styles.Container,
        {
          [styles.DepthBase]: depth === 'base',
          [styles.DepthMedium]: depth === 'medium',
          [styles.DepthHigh]: depth === 'high',
        },
        className,
      )}
    >
      {children}
    </div>
  );
}
