import React from "react";

const ProgressBar = ({ id, percent = 0 }) => (
  <div
    id={id}
    style={{
      height: ".3em",
      width: "100%",
      borderRadius: ".2em",
      backgroundColor: "#91A4AE",
    }}
  >
    <div
      style={{
        borderRadius: ".2em",
        height: ".3em",
        width: percent + "%",
        transition: "2s",
        // backgroundColor: "#ff6f00",
        backgroundColor: "#FF7500",
      }}
    ></div>
  </div>
);

export default ProgressBar;
