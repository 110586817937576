import React, { ComponentProps, ReactNode } from 'react';

export interface AnchorProps extends ComponentProps<'a'> {
  disabled?: boolean;
  children?: NonNullable<ReactNode>;
}

export default function Anchor({ disabled, children, target, ...props }: AnchorProps) {
  return (
    <a rel={target ? 'noopener noreferrer' : undefined} target={target} {...props}>
      {children}
    </a>
  );
}
