import { useState, useEffect } from "react";

export default (fetcher, dependencies) => {
  const [state, setState] = useState({
    first: true,
    result: null,
    error: null,
    loading: true,
  });
  let cancelled = false;
  useEffect(() => {
    if (!state.first)
      setState({ result: state.result, error: null, loading: true });
    fetcher().then(
      (res) => {
        if (!cancelled)
          setState({ first: false, result: res, error: null, loading: false });
      },
      (err) => {
        if (!cancelled)
          setState({ first: false, result: null, error: err, loading: false });
      }
    );
    return () => {
      cancelled = true;
    };
  }, dependencies);
  return [state.result, state.error, state.loading];
};
