import React, { Component, ReactNode } from 'react';
import InteractiveMap, { Marker } from 'components/InteractiveMap';
import EllipsedHeader from 'components/InteractiveMapModal/EllipsedHeader';
import Modal from '../BetaModal';
import Typography from '../Typography';
import styles from './styles.module.css';

export interface InteractiveMapModalProps {
  markers?: Marker[];
  visible: boolean;
  title: NonNullable<ReactNode>;
  zoom?: number;
  onClose: () => void;
}

interface InteractiveMapModalState {
  displayMap: boolean;
}

export default class InteractiveMapModal extends Component<InteractiveMapModalProps, InteractiveMapModalState> {
  state = {
    displayMap: this.props.visible,
  };

  toggleMapDisplay = () => {
    this.setState((prevState) => ({
      displayMap: !prevState.displayMap,
    }));
  };

  render() {
    const { visible, title, markers, zoom, onClose } = this.props;
    return (
      <Modal
        fullscreen
        visible={visible}
        onClose={onClose}
        onExited={this.toggleMapDisplay}
        onEntered={this.toggleMapDisplay}
      >
        <Modal.Header>
          <EllipsedHeader>
            <Typography.div variant="bold">{title}</Typography.div>
          </EllipsedHeader>
        </Modal.Header>
        <div className={styles.Body}>{this.state.displayMap && <InteractiveMap markers={markers} zoom={zoom} />}</div>
      </Modal>
    );
  }
}
