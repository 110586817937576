import cx from 'classnames';
import React, { ReactNode, forwardRef } from 'react';
import styles from './styles.module.css';

export interface BodyProps {
  children: NonNullable<ReactNode>;
  className?: string;
}

export default forwardRef<HTMLDivElement, BodyProps>(function ModalBody({ children, className }, ref) {
  return (
    <div ref={ref} className={cx(styles.Body, className)}>
      {children}
    </div>
  );
});
