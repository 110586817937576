import AlertCircle from './set/alert-circle.svg';
import AlertTriangle from './set/alert-triangle.svg';
import ArrowDown from './set/arrow-down.svg';
import ArrowLeft from './set/arrow-left.svg';
import ArrowRight from './set/arrow-right.svg';
import ArrowUp from './set/arrow-up.svg';
import Bookmark from './set/bookmark.svg';
import Calendar from './set/calendar.svg';
import Camera from './set/camera.svg';
import Cart from './set/cart.svg';
import Chart from './set/chart.svg';
import CheckCircle from './set/check-circle.svg';
import CheckSquare from './set/check-square.svg';
import Check from './set/check.svg';
import ChevronDown from './set/chevron-down.svg';
import ChevronLeft from './set/chevron-left.svg';
import ChevronRight from './set/chevron-right.svg';
import ChevronUp from './set/chevron-up.svg';
import Clock from './set/clock.svg';
import Cone from './set/cone.svg';
import ConeSparks from './set/cone-sparks.svg';
import Coins from './set/coins.svg';
import Document from './set/document.svg';
import Download from './set/download.svg';
import EyeOff from './set/eye-off.svg';
import Eye from './set/eye.svg';
import Facebook from './set/facebook.svg';
import Forbidden from './set/forbidden.svg';
import FileText from './set/file-text.svg';
import Folder from './set/folder.svg';
import Hash from './set/hash.svg';
import Headphones from './set/headphones.svg';
import HelpCircle from './set/help-circle.svg';
import Inbox from './set/inbox.svg';
import Info from './set/info.svg';
import LifeBuoy from './set/life-buoy.svg';
import List from './set/list.svg';
import Loader from './set/loader.svg';
import Lock from './set/lock.svg';
import LogOut from './set/log-out.svg';
import MapPin from './set/map-pin.svg';
import Maximize from './set/maximize.svg';
import MaximizeSquare from './set/maximize-square.svg';
import Menu from './set/menu.svg';
import MessageSquare from './set/message-square.svg';
import MessageRound from './set/message-round.svg';
import Minimize from './set/minimize.svg';
import Move from './set/move.svg';
import Pause from './set/pause.svg';
import Pencil from './set/pencil.svg';
import Phone from './set/phone.svg';
import Play from './set/play.svg';
import PlayFilled from './set/play-filled.svg';
import Plus from './set/plus.svg';
import Power from './set/power.svg';
import RotateCcw from './set/rotate-ccw.svg';
import Settings from './set/settings.svg';
import Sharp from './set/sharp.svg';
import ShoppingCart from './set/shopping-cart.svg';
import Slash from './set/slash.svg';
import ThumbsUp from './set/thumbs-up.svg';
import Trash from './set/trash.svg';
import Timer from './set/timer.svg';
import Upload from './set/upload.svg';
import User from './set/user.svg';
import Volume2 from './set/volume-2.svg';
import VolumeX from './set/volume-x.svg';
import Wen from './set/wen.svg';
import XCircle from './set/x-circle.svg';
import X from './set/x.svg';
import Zap from './set/zap.svg';

const appSet = {
  camera: Camera,
  sharp: Sharp,
  power: Power,
  phone: Phone,
  cart: Cart,
  document: Document,
  wen: Wen,

  // New icons
  'alert-circle': AlertCircle,
  'alert-triangle': AlertTriangle,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  bookmark: Bookmark,
  calendar: Calendar,
  chart: Chart,
  'check-circle': CheckCircle,
  'check-square': CheckSquare,
  check: Check,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  clock: Clock,
  cross: X,
  cone: Cone,
  'cone-sparks': ConeSparks,
  'cross-circle': XCircle,
  download: Download,
  'eye-off': EyeOff,
  eye: Eye,
  facebook: Facebook,
  forbidden: Forbidden,
  'file-text': FileText,
  folder: Folder,
  hash: Hash,
  headphones: Headphones,
  'help-circle': HelpCircle,
  inbox: Inbox,
  info: Info,
  'life-buoy': LifeBuoy,
  list: List,
  loader: Loader,
  lock: Lock,
  'log-out': LogOut,
  'map-pin': MapPin,
  maximize: Maximize,
  'maximize-square': MaximizeSquare,
  menu: Menu,
  'message-round': MessageRound,
  'message-square': MessageSquare,
  minimize: Minimize,
  move: Move,
  pause: Pause,
  pencil: Pencil,
  play: Play,
  'play-filled': PlayFilled,
  plus: Plus,
  'rotate-ccw': RotateCcw,
  settings: Settings,
  'shopping-cart': ShoppingCart,
  slash: Slash,
  'thumbs-up': ThumbsUp,
  upload: Upload,
  user: User,
  'volume-2': Volume2,
  'volume-x': VolumeX,
  coins: Coins,
  trash: Trash,
  timer: Timer,
  zap: Zap,
};

export default appSet;
