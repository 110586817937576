import React, { useCallback } from "react";

import { SvgButton } from "./button";
import texts from "../../configs/texts";
import Question from "./wrapper";

import SvgQAlreadyYes from "../../assets/Q_already_yes.svg";
import SvgQAlreadyNo from "../../assets/Q_already_no.svg";

const Qalready = ({ answer, ...props }) => {
  const onYesClick = useCallback(() => answer({ already: true, license: true }), [answer]);
  const onNoClick = useCallback(() => answer({ already: false }), [answer]);

  return (
    <Question {...props} title={texts.already.question} id="Qalready">
      <SvgButton id="btn-yes-already" onClick={onYesClick} Svg={SvgQAlreadyYes}>
        {texts.commons.yes}
      </SvgButton>
      <SvgButton id="btn-no-already" onClick={onNoClick} Svg={SvgQAlreadyNo}>
        {texts.commons.no}
      </SvgButton>
    </Question>
  );
};

export default Qalready;
