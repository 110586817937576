import React, { useState, useMemo, useEffect, useCallback } from "react";

import texts from "../../configs/texts";

import Question from "./wrapper";
import Validate from "./validate";

import { InputAutocomplete, Button } from "@ornikar/kitt";
import Spacer from "./spacer";

const opts = Array(56)
  .fill(0)
  .map((_, i) => 250 + i * 50)
  .map((v) => ({ v: v, label: v + "€ / an" }));

opts[0].label += texts.currentPrice.less
opts[opts.length-1].label += texts.currentPrice.more

const scrollList = () => {
  const price1000option = document.querySelector(".current-price-option-950");
  const list = document.querySelector(".styles-module_Results__14ogX");
  if (price1000option && list) list.scrollTo({ top: price1000option.offsetTop });
}

const QcurrentPrice = ({ answer, ...props }) => {
  const [currentPrice, setcurrentPrice] = useState(undefined);
  const [currentPriceSearch, setcurrentPriceSearch] = useState("");
  const results = useMemo(
    () =>
      opts.filter(
        (b) =>
          b.label.toLowerCase().indexOf(currentPriceSearch.toLowerCase()) >= 0
      ) || [],
    [opts, currentPriceSearch]
  );

  useEffect(scrollList, [opts]);

  const skip = useCallback(() => answer({ currentPrice: null }));

  return (
    <Question {...props} title={texts.currentPrice.question} id="QcurrentPrice">
      <InputAutocomplete
        onInputValueChange={(search) => {
          if (typeof search === "string") setcurrentPriceSearch(search);
        }}
        inputProps={{
          value: currentPriceSearch,
          onFocus: (e) => {
            setcurrentPriceSearch("");
            setcurrentPrice(undefined);
            e.target.value = "";
            setTimeout(scrollList, 10);
          },
        }}
        onChange={(item) => {
          if (item === null) {
            return setcurrentPrice(undefined);
          }
          setcurrentPrice(item.v);
          //setTimeout(() => setcurrentPriceSearch(item.label), 1);
        }}
        itemToString={(item) => item.label}
        placeholder={texts.currentPrice.placeholder}
        emptyComponent={() => (
          <InputAutocomplete.Item>
            {texts.currentPrice.emptyLabel}
          </InputAutocomplete.Item>
        )}
        isOpen={currentPrice === undefined}
      >
        {results.map((item) => (
          <InputAutocomplete.Item item={item} key={item.v} className={`current-price-option-${item.v}`}>
            {item.label}
          </InputAutocomplete.Item>
        ))}
      </InputAutocomplete>
      <Spacer />
      <div style={{ textAlign: "center" }}>
        <Button
          stretch
          type="subtle-dark"
          id="btn-skip-currentPrice"
          style={{ textDecoration: "underline" }}
          onClick={skip}
        >
          {texts.current.unknown}
        </Button>
      </div>
      <Validate
        id="btn-validate-currentPrice"
        disabled={currentPrice === undefined}
        onClick={() => answer({ currentPrice })}
      />
    </Question>
  );
};

export default QcurrentPrice;
