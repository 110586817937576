import React from "react";

const NotYetMessage = ({ monthlyUnits, monthlyCents }) => (
  <>
    <h1 className="styles-types-module_Reset__1HOMT styles-variants-module_black-bold__1t5oz styles-types-module_base-header2__1bOT8  styles-types-module_medium-header2__2OOVz  styles_Title__h1Kcp">
      <span className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7     ">
        C’est un peu trop tôt pour nous !
      </span>
    </h1>
    <div className="styles_Description__zIzcq">
      <div>
        <p>
          Notre première estimation de votre futur tarif est{" "}
          <strong className="styles-types-module_Reset__1HOMT styles-variants-module_primary-bold__1UhG7     ">
            {monthlyUnits},{monthlyCents}€ / mois.
          </strong>{" "}
        </p>

        <p>
          Malheureusement, nous ne sommes pas (encore) en mesure de vous
          proposer un devis définitif, car vous n’êtes pas encore titulaire du
          permis de conduire, ou êtes sous contrat avec votre assureur actuel
          depuis moins de douze mois.
        </p>

        <p>Nous serons ravis de revenir vers vous plus tard !</p>

        <p style={{ marginTop: '.5em', fontStyle: "italic" }}>
          La loi Consommation vous permet de résilier auprès de votre assureur
          actuel à tout moment à compter de 12 mois de souscription.
        </p>
      </div>
    </div>
  </>
);

export default NotYetMessage;
