import React, { useState, useMemo, useEffect } from "react";

import texts from "../../configs/texts";

import Question from "./wrapper";
import Validate from "./validate";

import { InputAutocomplete, Tooltip } from "@ornikar/kitt";
import Spacer from "./spacer";

const label = (x) =>
  x < 1
    ? `${x.toFixed(2)} soit ${Math.round((1 - x) * 100)}% de bonus`
    : x == 1
    ? `1.00 soit ni bonus, ni malus`
    : `${x.toFixed(2)} soit ${Math.round((x - 1) * 100)}% de malus`;

const opts = Array(300)
  .fill(0)
  .map((_, i) => (50 + i) / 100)
  .map((v) => ({ v: v, label: label(v) }));

const scrollList = () => {
  const crm1Option = document.querySelector(".malus-option-99");
  const list = document.querySelector(".styles-module_Results__14ogX");
  if (crm1Option && list) list.scrollTo({ top: crm1Option.offsetTop });
}

const Qmalus = ({ answer, ...props }) => {
  const [malus, setMalus] = useState(undefined);
  const [malusSearch, setMalusSearch] = useState("");
  const results = useMemo(
    () =>
      (opts &&
        opts.filter(
          (b) => b.label.toLowerCase().indexOf(malusSearch.toLowerCase()) >= 0
        )) ||
      [],
    [opts, malusSearch]
  );

  useEffect(scrollList, [opts]);

  return (
    <Question {...props} title={texts.malus.question} id="Qmalus">
      <InputAutocomplete
        // value={malusSearch}

        // defaultInputValue={malusSearch}
        onInputValueChange={(search) => {
          if (typeof search === "string") setMalusSearch(search);
        }}
        inputProps={{
          value: malusSearch,
          onFocus: (e) => {
            setMalusSearch("");
            setMalus(undefined);
            e.target.value = "";
            setTimeout(scrollList, 10);
          },
        }}
        onChange={(item) => {
          if (item === null) {
            return setMalus(undefined);
          }
          setMalus(item.v);
          //setTimeout(() => setMalusSearch(item.label), 1);
        }}
        itemToString={(item) => item.label}
        placeholder={texts.malus.placeholder}
        emptyComponent={() => (
          <InputAutocomplete.Item>
            {texts.malus.emptyLabel}
          </InputAutocomplete.Item>
        )}
        isOpen={malus === undefined}
      >
        {results.map((item) => (
          <InputAutocomplete.Item item={item} key={item.v} className={`malus-option-${item.v*100}`}>
            {item.label}
          </InputAutocomplete.Item>
        ))}
      </InputAutocomplete>
      <Spacer />
      <div style={{ textAlign: "center" }}>
        <Tooltip content="Chaque année vous bénéficiez de 5% de réduction. Exemple : cela fait 2 ans que je suis assuré sans accident responsable j'ai un bonus à 0,9">
          Ou trouver mon bonus ?
        </Tooltip>
      </div>
      <Validate
        id="btn-validate-malus"
        disabled={malus === undefined}
        onClick={() => answer({ malus })}
      />
    </Question>
  );
};

export default Qmalus;
