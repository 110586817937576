// import Tooltip from "./landing/Tooltip";
import {
  Button,
  Icon,
  InputText,
  LargeLoader,
  Typography,
} from "@ornikar/kitt";
import React, { useCallback, useMemo } from "react";
import texts from "../configs/texts";
import useQuote from "../hooks/useQuote";
import GiftCarContainer from "./GiftCarContainer";
import CutePrice from "./restitution/CutePrice";
import GoLongMessage from "./restitution/GoLongMessage";
import HeaderRestitution from "./restitution/Header";
import NotYetMessage from "./restitution/NotYetMessage";
import NoEstimateMessage from "./restitution/NoEstimateMessage";
import SorryMessage from "./restitution/SorryMessage";
import RestitutionContainer from "./restitution/PageContainer";
import Reassurances from "./restitution/Reassurances";
import LoadingMessage from "./restitution/LoadingMessage";
import styles from "../styles/fake-css-modules";
import { addMonths, startOfToday } from "date-fns";

const Restitution = ({ answers, answer, onClick, clearAll }) => {
  const [result, , loading] = useQuote(answers);

  const { vehiculeSummary, situationSummary } = useMemo(() => {
    const vehiculeSummary = `${answers.brand} - ${answers.model}`;
    const situationSummary = [];
    if (answers.already === false)
      situationSummary.push("Vous n'avez jamais été assuré");

    if (answers.still === "less1year" && answers.subscription === "unknown")
      situationSummary.push(`Vous êtes assurés depuis moins d'un an`);
    else if (
      answers.still === "less1year" &&
      answers.subscription !== undefined
    )
      situationSummary.push(
        `Vous êtes assurés depuis le ${answers.subscription}`
      );

    if (answers.still === "no")
      situationSummary.push(`Vous n'êtes plus assuré`);

    if (answers.still === "more1year")
      situationSummary.push(`Vous êtes assurés depuis plus d'un an.`);

    if (answers.malus !== undefined)
      situationSummary.push(`Votre bonus/malus est ${answers.malus}.`);

    if (answers.license === true) situationSummary.push("Vous avez le permis");
    if (answers.license === false && answers.licensewhen === "unknown")
      situationSummary.push("Vous allez bientôt passer le permis");
    else if (answers.license === false && answers.licensewhen !== undefined)
      situationSummary.push(
        `Vous allez passer le permis le ${answers.licensewhen}`
      );

    return { vehiculeSummary, situationSummary };
  }, [answers]);

  const clearSituation = useCallback(() =>
    answer({
      already: undefined,
      still: undefined,
      subscription: undefined,
      malus: undefined,
      when: undefined,
      license: undefined,
      licensewhen: undefined,
      alreadycar: undefined,
    })
  );

  let monthlyUnits,
    monthlyCents = "";
  if (result && result.yearly_contact_ttc) {
    const parts = ((result.yearly_contact_ttc || 0) / 12).toFixed(2).split(".");
    monthlyUnits = parts[0];
    monthlyCents = parts[1];
  }

  const isLoading = !result;
  const hasPrice = !isLoading && result.yearly_contact_ttc;
  const fourMonthFromNow = addMonths(startOfToday(), 4).toISOString();
  const shouldLongQuote =
    !isLoading &&
    (hasPrice || (!result.bad_age && !result.bad_zip && !result.bad_malus)) &&
    (answers.still !== "less1year" ||
      answers.subscription === "unkown" ||
      answers.subscription < fourMonthFromNow) &&
    answers.license !== false;

  return (
    <>
      <HeaderRestitution>
        {shouldLongQuote ? (
          <div className="styles-module_Button__2zcg9">
            <Button
              type="primary"
              stretch
              onClick={onClick}
              id="btn-header-restitution-cta"
            >
              {texts.landing.cta_restitution}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </HeaderRestitution>
      <RestitutionContainer>
        <div className="styles_ContentContainer__1gz-m">
          <div className="styles_Content__3heCT">
            {isLoading ? (
              <LoadingMessage />
            ) : hasPrice && shouldLongQuote ? (
              <GoLongMessage {...{ monthlyUnits, monthlyCents }} />
            ) : hasPrice && !shouldLongQuote ? (
              <NotYetMessage {...{ monthlyUnits, monthlyCents }} />
            ) : !hasPrice && shouldLongQuote ? (
              <NoEstimateMessage />
            ) : (
              <SorryMessage />
            )}

            <div
              className="show-small"
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1em auto 3em auto",
              }}
            >
              {shouldLongQuote && (
                <Button
                  id="btn-restitution-text-cta"
                  large
                  type="primary"
                  onClick={onClick}
                >
                  {texts.landing.cta_restitution}
                </Button>
              )}
            </div>

            <div style={{ flexGrow: "1" }} />
            <Reassurances />
          </div>
        </div>
        <GiftCarContainer>
          <Typography.h4
            className="quote-form-header"
            color="black"
            variant="bold"
            style={{ textAlign: "center" }}
          >
            Votre tarif estimatif
          </Typography.h4>
          {loading ? (
            <div className="quote-form-cute-container">
              <LargeLoader />
            </div>
          ) : hasPrice ? (
            <div className="quote-form-cute-container">
              <CutePrice
                monthlyUnits={monthlyUnits}
                monthlyCents={monthlyCents}
              />
            </div>
          ) : (
            <></>
          )}

          <div>
            <form data-test="payment-form">
              <div className={styles.DefaultStyling}>
                <div className="styles-module_LabelContainer__1_BEJ">
                  <label
                    htmlFor="vehiculeSummary"
                    className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-body__2VaSM  styles-types-module_medium-body__hjzlF  styles-module_Label__2c3jR"
                  >
                    {texts.landing.vehicule}
                  </label>
                </div>
                <InputText
                  value={vehiculeSummary}
                  id="vehiculeSummary"
                  name="vehiculeSummary"
                  readOnly
                  icon="pencil"
                  onIconClicked={() =>
                    answer({
                      brand: undefined,
                      model: undefined,
                    })
                  }
                  onFocus={(e) => e.target.blur()}
                />
              </div>
              <div className={styles.DefaultStyling}>
                <div className="styles-module_LabelContainer__1_BEJ">
                  <label
                    htmlFor="situationSummary"
                    className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-body__2VaSM  styles-types-module_medium-body__hjzlF  styles-module_Label__2c3jR"
                  >
                    {texts.landing.situation}
                  </label>
                </div>
                <div>
                  {situationSummary.map((part, idx) => (
                    <div
                      className="styles-module_InputContainer__1uC9K"
                      key={idx}
                    >
                      <div
                        className="styles-module_Input__Xtb_b styles-module_WithIcon__TosmA "
                        id={"situationSummary" + idx}
                        style={{
                          marginBottom: "6px",
                          height: "auto",
                          minHeight: "40px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {part}
                      </div>
                      <div className="styles-module_InputShadow__1_zF6" />
                      <button
                        onClick={clearSituation}
                        className="styles-module_InputIcon__1-Ioi reset-module_ResetButton__1dTnc styles-module_InputIcon__2TlvZ styles-module_WithCursor__hNIKo   "
                        type="button"
                      >
                        <Icon name="pencil" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="styles_FormBottom__27EhF">
                <div className={styles.DefaultStyling}>
                  <div className="styles-module_LabelContainer__1_BEJ">
                    <label
                      htmlFor="zipcode"
                      className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-body__2VaSM  styles-types-module_medium-body__hjzlF  styles-module_Label__2c3jR"
                    >
                      {texts.landing.cp}
                    </label>
                  </div>
                  <div>
                    <InputText
                      value={answers.cp || ""}
                      readOnly
                      id="zipcode"
                      name="zipcode"
                      icon="pencil"
                      onIconClicked={() => answer({ cp: undefined })}
                      onFocus={(e) => e.target.blur()}
                    />
                  </div>
                </div>
                <div className={styles.DefaultStyling}>
                  <div className="styles-module_LabelContainer__1_BEJ">
                    <label
                      htmlFor="age"
                      className="styles-types-module_Reset__1HOMT styles-variants-module_black-regular__2_1Sz styles-types-module_base-body__2VaSM  styles-types-module_medium-body__hjzlF  styles-module_Label__2c3jR"
                    >
                      {texts.landing.age}
                    </label>
                  </div>
                  <InputText
                    value={answers.age || ""}
                    id="age"
                    name="age"
                    readOnly
                    icon="pencil"
                    onIconClicked={() => answer({ age: undefined })}
                    onFocus={(e) => e.target.blur()}
                  />
                </div>
              </div>
              {!isLoading && (
                <>
                  <div className="styles_Action__1Gs6J">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {shouldLongQuote && (
                        <Button
                          id="btn-restitution-form-cta"
                          large
                          type="primary"
                          onClick={onClick}
                        >
                          {texts.landing.cta_restitution}
                        </Button>
                      )}
                      <Button
                        id="btn-restitution-form-clear-all"
                        type="subtle-dark"
                        onClick={clearAll}
                      >
                        {texts.landing.clear_all}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </GiftCarContainer>
      </RestitutionContainer>
    </>
  );
};

export default Restitution;
