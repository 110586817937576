/* eslint-disable jsx-a11y/label-has-for */
import { Except } from 'type-fest';
import React, { ComponentProps, ReactElement } from 'react';
import Typography from '../Typography';
import styles from './styles.module.css';

type HtmlLabelProps = ComponentProps<'label'>;
export type LabelProps = Except<HtmlLabelProps, 'htmlFor' | 'children' | 'color'> &
  Required<Pick<HtmlLabelProps, 'htmlFor' | 'children'>>;

export default function Label({ htmlFor, children, ...props }: LabelProps): ReactElement {
  return (
    <Typography tag="label" base="body" className={styles.Label} htmlFor={htmlFor} {...props}>
      {children}
    </Typography>
  );
}
